import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toastSetup } from "../../../../functions/toastSetup";
import PrintTicketImageMapper from "./PrintTicketImageMapper";
import SeatDisplay from "./SeatDisplay";
import axios from "axios";
import { Trash2, CheckCheck, SearchCheck } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginIsOpen } from "../../../../store/loginSlice";
export const PrintMappedTickets = ({
  concertId,
  groundPlanName,
  tickets,
  setTickets,
  freeSaleZones,
  onlineSale,
}) => {
  const [modalWindow, setModalWindow] = useState(false);
  const [selectedZoneData, setSelectedZoneData] = useState();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isOnlineSelected, setIsOnlineSelected] = useState(true);
  const [onlineSaleZones, setOnlineSaleZones] = useState(onlineSale);

  const token = useSelector((state) => state.userState.token);
  const dispatch = useDispatch();

  const groundPlanUrl = `${process.env.REACT_APP_API_URL}/static/ground-plans/${groundPlanName}`;
  const groundPlan = new Image();
  groundPlan.src = groundPlanUrl;
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (imageLoaded) return;
    const img = new Image();
    img.onload = () => setImageLoaded(true);
    img.src = groundPlanUrl;
  }, [groundPlanUrl, imageLoaded]);

  // Zone click
  function handleZoneClick(e, zoneData) {
    let zoneKey = zoneData[0];
    const onlineSaleZone = onlineSaleZones[zoneKey];
    setModalWindow(true);
    setSelectedZoneData(zoneData);
    if (document.querySelector(".highlighted"))
      document.querySelector(".highlighted").classList.remove("highlighted");
    e.classList.add("highlighted");

    const hasSeatsInOnlineSale =
      onlineSaleZone.rows && Object.values(onlineSaleZone.rows).some((row) => row.seats.length > 0);

    const isAlreadyOnSale = onlineSaleZone?.on_sale || hasSeatsInOnlineSale;

    const zoneFreeSaleData = zoneData[1];

    if (isAlreadyOnSale) {
      toast.info(
        `${zoneKey} zona je u bila u online prodaji. Odaberite slobodna sjedala!`,
        toastSetup("top-center", 3000)
      );
    }

    const ticketToSave = onlineSaleZone?.rows
      ? {
          categoryName: zoneKey,
          ticketsNum: calculateTicketsNum(onlineSaleZone.rows, isAlreadyOnSale),
          ticketType: zoneFreeSaleData.name,
          ticketPrice: Number(zoneFreeSaleData.price),
          rows: isAlreadyOnSale
            ? onlineSaleZone.rows
            : Object.fromEntries(
                Object.entries(onlineSaleZone.rows).map(([rowKey, row]) => [
                  rowKey,
                  {
                    total_seats: row.total_seats,
                    seats: Array.from({ length: row.total_seats }, (_, i) => i + 1),
                  },
                ])
              ),
        }
      : {
          categoryName: zoneKey,
          ticketType: zoneFreeSaleData.name,
          ticketPrice: Number(zoneFreeSaleData.price),
        };
    setTickets((tickets) =>
      tickets.find((ticket) => ticket.categoryName === zoneKey)
        ? tickets
        : [...tickets, ticketToSave]
    );
  }

  // Extracted function to calculate ticketsNum
  function calculateTicketsNum(rows, isAlreadyOnSale) {
    return isAlreadyOnSale
      ? Object.values(rows).reduce((acc, row) => acc + row.seats.length, 0)
      : Object.values(rows).reduce((acc, row) => acc + parseInt(row.total_seats), 0);
  }

  const handleCloseModal = () => {
    setModalWindow(false);
    setTickets((prevTickets) =>
      prevTickets.filter((ticket) => ticket.categoryName !== selectedZoneData[0])
    );
    document
      .querySelectorAll(".highlighted")
      .forEach((element) => element.classList.remove("highlighted"));
  };

  const handleBlurClick = () => {
    setModalWindow(false);
    document
      .querySelectorAll(".highlighted")
      .forEach((element) => element.classList.remove("highlighted"));
  };
  const toggleOnlineRows = () => {
    let zoneKey = selectedZoneData[0];
    const onlineSaleZone = onlineSaleZones[zoneKey];

    const hasSeatsInOnlineSale =
      onlineSaleZone.rows && Object.values(onlineSaleZone.rows).some((row) => row.seats.length > 0);

    const isAlreadyOnSale = onlineSaleZone?.on_sale || hasSeatsInOnlineSale;

    const updatedTickets = tickets.map((zone) => {
      if (zone.categoryName === selectedZoneData[0]) {
        const updatedRows = { ...zone.rows };
        Object.entries(selectedZoneData[1].rows).forEach(([rowKey, rowValue]) => {
          updatedRows[rowKey] = {
            ...updatedRows[rowKey],
            seats: isOnlineSelected
              ? []
              : isAlreadyOnSale
              ? selectedZoneData[1].rows[rowKey].seats
              : Array.from({ length: Number(zone.rows[rowKey].total_seats) }, (_, i) => i + 1),
          };
        });

        return {
          ...zone,
          rows: updatedRows,
          ticketsNum: Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0),
        };
      }
      return zone;
    });

    setIsOnlineSelected((prev) => !prev);
    setTickets(updatedTickets);
  };
  const toggleAllRows = () => {
    const newAllSelected = !isAllSelected;

    setTickets((prevTickets) => {
      return prevTickets.map((zone) => {
        if (zone?.categoryName !== selectedZoneData?.[0]) return zone;

        const updatedRows = { ...zone.rows };
        if (selectedZoneData?.[1]?.rows) {
          Object.entries(selectedZoneData[1].rows).forEach(([rowKey]) => {
            updatedRows[rowKey] = {
              ...updatedRows[rowKey],
              seats: newAllSelected
                ? Array.from({ length: Number(zone.rows[rowKey]?.total_seats) }, (_, i) => i + 1)
                : [],
            };
          });
        }

        return {
          ...zone,
          rows: updatedRows,
          ticketsNum: Object.values(updatedRows).reduce(
            (acc, row) => acc + (row?.seats?.length || 0),
            0
          ),
        };
      });
    });

    setIsAllSelected(newAllSelected);
  };

  const fetchReserveFreeSaleTickets = async (zoneKey) => {
    const ticketCategory = tickets.find((ticket) => ticket.categoryName === zoneKey);
    const ticketsToReserve = Object.entries(ticketCategory.rows).flatMap(([rowKey, rowValue]) =>
      rowValue.seats.map((seat) => ({
        category: ticketCategory.categoryName,
        row: rowKey,
        seat: String(seat),
      }))
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/freeSale/reserve-free-sale-tickets`,
        {
          token,
          concertId,
          ticketsToReserve,
        }
      );

      setOnlineSaleZones(response.data.zones);
      continueWithReserveFreeSaleTicketsResponse(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setLoginIsOpen(true));
      }
      toast.error(error?.response?.data?.message, toastSetup("top-center", 3000));
    }
  };

  const continueWithReserveFreeSaleTicketsResponse = (response) => {
    if (!response.unavailableTickets.length) {
      toast.success("Rezervacija uspješna", toastSetup("top-center", 2000));
    } else {
      setTickets((prevTickets) =>
        prevTickets.map((zone) => {
          // Check if this zone matches any unavailable ticket's category
          const unavailableForZone = response.unavailableTickets.filter(
            (ticket) => ticket.category === zone.categoryName
          );

          if (unavailableForZone.length > 0) {
            // Deep copy of rows to avoid direct mutation
            const updatedRows = { ...zone.rows };

            // Filter out unavailable seats for each row
            unavailableForZone.forEach(({ row, seat }) => {
              if (updatedRows[row]?.seats) {
                updatedRows[row].seats = updatedRows[row].seats.filter((s) => s !== Number(seat));
              }
            });

            return {
              ...zone,
              rows: updatedRows,
              ticketsNum: Object.values(updatedRows).reduce(
                (acc, row) => acc + row.seats.length,
                0
              ),
            };
          }

          return zone; // Return unchanged zone if no unavailable tickets
        })
      );

      if (response.unavailableTickets.length <= 6) {
        const unavailableTicketsByRow = response.unavailableTickets.reduce((acc, ticket) => {
          if (!acc[ticket.row]) {
            acc[ticket.row] = [];
          }
          acc[ticket.row].push(ticket.seat);
          return acc;
        }, {});

        const unavailableTicketsString = Object.entries(unavailableTicketsByRow)
          .map(([row, seats]) => `Red: ${row}, sjedalo: ${seats.join(", ")}`)
          .join(", ");

        toast.warn(
          `Rezervacija nije uspješna za (${response.unavailableTickets.length}) zauzeta sjedala. ${unavailableTicketsString}`,
          toastSetup("top-center", 2000)
        );
      } else {
        toast.warn(
          `Rezervacija nije uspješna za ${response.unavailableTickets.length} sjedala.`,
          toastSetup("top-center", 2000)
        );
      }
    }
  };

  return (
    imageLoaded && (
      <>
        {modalWindow ? (
          <>
            <div className="add-ticket-modal">
              <div className="modal-wrapper">
                <div className="modal-top-sticky">
                  <div className="modal-header">
                    <div className="header">
                      <div className="modal-title">
                        Zona: {selectedZoneData[0]} {"-"}{" "}
                        <small>
                          Ukupno:{" "}
                          {tickets.find((ticket) => ticket.categoryName === selectedZoneData[0])
                            ?.ticketsNum || 0}
                        </small>
                      </div>
                      <div className="close-modal">
                        <CheckCheck size={18} onMouseDown={() => handleBlurClick()} />
                        <Trash2
                          onClick={() => {
                            handleCloseModal();
                          }}
                          size={18}
                        />
                      </div>
                    </div>

                    <div className="zone-numbers-detail"></div>
                  </div>
                  <div className="modal-form">
                    <div className="modal-input">
                      <div className="modal-label">Tip ulaznice</div>
                      <input
                        className="table-input"
                        type="text"
                        placeholder="Tip ulaznice"
                        value={
                          tickets.find((ticket) => ticket.categoryName === selectedZoneData[0])
                            ?.ticketType
                        }
                        onChange={(e) => {
                          setTickets((prevTickets) =>
                            prevTickets.map((ticket) => {
                              if (ticket.categoryName === selectedZoneData[0]) {
                                return {
                                  ...ticket,
                                  ticketType: e.target.value,
                                };
                              }
                              return ticket;
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="modal-input">
                      <div className="modal-label">Cijena zone</div>
                      <input
                        className="table-input"
                        type="number"
                        value={
                          tickets.find((ticket) => ticket.categoryName === selectedZoneData[0])
                            ?.ticketPrice
                        }
                        placeholder="Unesite cijenu (BAM)"
                        onChange={(e) => {
                          setTickets((prevTickets) =>
                            prevTickets.map((ticket) => {
                              if (ticket.categoryName === selectedZoneData[0]) {
                                return {
                                  ...ticket,
                                  ticketPrice: e.target.value,
                                };
                              }
                              return ticket;
                            })
                          );
                        }}
                      />
                      <small>BAM</small>
                    </div>
                    {selectedZoneData && !selectedZoneData[1]?.rows && (
                      <div className="modal-input">
                        <div className="modal-label">Broj ulaznica</div>
                        <input
                          className="table-input"
                          placeholder="Broj ulaznica"
                          type="number"
                          value={
                            tickets.find((ticket) => ticket.categoryName === selectedZoneData[0])
                              ?.ticketsNum
                          }
                          onChange={(e) => {
                            setTickets((prevTickets) =>
                              prevTickets.map((ticket) => {
                                if (ticket.categoryName === selectedZoneData[0]) {
                                  return {
                                    ...ticket,
                                    ticketsNum: e.target.value,
                                  };
                                }
                                return ticket;
                              })
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {selectedZoneData[1]?.rows && (
                    <div className="maper-button-wrapper">
                      <div className="modal-input">
                        <div className="modal-label">Sve ulaznice</div>
                        <div className="switch">
                          <input
                            className="switch-input"
                            type="checkbox"
                            id={`switch-all-rows-for-${selectedZoneData[0]}`}
                            checked={isAllSelected}
                            onChange={() => toggleAllRows()}
                          />
                          <label
                            className="switch-label"
                            htmlFor={`switch-all-rows-for-${selectedZoneData[0]}`}
                          ></label>
                        </div>
                      </div>
                      <div className="modal-input">
                        <div className="modal-label">Online ulaznice</div>
                        <div className="switch">
                          <input
                            className="switch-input"
                            type="checkbox"
                            id={`switch-online-rows-for-${selectedZoneData[0]}`}
                            checked={isOnlineSelected}
                            onChange={() => toggleOnlineRows()}
                          />
                          <label
                            className="switch-label"
                            htmlFor={`switch-online-rows-for-${selectedZoneData[0]}`}
                          ></label>
                        </div>
                      </div>
                      <div
                        className="save-zone-rows-btn"
                        onClick={() => fetchReserveFreeSaleTickets(selectedZoneData[0])}
                      >
                        <SearchCheck size={20} />
                        <span>Rezerviraj</span>
                      </div>
                    </div>
                  )}
                </div>
                {selectedZoneData[1]?.rows && (
                  <div className="scroll-wrapper">
                    <SeatDisplay
                      selectedZoneData={selectedZoneData}
                      tickets={tickets}
                      setTickets={setTickets}
                      onlineSaleZones={onlineSaleZones}
                      isAllSelected={isAllSelected}
                    />
                  </div>
                )}
              </div>
            </div>
            <div onClick={() => handleBlurClick()} className="blur"></div>
          </>
        ) : (
          ""
        )}
        {imageLoaded && (
          <div className="organize-event-plan-wrapper">
            <div
              id="tooltip"
              display="none"
              style={{ position: "absolute", display: "none" }}
            ></div>
            <PrintTicketImageMapper
              cb={(editor) => {
                editor.loadImage(groundPlanUrl); // Load the URL directly
                editor.polygon();
              }}
              options={{
                width: groundPlan.width,
                height: groundPlan.height,
              }}
              tickets={tickets}
              handleZoneClick={handleZoneClick}
              freeSaleZones={freeSaleZones}
              onlineSaleZones={onlineSaleZones}
            />
          </div>
        )}
      </>
    )
  );
};
