import { useState } from "react";
import { UserPlus, Minimize2, X } from "lucide-react";

const OwnersModal = ({ owners = [], tickets, setTickets, index }) => {
  const [open, setOpen] = useState(false);
  const [stringsToRemove, setStringsToRemove] = useState([
    "ZAPAD",
    "ISTOK",
    "JUG",
    "PARTER",
    "SJEVER",
    "REGULAR",
    "VIP",
    "PREMIUM",
    "PARTER",
  ]);

  const addOwners = (owners) => {
    if (!owners) {
      return;
    }
    const newOwners = owners
      .split(/[,\n;:.]/)
      .map((owner) => owner.trim())
      .filter((owner) => owner !== "")
      .map((owner) => {
        const regex = new RegExp(`(${stringsToRemove.join("|")})`, "ig");
        return owner.replace(regex, "").replace(/[-.!]/g, "").trim();
      });

    setTickets((prevTickets) => {
      const newTickets = [...prevTickets];
      newTickets[index].owners = newOwners;
      return newTickets;
    });
  };

  const setOwnerForAll = (owner) => {
    if (owner === "") return;
    const ticketsNum = Number(tickets[index].ticketsNum) || 0;
    setTickets((prevTickets) => {
      const newTickets = [...prevTickets];
      newTickets[index].owners = Array(ticketsNum)
        .fill(owner)
        .filter((_, i) => i < ticketsNum);
      return newTickets;
    });
  };

  const removeOwner = (ownerIndex) => {
    setTickets((prevTickets) => {
      const newTickets = [...prevTickets];
      newTickets[index].owners.splice(ownerIndex, 1);
      return newTickets;
    });
  };

  const removeAllOwners = () => {
    setTickets((prevTickets) => {
      const newTickets = [...prevTickets];
      newTickets[index].owners = [];
      return newTickets;
    });
  };

  return (
    <div className="owners-container">
      <div className="tabel-number">{owners && owners?.length}</div>
      <div className="open-modal" onClick={() => setOpen(!open)}>
        <UserPlus size={18} />
      </div>
      {open && (
        <div className="modal-wrapper-owners">
          <div className="close-modal" onClick={() => setOpen(false)}>
            <Minimize2 size={20} />
          </div>
          <div className="header">Dodaj vlasnike ulaznica za {tickets[index].categoryName}</div>
          <div className="add-owners-modal">
            <div className="add-owners-container">
              <input
                type="text"
                className="owner-input"
                placeholder="Vlasnik svih ulaznica"
                spellCheck="false"
                onBlur={(e) => setOwnerForAll(e.target.value)}
              />
              <textarea
                placeholder="Dodaj vlasnike"
                spellCheck="false"
                className="textarea"
                onChange={(e) => addOwners(e.target.value)}
              />
              <div className="filter-strings">
                Filtrirane riječi:
                {stringsToRemove.map((string, i) => (
                  <span
                    key={i}
                    className="filter-string"
                    onClick={() => setStringsToRemove((prev) => prev.filter((s) => s !== string))}
                  >
                    {string}
                  </span>
                ))}
              </div>
              <input
                type="text"
                className="owner-input"
                placeholder="Dodaj riječi za filtriranje"
                spellCheck="false"
                onBlur={(e) => {
                  if (e.target.value === "") return;
                  setStringsToRemove((prev) => {
                    if (!prev.includes(e.target.value)) {
                      return [...prev, e.target.value];
                    }
                    return prev;
                  });
                }}
              />
            </div>

            {owners && owners?.length > 0 && (
              <div className="owners-list-scroll">
                <div className="owners-list">
                  <div className="owners">
                    <span>Broj vlasnika: {owners?.length}</span>
                    <X className="remove-owner" onClick={() => removeAllOwners()} />
                  </div>
                  <div className="owners">
                    {owners.map((owner, ownerIndex) => (
                      <div className="owner" key={ownerIndex}>
                        <span>{owner}</span>
                        <X className="remove-owner" onClick={() => removeOwner(ownerIndex)} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnersModal;
