import React, { useState, useEffect } from "react";
import { PrintPage } from "./PrintPage";
import { CircleX } from "lucide-react";

import axios from "axios";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import { useSelector } from "react-redux";

const PrintTickets = ({ eventData, userId, setOpenPrintTickets }) => {
  const [profileData, setProfileData] = useState(null);
  const [concertData, setConcertData] = useState(null);
  const translate = useSelector((state) => state.language) === "en";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileResponse, concertResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/id/${userId}`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${eventData.concertId}`),
        ]);

        const profile = profileResponse.data;
        if (!profile.is_verified) {
          const message = translate
            ? `To safely receive tickets, it is best to verify your email: "${profile.email}".`
            : `Za sigurno primanje ulaznica, najbolje je verificirati vaš email: "${profile.email}".`;
          toast.warn(message, toastSetup("top-right", 3000));
          return;
        }
        setProfileData(profile);
        setConcertData(concertResponse.data[0]);
      } catch (error) {
        toast.error(
          `Problem pri dohvatanju podataka. Pokušajte se odjaviti i ponovo prijaviti.`,
          toastSetup("top-right", 3000)
        );
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [eventData.concertId, userId, translate]);

  return (
    <span className="print-ticket-manager-modal-window">
      <div className="close-modal" onClick={() => setOpenPrintTickets(false)}>
        <CircleX />
      </div>
      {concertData && profileData && (
        <PrintPage
          concertData={concertData}
          setConcertData={setConcertData}
          profileData={profileData}
        />
      )}
    </span>
  );
};

export default PrintTickets;
