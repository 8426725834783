import { LoaderCircle } from "lucide-react";

const Button = ({ children, onClick, className, style, loader = true, disabled, ...props }) => {
  return (
    <button
      onClick={onClick}
      className={`profile-btn ${className} btn-with-loader ${disabled ? "disabled" : ""}`}
      style={style}
      disabled={disabled}
      {...props}
    >
      {loader && disabled && <LoaderCircle className="loading-spinner" />}
      {children}
    </button>
  );
};

export default Button;
