import React, { useEffect, useState } from "react";
import PlusIcon from "../../../assets/ikonice/plus_icon.svg";
import { AddPayment } from "./AddPayment";
import { SellingInfo } from "./SellingInfo";
import { ShieldCheck, ShieldX, PrinterCheck, CircleSlash } from "lucide-react";

export const EventDayCard = ({ setMarginB, iterator, data, concertId }) => {
  const [moneyLeft, setMoneyLeft] = useState(0);
  const [addedInputs, setAddedInputs] = useState(0);
  const [sellingInfo, setSellingInfo] = useState(data?.transactions || []);

  useEffect(() => {
    if (data?.transactions?.length) {
      const totalVerifiedPrice = data.transactions
        .filter((transaction) => transaction?.is_verified)
        .reduce((total, transaction) => total + parseFloat(transaction?.price || 0), 0);

      const soldTicketsPrice = Object.values(data?.zones || {}).reduce(
        (total, categoryData) => total + (categoryData?.sold || 0) * (categoryData?.price || 0),
        0
      );

      setMoneyLeft(soldTicketsPrice - totalVerifiedPrice);
    }
  }, [data?.transactions, data?.zones]);

  const handleImageClick = () => {
    setAddedInputs((prev) => prev + 1);
    setMarginB((prev) => prev + 76);
  };
  const renderZoneInfo = () =>
    Object.entries(data?.zones || {}).map(([categoryName, categoryData]) => (
      <div key={categoryName}>
        <p className="semi-bold">
          {categoryName} - {categoryData?.name}
        </p>
        <p>
          Prodano: {categoryData?.sold || 0}/{categoryData?.loaned || 0}
        </p>
        <p>
          Cijena: {categoryData?.price || 0} <small>BAM</small>
        </p>
      </div>
    ));

  const soldTickets = Object.values(data?.zones || {}).reduce(
    (total, categoryData) => total + (categoryData?.sold || 0),
    0
  );
  const soldTicketsPrice = Object.values(data?.zones || {}).reduce(
    (total, categoryData) => total + (categoryData?.sold || 0) * (categoryData?.price || 0),
    0
  );

  return (
    <div className="resellers-card">
      <div className="resellers-card-top-row">
        <div>
          <h6>{data?.reseller_name}</h6>
          <p>{data?.reseller_address}</p>
        </div>
        <div className="resellers-info">{renderZoneInfo()}</div>
        <div className="resellers-totals">
          <p>Prodano: {soldTickets}</p>
          <p>
            {soldTicketsPrice} <small>BAM</small>
          </p>
        </div>
        <div className="status-box">
          <div className="reseller-status-wrapper">
            <span>
              <small>Status: </small>
              {data.active ? (
                <span className="status-active">
                  <ShieldCheck size={18} />
                  Aktivan
                </span>
              ) : (
                <span className="status-inactive">
                  <ShieldX size={18} />
                  Neaktivan
                </span>
              )}
            </span>
          </div>
          <div className="reseller-status-wrapper">
            <span>
              <small>Print: </small>
              {data.can_print_tickets ? (
                <span className="status-active">
                  <PrinterCheck size={18} />
                  Aktivan
                </span>
              ) : (
                <span className="status-inactive">
                  <CircleSlash size={18} />
                  Neaktivan
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
      {sellingInfo.map((transaction, index) => (
        <SellingInfo key={index} transaction={transaction} />
      ))}
      <div className="resellers-card-last-row">
        {Array.from({ length: addedInputs }, (_, index) => (
          <AddPayment
            key={index}
            setSellingInfo={setSellingInfo}
            resellerId={data?.reseller_id}
            concertId={concertId}
          />
        ))}
        <p>
          Preostalo: {moneyLeft} <small>BAM</small>
        </p>
        {!addedInputs && (
          <img onClick={handleImageClick} className="plus-icon" src={PlusIcon} alt="Plus" />
        )}
      </div>
    </div>
  );
};
