import { CreditCard } from "lucide-react";

const TotalPrice = ({ ticketAmount, totalAmount, translate }) => {
  return (
    <div className="total-price">
      <div className="total-amount flex-between">
        <span className="main-text">{translate ? "Total price" : "Ukupna cijena"}</span>
        <span className="main-text">
          {totalAmount
            ? Math.round(
                (totalAmount + ticketAmount * 1.06 + (totalAmount + ticketAmount) * 0.056) *
                  100 *
                  (translate ? 0.52 : 1)
              ) / 100
            : 0}
          <span className="currency-styles">{translate ? " €" : " BAM"}</span>
        </span>
      </div>
      <div className="flex-between">
        <span className="secondary-text">
          {translate ? "Accumulative spending" : "Agencijski troškovi"}
        </span>
        <span className="secondary-text">
          {totalAmount
            ? Math.round(
                (ticketAmount * 1.06 + (totalAmount + ticketAmount) * 0.056) *
                  100 *
                  (translate ? 0.52 : 1)
              ) / 100
            : 0}
          <span className="currency-styles">{translate ? " €" : " BAM"}</span>
        </span>
      </div>
      <div className="button-with-icon pay-button text-with-icon">
        <CreditCard />
        <span>Idi na plaćanje</span>
      </div>
    </div>
  );
};

export default TotalPrice;
