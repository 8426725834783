import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import hr from "../../../components/helper/hr";
import countryMap from "../../../components/helper/countryMap";
import { toastSetup } from "../../../functions/toastSetup";
import { UpdateProfileInput } from "./UpdateProfileInput";
import { setToken, setUserID } from "../../../store/userSlice";
import { useNavigate } from "react-router-dom";
import { setLoginIsOpen } from "../../../store/loginSlice";
import { Check, X } from "lucide-react";

import { Trash2, Save } from "lucide-react";
import Button from "../../../components/Button";
import Separator from "../../../components/Separator";

export const UpdateProfilePage = ({ profileData }) => {
  const [name, setName] = useState(profileData.full_name.split(" ")[0]);
  const [lname, setLname] = useState(profileData.full_name.split(" ")[1]);
  const [email, setEmail] = useState(profileData.email);
  const [city, setCity] = useState(profileData.city);
  const [zip, setZip] = useState(profileData.zip);
  const [address, setAddress] = useState(profileData.address);
  const [password, setPassword] = useState("");
  const [repatePassword, setRepatePassword] = useState("");
  const [isModalVisible, setModalVisibility] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState(profileData.phone);
  const reverseCountry = (full_name) => {
    for (const code in countryMap) {
      if (countryMap[code] === full_name) {
        return code;
      }
    }
    return null;
  };
  const [country, setCountry] = useState(reverseCountry(profileData.country) || "BA");
  const id = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== "" && (password.length < 6 || password !== repatePassword)) {
      if (password.length < 6) {
        toast.warn("Lozinka mora imati barem 6 znakova!", toastSetup("top-right", 2000));
      } else if (password !== repatePassword) {
        toast.warn("Lozinke se ne podudaraju!", toastSetup("top-right", 2000));
      }
      return;
    }

    const user = {
      full_name: name + " " + lname,
      email: email,
      address: address,
      city: city,
      country: countryMap[country],
      zip: zip,
      phone: phone,
      password: password !== "" ? password : undefined,
    };

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/users/${id}`;

    try {
      await axios.patch(
        apiUrl,
        { user, token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Uspješno ste ažurirali podatke", toastSetup("top-right", 2000));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setLoginIsOpen(true));
      }

      const errorMessage = error.response?.data?.message || "Nepoznata pogreška.";
      toast.error(
        `Došlo je do pogreške prilikom ažuriranja podataka. ${errorMessage}!`,
        toastSetup("top-center", 4000)
      );
    }
  };

  function deleteUserProfile() {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/users/delete_user/${id}`, {
        data: { token }, // Send the token in the request body
        headers: {
          "Content-Type": "application/json", // Set the content type if needed
          // Other headers as needed
        },
      })
      .then((response) => {
        toast.success(`${response.data.message}`, toastSetup("top-right", 2000));
        setModalVisibility(true);
        dispatch(setUserID(""));
        dispatch(setToken(""));
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setModalVisibility(false);
          dispatch(setLoginIsOpen(true));
        }
        toast.error(`${error.response.data.message}`, toastSetup("top-right", 2000));
      });
  }

  return (
    <>
      <form className="form container smaller-profile " onSubmit={handleSubmit}>
        {isModalVisible ? (
          <>
            <div className="modal-window">
              <h6>Jeste li sigurni da želite obrisati profil?</h6>
              <div className="profile-btns-row">
                <Button onClick={deleteUserProfile} type="button">
                  <Check /> <span>Da</span>
                </Button>
                <Button
                  onClick={() => {
                    setModalVisibility(false);
                  }}
                  type="button"
                >
                  <X />
                  <span>Ne</span>
                </Button>
              </div>
            </div>
            <div
              onClick={() => {
                setModalVisibility(false);
              }}
              className="blur"
            ></div>
          </>
        ) : (
          ""
        )}
        <h6>Ažuriranje podataka</h6>
        <Separator />
        <div className="update-profile-form-wrapper center-btn">
          <UpdateProfileInput placeholder="Ime" id="name" inputValue={name} setValue={setName} />
          <UpdateProfileInput
            placeholder="Prezime"
            id="lname"
            inputValue={lname}
            setValue={setLname}
          />
          <UpdateProfileInput
            placeholder="Email"
            id="email"
            inputValue={email}
            setValue={setEmail}
          />
          <Separator />
          <UpdateProfileInput placeholder="Grad" id="city" inputValue={city} setValue={setCity} />
          <UpdateProfileInput placeholder="Zip" id="zip" inputValue={zip} setValue={setZip} />

          <UpdateProfileInput
            placeholder="Adresa"
            id="address"
            inputValue={address}
            setValue={setAddress}
          />
          <div className="input-field-custom-wrapper">
            <label htmlFor="phone" className="input-label-custom">
              Država / Telefon
            </label>
            <PhoneInput
              placeholder="Mobitel"
              value={phone || ""}
              onChange={setPhone}
              onCountryChange={setCountry}
              defaultCountry={country || "BA"}
              international={true}
              countryCallingCodeEditable={false}
              label={country}
              countryOptionsOrder={[
                "BA",
                "HR",
                "RS",
                "AL",
                "BG",
                "GR",
                "XK",
                "ME",
                "MK",
                "RO",
                "SI",
                "DE",
                "AT",
                "IT",
              ]}
              labels={hr}
              locales="hr"
            />
          </div>
          <Separator />
          <UpdateProfileInput
            placeholder="Nova lozinka"
            id="password"
            type="password"
            inputValue={password}
            setValue={setPassword}
          />

          <UpdateProfileInput
            placeholder="Ponovi lozinku"
            id="repatePassword"
            type="password"
            inputValue={repatePassword}
            setValue={setRepatePassword}
          />
        </div>
      </form>
      <div className="profile-btns-row">
        <Button type="button" onClick={handleSubmit}>
          <Save size={20} /> <span>Spremi promjene</span>
        </Button>
        <Button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setModalVisibility(true);
          }}
        >
          <Trash2 size={20} /> <span>Obriši profil</span>
        </Button>
      </div>
    </>
  );
};
