import React, { useMemo, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import { Editor } from "@tinymce/tinymce-react";
import tinyMCEConfig from "../../../components/helper/tinyConfig";
import { useDispatch, useSelector } from "react-redux";

import { setLoginIsOpen } from "../../../store/loginSlice";
import { CustomSelect, CustomSwitcher, InputField } from "../organize-event-page/Inputs";
import { AddOrganizer } from "../organize-event-page/components/AddOrganizer";
import Separator from "../../../components/Separator";
import SetTemplate from "../add-tickets/SetTemplate";
import SponsorInput from "../organize-event-page/SponsorInput";
import { ImageUploader } from "../organize-event-page/ImageUploader";

export const UpdateEventContainer = ({ concertData, setConcertData }) => {
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const dispatch = useDispatch();

  const [editedConcertData, setEditedConcertData] = useState(concertData);
  const id = concertData._id;

  const changedAttributes = useMemo(() => {
    const changed = {};

    for (const key in editedConcertData) {
      if (concertData[key] !== editedConcertData[key]) {
        changed[key] = editedConcertData[key];
      }
    }

    if (changed.time_of_event) {
      changed.time_of_event = new Date(changed.time_of_event).toUTCString();
    }

    if (changed.sale_start) {
      changed.sale_start = new Date(changed.sale_start).toUTCString();
    }

    return changed;
  }, [editedConcertData, concertData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/concerts/update_event",
        {
          id,
          data: changedAttributes,
          userId,
          token,
        }
      );
      setConcertData(response.data.updatedEvent);
      setEditedConcertData(response.data.updatedEvent);
      toast.success(response?.data?.message, toastSetup("top-center", 1500));
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        dispatch(setLoginIsOpen(true));
      }

      toast.error(error?.response?.data?.message, toastSetup("top-center", 3000));
    }
  };

  const handleUploadSuccess = (imageNames) => {
    setEditedConcertData((prev) => ({
      ...prev,
      poster: {
        portrait: imageNames[0],
        landscape: imageNames[1],
      },
    }));
  };

  const numberOfChangedAttributes = useMemo(() => {
    return Object.keys(changedAttributes).length;
  }, [changedAttributes]);

  return (
    concertData && (
      <form onSubmit={handleSubmit} className="general-info-form">
        {numberOfChangedAttributes > 0 && (
          <div className="info-container">
            Ne zaboravi spremiti promjene!
            <button type="submit">Spremi promjene ({numberOfChangedAttributes})</button>
          </div>
        )}
        <ImageUploader aspectRatios={["2 / 3", "16 / 9"]} onUploadSuccess={handleUploadSuccess} />
        <Separator />
        <InputField
          label="Naziv događaja"
          type="text"
          id="performer_name"
          name="performer_name"
          value={editedConcertData.performer_name}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              performer_name: e.target.value,
            })
          }
        />

        <InputField
          label="Vrijeme događaja"
          type="datetime-local"
          id="time_of_event"
          name="time_of_event"
          value={editedConcertData?.time_of_event}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              time_of_event: e.target.value,
            })
          }
        />
        <CustomSelect
          label="Tip događaja"
          options={[
            { value: "concert", label: "Koncert" },
            { value: "festival", label: "Festival" },
            { value: "theaters", label: "Predstava" },
            { value: "sport", label: "Sport" },
            { value: "other", label: "Ostalo" },
          ]}
          value={
            ["concert", "festival", "theaters", "sport", "other"].includes(
              editedConcertData?.type?.[0]
            )
              ? editedConcertData.type[0]
              : ""
          }
          onChange={(e) => {
            const selectedType = e.target.value;
            setEditedConcertData({
              ...editedConcertData,
              type: [
                ...editedConcertData.type.filter(
                  (t) => !["concert", "festival", "theaters", "sport", "other"].includes(t)
                ),
                selectedType,
              ],
            });
          }}
          name="type"
        />
        <CustomSwitcher
          label="Sakrij događaj"
          checked={editedConcertData?.hidden === true}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              hidden: e.target.checked,
            })
          }
          id="hidden"
        />
        <InputField
          label="Država"
          type="text"
          id="place.country"
          name="place.country"
          value={editedConcertData.place.country}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              place: {
                ...editedConcertData.place,
                country: e.target.value,
              },
            })
          }
        />
        <InputField
          label="Grad"
          name="place.city"
          value={editedConcertData.place.city}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              place: {
                ...editedConcertData.place,
                city: e.target.value,
              },
            })
          }
        />
        <InputField
          label="Mjesto"
          name="place.place"
          value={editedConcertData.place.place}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              place: {
                ...editedConcertData.place,
                place: e.target.value,
              },
            })
          }
        />

        <AddOrganizer
          eventData={editedConcertData}
          updateEventData={(property, value) =>
            setEditedConcertData((prev) => ({ ...prev, [property]: value }))
          }
        />
        <CustomSwitcher
          id="personalization"
          label="Personalizacija"
          checked={editedConcertData?.credentials_required === true}
          name="credentials_required"
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              credentials_required: e.target.checked,
            })
          }
        />
        <CustomSwitcher
          id="on_sale"
          label="Online prodaja"
          checked={editedConcertData?.on_sale === true}
          name="on_sale"
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              on_sale: e.target.checked,
            })
          }
        />

        <InputField
          type="datetime-local"
          label="Početak prodaje"
          name="sale_start"
          value={editedConcertData?.sale_start}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              sale_start: new Date(e.target.value),
            })
          }
        />
        <InputField
          label="Broj ulaznica po korisniku"
          type="number"
          id="max_tickets_per_user"
          name="max_tickets_per_user"
          value={editedConcertData?.max_tickets_per_user}
          placeholder={"Broj ulaznica po korisniku"}
          onChange={(e) =>
            setEditedConcertData({
              ...editedConcertData,
              max_tickets_per_user: Number(e.target.value),
            })
          }
        />
        <SetTemplate
          template={editedConcertData?.template}
          setTemplate={(template) =>
            setEditedConcertData({
              ...editedConcertData,
              template,
            })
          }
          isPDF={true}
        />
        <SponsorInput eventData={editedConcertData} setEventData={setEditedConcertData} />
        <Separator />
        <h6>Opis događaja</h6>
        <Editor
          apiKey={tinyMCEConfig.apiKey}
          value={String(editedConcertData.description || "")}
          init={tinyMCEConfig}
          onEditorChange={(content) =>
            setEditedConcertData({
              ...editedConcertData,
              description: content,
            })
          }
        />

        <button type="submit">Spremi promjene</button>
      </form>
    )
  );
};
