import { initReactI18next } from "react-i18next";
import i18next from "i18next"; // Import i18next
import en from "./en.json";
import hr from "./hr.json";

const userLang = navigator.language || navigator.userLanguage;

i18next.use(initReactI18next).init({
  lng: userLang.startsWith("hr") ? "hr" : "en", // Default to 'hr' if browser language starts with 'hr'
  fallbackLng: "hr",
  resources: {
    en: { translation: en },
    hr: { translation: hr },
  },
});

export default i18next;
