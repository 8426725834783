import { useState, useEffect } from "react";
import axios from "axios";
import { CustomSelect, InputField } from "./Inputs";
import useDebounce from "../../../functions/useDebounce";

const PlaceInput = ({ eventData, setEventData }) => {
  const [eventPlaces, setEventPlaces] = useState([]);
  const [cities, setCities] = useState();
  const [cityInput, setCityInput] = useState("");
  const [shouldFetchCities, setShouldFetchCities] = useState(true);

  // Debounce the city input value
  const debouncedCityInput = useDebounce(cityInput, 300);

  const handlePlaceChange = async (selectedHall) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/places/zones`, {
        params: { selectedHall },
      });

      const { location, type, zones, ground_plan } = response.data;

      const fixedZones = Object.fromEntries(
        Object.entries(zones).map(([zoneKey, { type, rows, price, amount, ...rest }]) => {
          return [
            zoneKey,
            {
              ...rest,
              name: type?.trim() || "",
              on_sale: !rows,
              rows,
              price: Number(price) || 0,
              amount: Number(amount) || 0,
              max_amount: Number(amount) || 0,
            },
          ];
        })
      );
      setEventData((prev) => ({
        ...prev,
        place: {
          ...prev.place,
          place: selectedHall,
          city: location,
          type: type,
          ground_plan: ground_plan || "",
        },
        tickets: { ...prev.tickets, online_sale: { zones: fixedZones } },
      }));

      // Iterate over each zone key and set on_sale to false
    } catch (error) {
      console.error("Error fetching zones:", error);
    }
  };
  useEffect(() => {
    if (!debouncedCityInput || !shouldFetchCities) {
      return;
    }

    const fetchCities = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/places/city_name/${debouncedCityInput}`
        );
        setCities(response.data.city);
      } catch (error) {
        setCities([error.response?.data?.msg || "Error fetching cities"]);
      }
    };

    fetchCities();
  }, [debouncedCityInput, shouldFetchCities]);

  useEffect(() => {
    const fetchPlaces = async () => {
      if (!eventData?.place?.city) {
        setEventPlaces([]);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/places/place_by_location/${eventData?.place?.city}`
        );
        setEventPlaces(response.data.placeNames.map((place) => ({ label: place, value: place })));
      } catch (error) {
        console.error("Error fetching concert halls:", error);
      }
    };

    fetchPlaces();
  }, [eventData?.place?.city]);

  return (
    <>
      <InputField
        label="Država"
        name="country"
        placeholder="Država"
        grow={true}
        autoComplete="off"
        type="text"
        value={eventData?.place?.country}
        onChange={(e) =>
          setEventData((prev) => ({
            ...prev,
            place: { ...prev.place, country: e.target.value },
          }))
        }
      />
      <div className="combo-box">
        <InputField
          label="Grad"
          name="city"
          placeholder="Grad"
          autoComplete="off"
          type="text"
          value={cityInput}
          style={{ width: "100%" }}
          onChange={(e) => {
            setCityInput(e.target.value);
            setShouldFetchCities(true); // Allow fetching if the user types
          }}
        />
        <div className="all-cities-wrapper">
          <div className="city-wrapper">
            {cities &&
              cities.map((city, i) => (
                <span
                  key={i}
                  onClick={() => {
                    setCityInput(city);
                    setEventData((prev) => ({
                      ...prev,
                      place: { ...prev.place, city: city, place: "" },
                    }));
                    setCities([]);
                    setShouldFetchCities(false); // Disable further fetches
                  }}
                >
                  {city}
                </span>
              ))}
          </div>
        </div>
      </div>

      <CustomSelect
        label="Mjesto"
        options={eventPlaces}
        value={eventData?.place?.place || ""}
        onChange={(event) => handlePlaceChange(event.target.value)}
        name="place.place"
        grow={true}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default PlaceInput;
