import { useEffect, useState } from "react";

export default function useDebounce(value, delay, options = { leading: false }) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    let timeoutId;

    if (options.leading && !timeoutId) {
      setDebouncedValue(value);
    }

    timeoutId = setTimeout(() => {
      if (!options.leading) setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [value, delay, options.leading]);

  return debouncedValue;
}
