import React from "react";
import { Link } from "react-router-dom";
import { hrTimeFormat } from "../../../../components/helper/timeFormat";
import { useSelector } from "react-redux";
import { ShoppingCart, Info } from "lucide-react";

const MainSearchCard = ({ event }) => {
  const timeOfEvent = new Date(event.time_of_event);
  const date = timeOfEvent.toLocaleString("hr-HR", hrTimeFormat);
  const place = `${event.place?.city}, ${event.place?.place}`;

  const language = useSelector((state) => state.language);

  return (
    event && (
      <div className="search-card-container">
        <img
          src={`${process.env.REACT_APP_API_URL}/static/event-images/${event.poster?.landscape}`}
          alt="Poster Image"
          loading="lazy"
        />
        <div className="search-card-content-wrapper">
          <div className="card-info-wrapper">
            <h3>{event.performer_name}</h3>
            <p>
              {date} - {place}
            </p>
          </div>
          <div className="card-btns-wrapper">
            <Link to={`/single/${event._id}`}>
              <Info size={20} />
              <span>{language === "hr" ? "Pogledaj" : "Details"}</span>
            </Link>
            {/* sale_start postoji i ako postoji mora biti veći od danas. Ako ne postoji onda pokaži kupi uvijek */}
            {(!event?.sale_start || new Date(event?.sale_start) <= new Date()) && (
              <Link to={`/buy/${event._id}`}>
                <ShoppingCart size={20} />
                <span>{language === "hr" ? "Kupi" : "Buy"}</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default MainSearchCard;
