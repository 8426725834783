import { editor } from "@overlapmedia/imagemapper";
import React, { useMemo, useRef, useCallback, useEffect } from "react";

function OnlineImageMapper({ options = {}, style = {}, cb, handleZoneClick, zones }) {
  const elementRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) {
      const editorInstance = editor(elementRef.current, options, style);
      editorRef.current = editorInstance;
      if (cb) cb(editorInstance);
    }
    editorRef.current.selectMode();
  }, [options, style, cb]);

  const showTooltip = useCallback((e, data) => {
    const tooltip = document.getElementById("tooltip");
    const containerRect = document.querySelector(".buy-container").getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    let tooltipContent = `
        <p style="text-align: center; margin-bottom: -5px">Zona: ${data.zoneName || "N/A"}</p>
      `;

    if (data.ticket_name && data.available_seats) {
      tooltipContent += `
          <div>
            <p>Cijena: <small>${data.price || "0,00"} BAM</small></p>
            <small>
            Dostupno: ${data.available_seats || 0} / ${data.total_amount || 0}
            </small>
          </div>
        `;
    }

    tooltip.innerHTML = tooltipContent;
    Object.assign(tooltip.style, {
      left: `${x - 20}px`,
      top: `${e.clientY - 120}px`,
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      width: "170px",
    });
  }, []);

  const hideTooltip = useCallback(() => {
    document.getElementById("tooltip").style.display = "none";
  }, []);

  const showZoneTooltip = useCallback(
    (zoneData, zoneName, e) => {
      showTooltip(e, {
        zoneName,
        price: zones[zoneName]?.price || zoneData.price,
        total_amount: zones[zoneName]?.max_amount || zoneData.max_amount,
        available_seats: zones[zoneName]?.amount || zoneData.amount,
        ticket_name: zones[zoneName]?.name || zoneData.name,
      });
    },
    [showTooltip, zones]
  );
  const renderZones = useMemo(() => {
    if (!zones) return null;

    const zoneElements = Object.entries(zones).map(([zoneName, zoneData], index) => {
      const { amount, max_amount, on_sale } = zoneData;

      // Calculate remaining seats percentage
      const remainingSeatsPercentage = (amount / max_amount) * 100 || 0;

      // Determine the zone's color based on the reversed percentage logic
      let fillColor = "gray"; // Default color for zones not on sale
      if (on_sale && amount > 0) {
        if (remainingSeatsPercentage > 0 && remainingSeatsPercentage <= 100) {
          // Interpolate color between green (#00FF00) and yellow (#FFFF00)
          const greenToYellowReversed = (percentage) => {
            const green = 255; // Full green in RGB
            const red = Math.round(255 * ((100 - percentage) / 100)); // Increasing red as percentage decreases
            return `rgb(${red}, ${green}, 0)`;
          };

          fillColor = greenToYellowReversed(remainingSeatsPercentage);
        } else if (remainingSeatsPercentage === 0) {
          fillColor = "red"; // Optional: handle special case for no remaining seats
        }
      }

      const shapeProps = {
        fill: fillColor,
        stroke: "rgb(100, 100, 100)",
        cursor: "pointer",
        strokeWidth: "8",
        opacity: 0.45,
        strokeDasharray: "none",
        strokeLinejoin: "round",
        id: zoneName,
      };

      return (
        <g key={`${zoneData?.location?.shape}_${index}`}>
          {zoneData?.location?.shape === "rect" && (
            <rect
              {...shapeProps}
              width={zoneData.location.size.width}
              height={zoneData.location.size.height}
              x={zoneData.location.position.x}
              y={zoneData.location.position.y}
              onTouchEndCapture={(e) => handleZoneClick(e.target, [zoneName, zoneData])}
              onClick={(e) => handleZoneClick(e.target, [zoneName, zoneData])}
              onMouseMove={(e) => showZoneTooltip(zoneData, zoneName, e)}
              onMouseOut={hideTooltip}
            />
          )}
          {zoneData?.location?.shape === "polygon" && (
            <polygon
              {...shapeProps}
              points={zoneData.location.points}
              onTouchEndCapture={(e) => handleZoneClick(e.target, [zoneName, zoneData])}
              onClick={(e) => handleZoneClick(e.target, [zoneName, zoneData])}
              onMouseMove={(e) => showZoneTooltip(zoneData, zoneName, e)}
              onMouseOut={hideTooltip}
            />
          )}
        </g>
      );
    });

    return zoneElements;
  }, [handleZoneClick, showZoneTooltip, hideTooltip, zones]);

  return (
    <svg
      className="image-map-svg"
      ref={elementRef}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={options.width}
      height={options.height}
      viewBox={`0, 0, ${options.width}, ${options.height}`}
      preserveAspectRatio="xMinYMin"
    >
      {renderZones}
    </svg>
  );
}

export default OnlineImageMapper;
