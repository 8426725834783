import React, { useEffect, useState } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import SponsorModal from "./SponsorModal";

const SponsorInput = ({ eventData, setEventData }) => {
  const [existingSponsors, setExistingSponsors] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchSponsors();
  }, []);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const fetchSponsors = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/helper/sponsors`);
      setExistingSponsors(response.data);
    } catch (error) {
      toast.error("Greška pri dohvatanju sponzora", toastSetup("top-right", 3000));
    }
  };
  return (
    <>
      <div className="sponsors-container align-self-end">
        <button type="button" className="profile-btn " onClick={toggleModal}>
          Dodaj sponzore ({eventData?.sponsors?.length || 0})
        </button>
      </div>
      <SponsorModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        eventData={eventData}
        setEventData={setEventData}
        existingSponsors={existingSponsors}
      />
    </>
  );
};

export default SponsorInput;
