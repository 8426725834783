import { CheckCheck } from "lucide-react";
import SeatDisplayOrganize from "./SeatDisplayOrganize";

import { toast } from "react-toastify";
import { toastSetup } from "../../../../functions/toastSetup";

const ModalWindow = ({ eventData, setEventData, setModalWindow, selectedZone }) => {
  const zones = eventData.tickets.online_sale.zones;
  const handleBlurClick = () => {
    setModalWindow(false);
    document.querySelectorAll(".highlighted").forEach((el) => el.classList.remove("highlighted"));
  };

  const updateZone = (property, value) =>
    setEventData((prev) => ({
      ...prev,
      tickets: {
        ...prev.tickets,
        online_sale: {
          ...prev.tickets.online_sale,
          zones: {
            ...prev.tickets.online_sale.zones,
            [selectedZone]: {
              ...prev.tickets.online_sale.zones[selectedZone],
              [property]: property === "amount" ? value : value,
              max_amount:
                property === "amount"
                  ? value
                  : prev.tickets.online_sale.zones[selectedZone].max_amount,
            },
          },
        },
      },
    }));

  const toggleAllRows = () => {
    const selectedZoneData = eventData.tickets.online_sale.zones[selectedZone];
    const updatedRows = Object.entries(selectedZoneData?.rows || {}).some(
      ([, row]) => row.seats.length > 0
    )
      ? Object.keys(selectedZoneData.rows || {}).reduce((acc, rowKey) => {
          acc[rowKey] = {
            ...selectedZoneData.rows[rowKey],
            seats: [],
          };
          return acc;
        }, {})
      : Object.keys(selectedZoneData.rows || {}).reduce((acc, rowKey) => {
          acc[rowKey] = {
            ...selectedZoneData.rows[rowKey],
            seats: Array.from(
              { length: selectedZoneData.rows[rowKey]?.total_seats || 0 },
              (_, i) => i + 1
            ),
          };
          return acc;
        }, {});

    const updatedAmount = Object.values(updatedRows).reduce(
      (acc, row) => acc + row.seats.length,
      0
    );

    setEventData((prev) => ({
      ...prev,
      tickets: {
        ...prev.tickets,
        online_sale: {
          ...prev.tickets.online_sale,
          zones: {
            ...prev.tickets.online_sale.zones,
            [selectedZone]: {
              ...selectedZoneData,
              amount: updatedAmount,
              max_amount: updatedAmount,
              rows: updatedRows,
            },
          },
        },
      },
    }));
  };

  const toggleRowsProperty = () => {
    const selectedZoneData = eventData.tickets.online_sale.zones[selectedZone];

    const updatedZone =
      Object.keys(selectedZoneData.rows || {}).length === 0
        ? { ...selectedZoneData, rows: selectedZoneData.prev_rows }
        : {
            ...selectedZoneData,
            prev_rows: selectedZoneData.rows,
            rows: null,
          };

    const updatedAmount = Object.values(updatedZone.rows || {}).reduce(
      (acc, row) => acc + (row?.seats?.length || 0),
      0
    );

    setEventData((prev) => ({
      ...prev,
      tickets: {
        ...prev.tickets,
        online_sale: {
          ...prev.tickets.online_sale,
          zones: {
            ...prev.tickets.online_sale.zones,
            [selectedZone]: {
              ...updatedZone,
              amount: updatedAmount,
              max_amount: updatedAmount,
            },
          },
        },
      },
    }));
  };
  return (
    <>
      <div className="add-ticket-modal">
        <div className="modal-wrapper">
          <div className="modal-top-sticky">
            <div className="modal-header">
              <div className="header">
                <div className={`modal-title ${zones[selectedZone]?.on_sale ? "on-sale" : ""}`}>
                  Zona: {selectedZone} - {zones[selectedZone]?.max_amount || 0}
                </div>
                <div className="close-modal">
                  <CheckCheck size={18} onMouseDown={() => handleBlurClick()} />
                </div>
              </div>
            </div>
            <div className="modal-form">
              <div className="modal-input">
                <div className="modal-label">U prodaji</div>
                <div className="switch">
                  <input
                    className="table-input switch-input"
                    type="checkbox"
                    id={`switch-${zones[selectedZone]}`}
                    checked={zones[selectedZone].on_sale}
                    onChange={(e) => {
                      if (
                        !zones[selectedZone].on_sale &&
                        (!zones[selectedZone]?.amount ||
                          !zones[selectedZone]?.price ||
                          !zones[selectedZone]?.name)
                      ) {
                        toast.warn("Unesite sve podatke za zonu!", toastSetup);
                        return;
                      }
                      if (!zones[selectedZone].on_sale) {
                        toast.success(
                          "Uspješno dodana zona u online prodaju",
                          toastSetup("top-center", 2000)
                        );
                      } else {
                        toast.success(
                          "Uspješno uklonjena zona iz online prodaje",
                          toastSetup("top-center", 2000)
                        );
                      }
                      updateZone("on_sale", e.target.checked);
                    }}
                  />
                  <label htmlFor={`switch-${zones[selectedZone]}`}></label>
                </div>
              </div>

              <div className="modal-input">
                <div className="modal-label">Tip ulaznice</div>
                <input
                  value={zones[selectedZone]?.name}
                  className="table-input"
                  type="text"
                  onChange={(e) => {
                    updateZone("name", e.target.value);
                  }}
                />
              </div>
              <div className="modal-input">
                <div className="modal-label">Cijena zone</div>
                <input
                  value={zones[selectedZone]?.price}
                  className="table-input"
                  type="number"
                  onChange={(e) => {
                    updateZone("price", parseInt(e.target.value));
                  }}
                />
                <small>BAM</small>
              </div>
              <div className="modal-input">
                <div className="modal-label">Bez sjedala</div>
                <div className="switch">
                  <input
                    className="table-input switch-input"
                    type="checkbox"
                    id={`switch-with-seats-${zones[selectedZone]}`}
                    checked={!Object.keys(zones[selectedZone]?.rows || {}).length > 0}
                    onChange={() => toggleRowsProperty()}
                  />
                  <label htmlFor={`switch-with-seats-${zones[selectedZone]}`}></label>
                </div>
              </div>
              {zones[selectedZone]?.rows ? (
                <div className="modal-input">
                  <div className="modal-label">
                    {Object.values(zones[selectedZone]?.rows || {}).some(
                      (row) => row.seats.length > 0
                    )
                      ? "Ukloni sve ulaznice"
                      : "Dodaj sve ulaznice"}
                  </div>
                  <div className="switch">
                    <input
                      className="table-input switch-input"
                      type="checkbox"
                      id={`switch-select-all-${zones[selectedZone]}`}
                      checked={zones[selectedZone]?.rows}
                      onChange={() => toggleAllRows()}
                    />
                    <label htmlFor={`switch-select-all-${zones[selectedZone]}`}></label>
                  </div>
                </div>
              ) : (
                <div className="modal-input">
                  <div className="modal-label">Broj ulaznica za prodaju</div>
                  <input
                    value={zones[selectedZone]?.amount}
                    className="price-input ticket-type table-input"
                    type="number"
                    onChange={(e) => {
                      updateZone("amount", e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {zones[selectedZone]?.rows && (
            <div className="scroll-wrapper">
              <SeatDisplayOrganize
                eventData={eventData}
                setEventData={setEventData}
                selectedZone={selectedZone}
              />
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          handleBlurClick();
        }}
        className="blur"
      ></div>
    </>
  );
};

export default ModalWindow;
