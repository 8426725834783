const ConcertComponent = ({ concertData }) => {
  if (!concertData) return null;

  const { tickets } = concertData;
  const { free_sale } = tickets;
  const { total_amount_left, sold_amount, total_loaned, zones } = free_sale;

  const total = total_amount_left + sold_amount;
  const available = total - total_loaned;

  return (
    <div className="bottom-part">
      <h6>Ulaznice za slobodnu prodaju</h6>
      <p>Ukupno: {total}</p>
      <p>Zaduženo: {total_loaned}</p>
      <p>Dostupno: {available}</p>
      <div className="line"></div>
      {zones &&
        Object.entries(zones).map(([categoryKey, category]) => (
          <div key={categoryKey} className="category">
            <p>
              {categoryKey} {categoryKey !== "" && category.name !== "" ? "-" : ""} {category.name}
            </p>
            <p>
              Zaduženo: {category.loaned ?? 0} / {category.max_amount}
            </p>
          </div>
        ))}
    </div>
  );
};

export default ConcertComponent;
