import React from "react";
import { hrTimeFormat } from "../../../components/helper/timeFormat";

export const EventDetails = ({ concertData }) => {
  return (
    <div className="concert-container">
      {concertData && (
        <div className="top-part">
          <div className="info">
            <h4>{concertData.performer_name}</h4>
            <span style={{ textTransform: "capitalize" }}>
              {new Date(concertData.time_of_event).toLocaleDateString("hr-HR", hrTimeFormat)} -{" "}
              {concertData.place.place}, {concertData.place.city}, {concertData.place.country}
            </span>
          </div>
          <img
            className="info-buy-page-image"
            src={
              concertData?.poster?.landscape
                ? `${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`
                : ""
            }
            alt="concertData.poster.landscape"
          />
        </div>
      )}
    </div>
  );
};
