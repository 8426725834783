import { useSelector } from "react-redux";
import OrderInfo from "./OrderInfo";

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const queryParams = {};
  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }
  return queryParams;
};

export const OrderConfirmation = () => {
  const language = useSelector((state) => state.language);
  const queryParams = getQueryParams();
  const orderNumber = queryParams.order_number;

  // State to hold the calculated digest and verification status

  return (
    <div style={{ textAlign: "center", marginTop: "30px" }}>
      {orderNumber ? (
        <OrderInfo orderNumber={orderNumber} successURL={window.location.href} />
      ) : (
        <p>{language === "en" ? "Order number not found" : "Broj narudžbe nije pronađen"}</p>
      )}
    </div>
  );
};
