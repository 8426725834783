import { hrTimeFormat } from "../../../../components/helper/timeFormat";

import { Clock, MapPinHouse } from "lucide-react";

const EventInfo = ({ concertData }) => {
  return (
    <div className="event-info-container">
      <img
        src={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`}
        alt="Landscape poster of event"
      />
      <div className="image-overlay">
        <div className="text-with-icon event-name-wrapper">
          <span className="event-name">{concertData?.performer_name}</span>
        </div>
        <div className="text-with-icon">
          <Clock />
          <span className="card-main-info">
            {new Date(concertData?.time_of_event).toLocaleString("hr-HR", hrTimeFormat)}
          </span>
        </div>
        <div className="text-with-icon">
          <MapPinHouse />
          <span className="card-main-info">
            {concertData?.place?.city}, {concertData?.place?.place}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EventInfo;
