const { createSlice } = require("@reduxjs/toolkit");

const ticketSlicePrint = createSlice({
  name: "tickets",
  initialState: {
    tickets: [
      {
        id: 1,
        name: "",
        lname: "",
        email: "",
        price: 0,
        category: "",
        rows: "",
        seat: "",
        color: "",
      },
    ],
    totalAmount: 0,
  },
  reducers: {
    addTicket(state, action) {
      state.tickets.push({
        id: state.tickets.length + 1,
        name: "",
        lname: "",
        email: state.tickets[state.tickets.length - 1].email,
        price: state.tickets[state.tickets.length - 1].price,
        category: state.tickets[state.tickets.length - 1].category,
        ticketName: state.tickets[state.tickets.length - 1].ticketName,
        row: state.tickets[state.tickets.length - 1].row,
        seat: "",
        color: state.tickets[state.tickets.length - 1].color,
      });

      state.totalAmount += state.tickets[state.tickets.length - 1].price;
    },
    selectTicket(state, action) {
      const { id, name, email, lname, price, category, seat, row, color } = action.payload;

      const existingTicketIndex = state.tickets.findIndex((ticket) => ticket?.id === id);

      if (existingTicketIndex !== -1) {
        state.tickets[existingTicketIndex] = {
          id,
          name,
          lname,
          email,
          price,
          category,
          seat,
          row,
          color,
        };
      } else {
        state.tickets.push({ id, name, lname, email, price, category, seat, row, color });
      }

      state.totalAmount = state.tickets.reduce((sum, ticket) => sum + (ticket?.price || 0), 0);
    },
    selectTicket(state, action) {
      const { ticketPrice, ticketID, category, seat, row, ticketName, color } = action.payload;

      const ticketIndex = state.tickets.findIndex((ticket) => ticket?.id === ticketID);

      if (ticketIndex !== -1) {
        state.tickets[ticketIndex] = {
          ...state.tickets[ticketIndex],
          price: ticketPrice,
          category,
          ticketName,
          seat,
          row,
          color,
        };

        state.totalAmount = state.tickets.reduce((sum, ticket) => sum + (ticket?.price || 0), 0);
      }
    },
    removeTicket(state, action) {
      const deletingId = action.payload;

      if (state.tickets.length > 1) {
        state.tickets = state.tickets.filter((ticket) => ticket?.id !== deletingId);

        state.tickets.forEach((ticket, index) => {
          ticket.id = index + 1;
        });
      }

      state.totalAmount = state.tickets.reduce((sum, ticket) => sum + (ticket?.price || 0), 0);
    },
    updateTicket(state, action) {
      const { ticketID, property, value } = action.payload;

      const ticketIndex = state.tickets.findIndex((ticket) => ticket?.id === ticketID);

      if (ticketIndex !== -1) {
        state.tickets[ticketIndex] = {
          ...state.tickets[ticketIndex],
          [property]: value,
        };
      }
    },
    presetTicket(state, action) {
      const { email, category, ticketName, price, color, name, lname } = action.payload;

      state.tickets = [
        {
          id: 1,
          name,
          lname,
          email,
          ticketName,
          price,
          category,
          seat: "",
          row: "",
          color,
        },
      ];
      state.totalAmount = price;
    },
    resetState(state, action) {
      const email = action.payload;

      state.tickets = [
        {
          id: 1,
          name: "",
          lname: "",
          email,
          price: 0,
          category: "",
          seat: "",
          row: "",
        },
      ];
      state.totalAmount = 0;
    },
    softResetState(state, action) {
      state.tickets = state.tickets.map((ticket) => ({
        ...ticket,
        price: 0,
        category: "",
        seat: "",
        row: "",
      }));
      state.totalAmount = 0;
    },
  },
});

export const {
  addTicket,
  removeTicket,
  selectTicket,
  resetState,
  softResetState,
  updateTicket,
  presetTicket,
} = ticketSlicePrint.actions;

export const ticketStateExperimental = ticketSlicePrint.reducer;
