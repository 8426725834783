import React from "react";
import { InputField } from "../organize-event-page/Inputs";

export const UpdateProfileInput = ({ placeholder, id, inputValue, setValue, type = "text" }) => {
  return (
    <InputField
      label={placeholder}
      placeholder={placeholder}
      type={type}
      grow={true}
      id={id}
      value={inputValue || ""}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
