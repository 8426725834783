import React, { useEffect, useState } from "react";
import { TicketGen } from "./free-sale-manager/TicketGen";
import OnlineTicketManager from "./online-sale-manager/OnlineTicketManager";
import { OnlineTicketManagerMapped } from "./online-sale-manager/OnlineTicketManagerMapped";
import axios from "axios";
import TicketTypeSwitcher from "./TicketTypeSwitcher";
import { EventDetails } from "./EventsDetails";

import { RefreshCw } from "lucide-react";

export const EventDetailsTicket = ({ concertData, setConcertData, adminEmail, adminName }) => {
  const [showPrint, setShowPrint] = useState(true);
  const [zones, setZones] = useState(concertData?.tickets.online_sale.zones);
  const [onlineSaleZones, setOnlineSaleZones] = useState(concertData?.tickets.online_sale.zones);

  useEffect(() => {
    fetchData(); // Call the async function immediately
  }, [showPrint, concertData._id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${concertData._id}`
      );
      setConcertData(response.data[0]);
      setZones(response.data[0].tickets.online_sale.zones);
      setOnlineSaleZones(response.data[0].tickets.online_sale.zones);
    } catch (error) {
      console.error("Error fetching concert data:", error);
    }
  };

  return (
    <div className="concert-container">
      {concertData && (
        <>
          <EventDetails concertData={concertData} />
          <div className="refresh-button" onClick={() => fetchData()}>
            <RefreshCw size={20} />
            <span>Osvježi</span>
          </div>
          <div className="center-btn">
            <TicketTypeSwitcher
              ticketState={showPrint}
              setTicketState={setShowPrint}
              statesArray={["Online prodaja", "Print ulaznice"]}
            />
          </div>
          {showPrint ? (
            <>
              <h6>Izradi ulaznice</h6>
              <TicketGen
                key={concertData._id}
                setConcertData={setConcertData}
                concertData={concertData}
                adminEmail={adminEmail}
                adminName={adminName}
              />
            </>
          ) : (
            <>
              <h6>Upravljaj online ulaznicama</h6>
              {concertData.place.type === "hall" ? (
                <OnlineTicketManager
                  onlineSale={concertData.tickets.online_sale}
                  id={concertData._id}
                />
              ) : (
                concertData.place.ground_plan && (
                  <OnlineTicketManagerMapped
                    id={concertData._id}
                    onlineSaleZones={onlineSaleZones}
                    setOnlineSaleZones={setOnlineSaleZones}
                    groundPlanName={concertData.place.ground_plan}
                    zones={zones}
                    setZones={setZones}
                  />
                )
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
