import { Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";

import { ChevronsRight, ChevronsLeft, CirclePlus } from "lucide-react";

import "swiper/scss";
import "swiper/scss/pagination";
import { useState } from "react";

const TicketSwiper = ({ children, onSwiper, swiperRef, onAddTicket, totalTickets }) => {
  const [isBegEnd, setIsBegEnd] = useState([true, true]);

  const pagination = {
    clickable: true,
    renderBullet: (index, className) => `<span class="${className}">${index + 1}</span>`,
  };
  const showAddButton = totalTickets - swiperRef?.current?.activeIndex === 1 || isBegEnd[1];

  return (
    <div className="ticket-swiper-wrapper">
      <Swiper
        direction="horizontal"
        pagination={pagination}
        modules={[Pagination]}
        watchOverflow={false}
        onSlideChange={(swiper) => setIsBegEnd([swiper.isBeginning, swiper.isEnd])}
        onSwiper={(swiper) => {
          onSwiper(swiper);
          swiper.on("touchEnd", () => {
            if (swiper.isEnd && swiper.touches.currentX - swiper.touches.startX < -100) {
              onAddTicket();
            }
          });
        }}
      >
        {children}
        {/* CUSTOM NAVIGATION BUTTONS */}
        <div className="swiper-button-prev">
          {isBegEnd[0] ? (
            ""
          ) : (
            <ChevronsLeft className="pointer-hover" onClick={() => swiperRef.current.slidePrev()} />
          )}
        </div>
        <div className="swiper-button-next">
          {showAddButton ? (
            <div className="vertical-button pointer-hover" onClick={() => onAddTicket()}>
              <CirclePlus />
              <span>Dodaj</span>
            </div>
          ) : (
            <ChevronsRight
              className="pointer-hover"
              onClick={() => swiperRef.current.slideNext()}
            />
          )}
        </div>
        {/* CUSTOM NAVIGATION BUTTONS - END*/}
      </Swiper>
    </div>
  );
};

export default TicketSwiper;
