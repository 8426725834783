import { useDispatch } from "react-redux";

import { Trash2, Armchair, Tag, Rows3, ContactRound } from "lucide-react";
import Badge from "./Badge";

const TicketBill = ({ translate, ticket, handleSlideChange, handleRemoveTicket }) => {
  const { id: ticketID, ticketName, category, row, seat, price, color, name, lname } = ticket;

  const formattedPrice = price
    ? translate
      ? (price / 1.923).toFixed(2)
      : price.toFixed(2)
    : "0.00";

  const dispatch = useDispatch();

  const bottomShadow = {
    boxShadow: `0px 10px 14px -14px ${color}`,
    borderBottom: `solid 1px ${color}80`,
  };
  return (
    <>
      <div className="ticket-bill-row" style={bottomShadow}>
        <div className="ticket-bill-left" onClick={() => handleSlideChange(ticketID - 1)}>
          <div className="flex-container">
            <Tag style={{ color: color }} size={20} />
            <span className="main-text">{category}</span>
            <Badge color={color}>{ticketName}</Badge>
          </div>
          {row ||
            seat ||
            (name && lname && (
              <div className="flex-container">
                {row && (
                  <span className="secondary-text text-with-icon">
                    <Rows3 size={16} />
                    {translate ? "Row" : "Red"} <span className="sub-main-text">{row}</span>
                  </span>
                )}
                {seat && (
                  <span className="secondary-text text-with-icon">
                    <Armchair size={16} />
                    {translate ? "Seat" : "Sjedalo"} <span className="sub-main-text">{seat}</span>
                  </span>
                )}
                {name && lname && (
                  <div className="secondary-text text-with-icon">
                    <ContactRound size={16} />
                    <span>
                      {name} {lname}
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className="ticket-bill-right">
          <span className="price main-text">
            {formattedPrice} <span className="currency-styles">{translate ? "€" : "BAM"}</span>
          </span>
          <Trash2 className="remove-ticket-btn" onClick={() => handleRemoveTicket(ticketID)} />
        </div>
      </div>
    </>
  );
};

export default TicketBill;
