import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import CategoryCard from "./CategoryCard";
import TotalCard from "./TotalCard";
import { RefreshCw } from "lucide-react";

const ScansPerCategory = ({ concertId }) => {
  const token = useSelector((state) => state.userState.token);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getScansPerCategory();
  }, [concertId, token]);

  const getScansPerCategory = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/scans-per-category/tickets_for_${concertId}`,
        {
          token,
        }
      );
      setData(response.data);
    } catch (error) {
      setError("Error fetching concert data");
      console.error("Error fetching concert data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {data && (
        <div style={{ position: "relative" }}>
          <TotalCard total={data.totalTickets} isValid={data.totalValidTickets} />
          <button
            style={{ position: "absolute", right: "0.5rem", top: "0.5rem" }}
            className="profile-btn"
            onClick={getScansPerCategory}
            disabled={loading}
          >
            <RefreshCw />
          </button>
          <div className="entrance-category-container">
            {Object.entries(data.countedCategories).map(([category, { total, isValid }]) => (
              <CategoryCard category={category} total={total} isValid={isValid} key={category} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScansPerCategory;
