// TicketModal component
import React, { useState, useEffect } from "react";
import axios from "axios";
import { RefreshCw, Minimize2 } from "lucide-react";

const TicketModal = ({ ticketId, onClose, concertId }) => {
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getTicketData();
  }, [ticketId, concertId]);

  const getTicketData = async () => {
    setLoading(true);
    setError(null); // Reset error state before the fetch
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/scans/tickets_for_${concertId}/${ticketId}`
      );
      setTicketData(response.data.ticket);
    } catch (error) {
      setError(`Error fetching ticket data, ${ticketId}`);
      console.error("Error fetching ticket data:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handleOutsideClick = (event) => {
    // Close modal if clicking outside the modal content
    if (event.target.classList.contains("modal")) {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleOutsideClick}>
      <div className="modal-content">
        <div className="button-wrapper">
          {!ticketData && (
            <button className="profile-btn" onClick={getTicketData}>
              <RefreshCw />
            </button>
          )}
          <button className="profile-btn" onClick={onClose}>
            <Minimize2 />
          </button>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {ticketData && (
          <ul>
            {Object.entries(ticketData).map(([key, value]) => {
              // Only render if value is not null, undefined, or an empty string
              if (value) {
                return (
                  <li key={key}>
                    <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>{" "}
                    {value.toString()}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TicketModal;
