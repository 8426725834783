import { useDispatch } from "react-redux";
import HallTickets from "./HallTickets";
import { updateTicket } from "../../../../store/ticket-slice";
import { InputField } from "../../organize-event-page/Inputs";

import { Tag } from "lucide-react";

const Personalization = ({
  ticket,
  onlineSaleZones,
  credentialsRequired,
  placeType,
  translate,
  userEmail,
  userFullName,
}) => {
  const dispatch = useDispatch();
  const { id: ticketID, category, name, lname, email } = ticket;

  const handleUpdateTicket = (property, value) => {
    dispatch(
      updateTicket({
        ticketID,
        property,
        value,
      })
    );
  };

  return (
    <div className="personalization">
      <div className="ticket-heading">
        <div className="flex-col">
          <span className="ticket-title text-with-icon">
            <Tag size={22} />
            {translate ? "Ticket" : "Ulaznica"} {ticketID}
          </span>
          {credentialsRequired && (
            <span className="info-text">
              Organizator zahtjeva osobne podatke na ulaznici. Kupovinom ovih ulaznica pristajete na
              prikaz istih na ulaznici.
            </span>
          )}
        </div>
        {credentialsRequired && (
          <div className="credentials">
            <InputField
              className="buy-input"
              placeholder={translate ? "Name" : "Ime"}
              type="text"
              value={name}
              onChange={(e) => handleUpdateTicket("name", e.target.value)}
            />
            <InputField
              className="buy-input"
              placeholder={translate ? "Last name" : "Prezime"}
              type="text"
              value={lname}
              onChange={(e) => handleUpdateTicket("lname", e.target.value)}
            />
            <InputField
              className="buy-input"
              placeholder={translate ? "Email" : "Email"}
              type="email"
              value={email}
              onChange={(e) => handleUpdateTicket("email", e.target.value)}
            />
          </div>
        )}
      </div>
      {placeType === "hall" && (
        <HallTickets
          ticketID={ticketID}
          selectedZone={category}
          userEmail={userEmail}
          userFullName={userFullName}
          onlineSaleZones={onlineSaleZones}
        />
      )}
    </div>
  );
};

export default Personalization;
