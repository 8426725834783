import { ShieldCheck, ShieldX, PrinterCheck, CircleSlash } from "lucide-react";
import Button from "../../../components/Button";

const StatusDisplay = ({ reseller, handleClickPrintTickets }) => {
  return (
    <span className="reseller-status-wrapper">
      <span>
        <small>Status: </small>
        {reseller.active ? (
          <span className="status-active">
            <ShieldCheck size={18} />
            Aktivan
          </span>
        ) : (
          <span className="status-inactive">
            <ShieldX size={18} />
            Neaktivan
          </span>
        )}
      </span>
      <span>
        {reseller.can_print_tickets ? (
          <Button
            className="print-status-active"
            disabled={!reseller.active || !reseller.can_print_tickets}
            loader={false}
            type="button"
            onClick={() => handleClickPrintTickets()}
          >
            <PrinterCheck size={18} />
            <span>Izrada ulaznica</span>
          </Button>
        ) : (
          <span className="print-status-inactive">
            <small>Izrada ulaznica: </small>
            <CircleSlash size={18} />
            <span>Neaktivno</span>
          </span>
        )}
      </span>
    </span>
  );
};

export default StatusDisplay;
