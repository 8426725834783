const Badge = ({ color, children }) => {
  const styleBadge = {
    color: color,
    backgroundColor: `${color}10`,
    outline: `solid 1px ${color}`,
  };
  return (
    <div className="ticket-badge" style={styleBadge}>
      {children}
    </div>
  );
};

export default Badge;
