import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import { toastSetup } from "../../../../functions/toastSetup";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { PrintMappedTickets } from "./PrintMappedTickets";
import SetTemplate from "../SetTemplate";
import TestTemplate from "../TestTemplate";
import TicketTypeSwitcher from "../TicketTypeSwitcher";
import OwnersModal from "./OwnersModal";

import { TicketPlus, Trash2 } from "lucide-react";
import Button from "../../../../components/Button";

export const TicketGen = ({ concertData, setConcertData, adminEmail, adminName }) => {
  const [isPDF, setIsPDF] = useState(false);
  const [rowNum, setRowNum] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState(false);
  const firstInvalidInputRef = useRef(null);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [template, setTemplate] = useState(concertData?.freeSaleTemplate || "");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    try {
      const categoryNames = tickets.map((ticket) => ticket.categoryName.trim());
      const hasDuplicateNames = new Set(categoryNames).size !== categoryNames.length;

      if (hasDuplicateNames) {
        toast.warn(
          "Molimo unesite jedinstvene nazive zona ulaznica.",
          toastSetup("top-right", 3000)
        );
        return;
      }

      const hasIncompleteData = tickets.some(
        (ticket) => !ticket.categoryName || !ticket.ticketPrice || !ticket.ticketType
      );

      if (hasIncompleteData) {
        const invalidFields = tickets
          .map((ticket, index) => ({
            index,
            fields: ["categoryName", "ticketPrice", "ticketType"].filter((field) => !ticket[field]),
          }))
          .filter((ticket) => ticket.fields.length > 0);

        toast.warn(
          "Molimo unesite sve potrebne podatke za zone ulaznica.",
          toastSetup("top-right", 3000)
        );

        if (invalidFields.length > 0) {
          setInvalidInputs(invalidFields);
          return;
        }

        if (!firstInvalidInputRef.current) {
          return;
        }

        const firstInvalidInputName = firstInvalidInputRef.current.name;
        if (["categoryName", "ticketPrice", "ticketType"].includes(firstInvalidInputName)) {
          firstInvalidInputRef.current.focus();
        }
        return;
      }

      const totalTicketCount = tickets.reduce(
        (total, ticket) => total + parseInt(ticket.ticketsNum || 0, 10),
        0
      );

      if (totalTicketCount === 0 && concertData.place.type === "hall") {
        toast.warn(
          "Nema ulaznica za ispis. Unesite broj ulaznica za neku od zona.",
          toastSetup("top-right", 3000)
        );
        return;
      }

      const validTickets = tickets.filter(
        (ticket) => ticket.ticketsNum === "" || ticket.ticketsNum !== 0
      );

      validTickets.forEach((ticket) => {
        if (ticket.rows) {
          Object.keys(ticket.rows).forEach((rowKey) => {
            if (ticket.rows[rowKey].seats.length === 0) {
              delete ticket.rows[rowKey];
            }
          });
        }
      });

      if (validTickets.length === 0) {
        toast.warn(
          "Nema ulaznica za ispis. Unesite broj ulaznica za neku od zona.",
          toastSetup("top-right", 3000)
        );
        return;
      }

      toast.success(
        `Ulaznice su tijeku izrade. Po završetku ih preuzmite putem poveznice koja će biti poslana na ${adminEmail}`,
        toastSetup("top-right", 5000)
      );

      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/freeSale/generate-tickets`, {
        ticketGenData: validTickets,
        concertData,
        adminEmail,
        adminName,
        template,
        isPDF,
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${concertData._id}`
      );
      setConcertData(response.data[0]);

      toast.success("Generiranje ulaznica uspješno!", toastSetup("top-right", 3000));
    } catch (error) {
      toast.error(
        `Problem s generiranjem ulaznica. Greška ${error?.response?.data?.error}`,
        toastSetup("top-right", 3000)
      );
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (concertData.tickets.free_sale.zones && concertData.place.type === "hall") {
      const initialRows = Object.keys(concertData.tickets.free_sale.zones).map((categoryName) => ({
        categoryName,
        ticketType: concertData.tickets.free_sale.zones[categoryName].name,
        ticketsNum: "",
        ticketPrice: concertData.tickets.free_sale.zones[categoryName].price.toString(),
        ticketsAlready: concertData.tickets.free_sale.zones[categoryName].max_amount,
      }));
      setTickets(initialRows);
      setRowNum(initialRows.length);
    } else {
      setRowNum(0);
      setTickets([]);
    }
  }, [concertData]);

  const handleAddZone = () => {
    setRowNum((prevRowNum) => prevRowNum + 1);
    // Add a new ticket object to the tickets state when adding a row
    setTickets([...tickets, { categoryName: "", ticketType: "", ticketsNum: "", ticketPrice: "" }]);
  };

  const handleRemoveZone = (index) => {
    const updatedTickets = [...tickets];
    updatedTickets.splice(index, 1);
    setTickets(updatedTickets);
    setRowNum((prevRowNum) => prevRowNum - 1);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedTickets = [...tickets];
    updatedTickets[index] = {
      ...updatedTickets[index],
      [name]: value,
    };
    setTickets(updatedTickets);

    // Remove the invalid input from invalidInputs
    setInvalidInputs((prevInvalidInputs) =>
      prevInvalidInputs.filter((input) => input.index !== index)
    );
  };
  useEffect(() => {
    // Recalculate total number of tickets, total amount, and provision
    let totalTickets = 0;
    let totalAmount = 0;
    tickets.forEach((ticket) => {
      const ticketsNum = parseInt(ticket.ticketsNum, 10);
      const ticketPrice = parseFloat(ticket.ticketPrice);

      if (!isNaN(ticketsNum) && !isNaN(ticketPrice)) {
        totalTickets += ticketsNum;
        totalAmount += ticketsNum * ticketPrice;
      }
    });
    setTotalTickets(totalTickets);
    setTotalAmount(totalAmount.toFixed(2)); // Rounding to 2 decimal places
  }, [tickets]);
  return (
    <div className="generator-container">
      <div className="template-container">
        <TicketTypeSwitcher
          ticketState={isPDF}
          setTicketState={setIsPDF}
          statesArray={["Print ulaznica", "PDF ulaznica"]}
          setTemplate={setTemplate}
        />
        <SetTemplate isPDF={isPDF} template={template} setTemplate={setTemplate} />
        <TestTemplate template={template} concertId={concertData._id} />
      </div>

      <form onSubmit={handleFormSubmit}>
        <div className="tickets-categories-container container-fluid">
          {concertData && concertData.place.type !== "hall" ? (
            <PrintMappedTickets
              concertId={concertData._id}
              groundPlanName={concertData.place.ground_plan}
              tickets={tickets}
              setTickets={setTickets}
              freeSaleZones={concertData.tickets.free_sale.zones}
              onlineSale={concertData.tickets.online_sale.zones}
            />
          ) : (
            Array.from({ length: rowNum }).map((_, i) => {
              const isDeletableRow =
                i >= Object.keys(concertData.tickets.free_sale.zones || []).length;
              const categoryNameRef = i === 0 ? firstInvalidInputRef : null;
              const ticketPriceRef = i === 0 ? null : firstInvalidInputRef;

              return (
                <div key={i} className="row">
                  <div className="col-lg-6">
                    <input
                      className={`event-input category-name ${
                        invalidInputs.some(
                          (input) => input.index === i && input.fields.includes("categoryName")
                        )
                          ? "invalid-input"
                          : ""
                      }`}
                      name="categoryName"
                      value={tickets[i]?.categoryName || ""}
                      placeholder="Zona ulaznice"
                      type="text"
                      onChange={(e) => handleInputChange(e, i, "categoryName")}
                      onInput={(e) => {
                        e.target.style = "outline: none;";
                      }}
                      disabled={!isDeletableRow}
                      ref={categoryNameRef}
                    />
                    <input
                      className={`event-input ticket-type ${
                        invalidInputs.some(
                          (input) => input.index === i && input.fields.includes("ticketType")
                        )
                          ? "invalid-input"
                          : ""
                      }`}
                      name="ticketType"
                      value={tickets[i]?.ticketType || ""}
                      placeholder="Tip ulaznice"
                      type="text"
                      onChange={(e) => handleInputChange(e, i, "ticketType")}
                      onInput={(e) => {
                        e.target.style = "outline: none;";
                      }}
                      disabled={!isDeletableRow}
                    />
                  </div>
                  <div className="col-lg-6 add-tickets-right-col">
                    <div className="tickets-num-owner-input">
                      <input
                        className="event-input tickets-num"
                        name="ticketsNum"
                        value={tickets[i]?.ticketsNum || ""}
                        placeholder={`Broj ulaznica. (${tickets[i]?.ticketsAlready || "0"})`}
                        type="text"
                        onChange={(e) => handleInputChange(e, i, "ticketsNum")}
                        onInput={(e) => {
                          e.target.style = "outline: none;";
                        }}
                      />
                      <span>Vlasnici</span>
                      <OwnersModal
                        owners={tickets[i]?.owners}
                        tickets={tickets}
                        setTickets={setTickets}
                        index={i}
                      />
                    </div>
                    <div className="price">
                      <input
                        className={`event-input ticket-price ${
                          invalidInputs.some(
                            (input) => input.index === i && input.fields.includes("ticketPrice")
                          )
                            ? "invalid-input"
                            : ""
                        }`}
                        name="ticketPrice"
                        value={tickets[i]?.ticketPrice || ""}
                        placeholder="Cijena ulaznice"
                        type="text"
                        onChange={(e) => handleInputChange(e, i, "ticketPrice")}
                        onInput={(e) => {
                          e.target.style = "outline: none;";
                        }}
                        disabled={!isDeletableRow}
                        ref={ticketPriceRef}
                      />
                      <small>BAM</small>

                      {isDeletableRow && (
                        <Trash2
                          className="remove-ticket"
                          alt="trash can"
                          onClick={() => handleRemoveZone(i)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {concertData && concertData.place.type === "hall" && (
          <TicketPlus className="add-category-icon" onClick={handleAddZone} alt="Add tickets" />
        )}
        <h6 style={{ marginLeft: "10px" }}>Ulaznice za printanje</h6>
        <div className="total-summ">
          <div>
            <p>Ukupan broj ulaznica: {totalTickets}</p>
            <small>Ukupan iznos: {totalAmount} BAM</small>
          </div>
        </div>
        {concertData.place.type !== "hall" && (
          <>
            <div className="legend">
              <div className="online">Online prodaja</div>
              <div className="free">Slobodna prodaja</div>
              <div className="available">Dostupne zone</div>
              <div className="selected">Odabrane zone</div>
              <div className="mixed">Kombinirana prodaja</div>
            </div>
            <div className="total-per-zone-container">
              <table className="total-per-zone">
                <thead>
                  <tr>
                    <th>Zona</th>
                    <th>Tip</th>
                    <th>Broj ulaznica</th>
                    <th>Vlasnici</th>
                    <th>
                      Cijena (<small>BAM</small>)
                    </th>
                    <th>Izbriši</th>
                  </tr>
                </thead>
                <tbody>
                  {tickets.length === 0 && (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center", padding: "15px 0" }}>
                        Dodaj zone za printanje
                      </td>
                    </tr>
                  )}
                  {tickets.map((ticket, i) => (
                    <tr key={i} className="tabel-number">
                      <td>{ticket.categoryName}</td>
                      <td>
                        <input
                          className="table-input"
                          type="text"
                          value={ticket.ticketType}
                          onChange={(e) =>
                            setTickets((prevTickets) =>
                              prevTickets.map((ticket, index) =>
                                index === i ? { ...ticket, ticketType: e.target.value } : ticket
                              )
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="table-input"
                          type="number"
                          value={ticket.ticketsNum}
                          onChange={(e) =>
                            setTickets((prevTickets) =>
                              prevTickets.map((ticket, index) =>
                                index === i ? { ...ticket, ticketsNum: e.target.value } : ticket
                              )
                            )
                          }
                          disabled={!!ticket?.rows}
                        />
                      </td>
                      <td>
                        <OwnersModal
                          owners={ticket.owners}
                          tickets={tickets}
                          setTickets={setTickets}
                          index={i}
                        />
                      </td>
                      <td>
                        <input
                          className="table-input"
                          type="number"
                          value={ticket.ticketPrice}
                          onChange={(e) =>
                            setTickets((prevTickets) =>
                              prevTickets.map((ticket, index) =>
                                index === i ? { ...ticket, ticketPrice: e.target.value } : ticket
                              )
                            )
                          }
                        />
                      </td>
                      <td>
                        <Trash2
                          alt="trash can"
                          onClick={() =>
                            setTickets((prev) => prev.filter((_, index) => index !== i))
                          }
                          className="remove-ticket"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <div className="button-wrapper">
          <Button type="submit" className="add-tickets-btn center-btn w-10rem" disabled={loader}>
            {loader ? "Izrada u tijeku..." : "Izradi ulaznice"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TicketGen;
