import React, { useEffect, useRef, useState } from "react";
import ArrowIcon from "../../../assets/ikonice/arrow_icon.svg";

import TicketCategories from "./TicketCategories";
import AddResellerToEvent from "./AddResellerToEvent";

import { Users, MapPinHouse } from "lucide-react";
export const AddResellerCard = ({ userData, setConcertData, concertData }) => {
  const [dropdown, setDropdown] = useState(false);
  const [hasBorderRadius, setBorderRadius] = useState(true);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const dropdownRef = useRef(null);
  const [arrowDisabled, disableArrow] = useState(false);

  const freeSaleData = concertData?.tickets?.free_sale;
  const concertId = concertData?._id;

  useEffect(() => {
    // Get the height of the dropdown content
    setDropdownHeight(dropdown ? dropdownRef.current.scrollHeight : 0);

    const cleanup = () => {
      setBorderRadius(true);
    };

    if (!dropdown) {
      const timer = setTimeout(cleanup, 200);
      return () => clearTimeout(timer);
    } else {
      setBorderRadius(false);
    }
  }, [dropdown, freeSaleData]);

  // Toggle dropdown
  function toggleDropdown() {
    setDropdown(!dropdown);

    // Disable arrow on 0.4 sec so user cannot spam
    disableArrow(true);
    setTimeout(() => {
      disableArrow(false);
    }, 400);
  }
  return (
    <div
      style={{
        borderBottomLeftRadius: hasBorderRadius ? "7px" : "0",
        borderBottomRightRadius: hasBorderRadius ? "7px" : "0",
        marginBottom: dropdown ? dropdownHeight + 10 : "10px",
      }}
      className="add-reseller-card myevent-card"
    >
      <div className="resellers-top-part-wrapper">
        <div className="add-reseller-card-part-2">
          <span>
            <MapPinHouse size={20} /> {userData.reseller_info.sellingPlaceName}
          </span>
          <span>{userData.reseller_info.sellingPlaceAddress}</span>
        </div>
        <div className="add-reseller-card-part-2">
          <span>
            <Users size={20} /> {userData.full_name}
          </span>
          <span>{userData.reseller_info.sellingPlaceNumber}</span>
        </div>
        <AddResellerToEvent
          setConcertData={setConcertData}
          userData={userData}
          concertId={concertId}
          concertData={concertData}
        />
      </div>
      <div
        onClick={(e) => (!arrowDisabled ? toggleDropdown(e) : undefined)}
        className="myevent-card-part-3"
        style={{
          borderBottomRightRadius: hasBorderRadius ? "7px" : "0",
          backgroundColor: hasBorderRadius ? "rgba(69, 91, 217, 0.7)" : "rgba(69, 91, 217, 0.5)",
        }}
      >
        <span>Pregled kategorija</span>
        <img
          style={dropdown ? { rotate: "-180deg" } : { rotate: "0deg" }}
          className="dropdown-arrow"
          src={ArrowIcon}
          alt="Drop"
        />
      </div>
      <TicketCategories
        setConcertData={setConcertData}
        userData={userData}
        concertId={concertId}
        freeSaleData={freeSaleData}
        dropdown={dropdown}
        dropdownHeight={dropdownHeight}
        dropdownRef={dropdownRef}
      />
    </div>
  );
};
