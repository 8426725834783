import React from "react";
import { hrTimeFormatShort } from "../../../components/helper/timeFormatShort";
import { CheckCheck, X } from "lucide-react";

export const SellingInfo = ({ transaction, children }) => {
  const formattedDate = new Date(transaction?.date).toLocaleDateString("hr-HR", hrTimeFormatShort);

  if (!transaction) return;
  return (
    <div className="resellers-card-bottom-row">
      <div>{transaction.reseller}</div>
      <div>
        <small>{formattedDate}</small>
      </div>
      <div>{transaction.taker}</div>
      <div>
        {transaction.price}
        <small> BAM</small>
      </div>

      {transaction.is_verified ? <CheckCheck className="check-icon" /> : <X className="x-icon" />}
      {children}
    </div>
  );
};
