import React, { useEffect, useState, useCallback } from "react";
import { Mode } from "../../../draw-place/image-mapper/ImageMapper";
import OnlineImageMapper from "./OrganizeTicketImageMapper";
import ModalWindow from "./ModalWindow";
import TicketsTabel from "./TicketsTabel";

export const MappedOnlineManager = ({ eventData, setEventData }) => {
  const [modalWindow, setModalWindow] = useState(false);
  const [imageData, setImageData] = useState({ loaded: false, width: 0, height: 0 });

  const [selectedZone, setSelectedZone] = useState(null);

  const groundPlanUrl = `${process.env.REACT_APP_API_URL}/static/ground-plans/${eventData.place.ground_plan}`;

  // Preload Image Function
  const preloadImage = useCallback(async (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;
      img.src = url;
    });
  }, []);

  // Reload Image on URL Change
  useEffect(() => {
    let isMounted = true; // Prevent state update if component unmounts
    setImageData({ loaded: false, width: 0, height: 0 }); // Reset state before reloading
    const loadImage = async () => {
      try {
        const { width, height } = await preloadImage(groundPlanUrl);
        if (isMounted) {
          setImageData({ loaded: true, width, height });
        }
      } catch (error) {
        console.error("Failed to load image:", error);
      }
    };
    loadImage();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [groundPlanUrl, preloadImage]); // Trigger reload when URL changes

  // Zone Click Handler
  const handleZoneClick = (e, zoneData) => {
    const selectedZone = zoneData[0];
    const selectedZoneData = zoneData[1];

    if (!selectedZoneData?.name && !selectedZoneData?.amount && !selectedZoneData?.max_amount) {
      const updatedRows = selectedZoneData.rows
        ? Object.fromEntries(
            Object.entries(selectedZoneData.rows).map(([rowKey, rowValue]) => [
              rowKey,
              {
                ...rowValue,
                seats: Array.from({ length: rowValue?.total_seats || 0 }, (_, i) => i + 1),
              },
            ])
          )
        : null;

      const updatedAmount = updatedRows
        ? Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0)
        : 0;

      setEventData((prev) => ({
        ...prev,
        tickets: {
          ...prev.tickets,
          online_sale: {
            ...prev.tickets.online_sale,
            zones: {
              ...prev.tickets.online_sale.zones,
              [selectedZone]: {
                ...selectedZoneData,
                amount: updatedAmount,
                max_amount: updatedAmount,
                rows: updatedRows,
              },
            },
          },
        },
      }));
    }
    setModalWindow(true);
    setSelectedZone(selectedZone);
    document.querySelectorAll(".highlighted").forEach((el) => el.classList.remove("highlighted"));
    document.getElementById(selectedZone).classList.add("highlighted");
  };

  if (!imageData.loaded) {
    return <div>Loading ground plan...</div>;
  }

  return (
    <>
      {modalWindow && (
        <ModalWindow
          eventData={eventData}
          setEventData={setEventData}
          selectedZone={selectedZone}
          setModalWindow={setModalWindow}
        />
      )}
      <div className="organize-event-plan-wrapper">
        <div id="tooltip" display="none" style={{ position: "absolute", display: "none" }}></div>
        <OnlineImageMapper
          mode={Mode.SELECT}
          cb={(editor) => {
            editor.loadImage(groundPlanUrl);
            editor.polygon();
          }}
          options={{
            width: imageData.width,
            height: imageData.height,
          }}
          handleZoneClick={handleZoneClick}
          onlineSaleZones={eventData.tickets.online_sale.zones}
          zones={eventData.tickets.online_sale.zones}
        />
        {eventData.tickets.online_sale.zones && (
          <TicketsTabel
            zones={eventData.tickets.online_sale.zones}
            setEventData={setEventData}
            handleZoneClick={handleZoneClick}
          />
        )}
      </div>
    </>
  );
};
