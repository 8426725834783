export const InputField = ({
  label,
  value,
  onClick,
  onChange,
  type = "text",
  placeholder,
  className = "",
  grow = false,
  isError = false,
  errorMessage = "Greška",
  style,
  disabled = false,
  ...props
}) => {
  return (
    <div
      className={`input-field-custom-wrapper ${className}`}
      style={{
        ...style,
        flex: grow ? "1" : "",
        opacity: isError || disabled ? 0.5 : 1,
      }}
    >
      {label && <label className="input-label-custom">{label}</label>}
      <input
        className={`input-field-custom ${isError ? "input-field-error" : ""}`}
        type={type}
        spellCheck="false"
        value={value}
        onChange={onChange}
        disabled={disabled}
        onClick={onClick}
        placeholder={placeholder}
        {...props}
      />
      {isError && <div className="input-field-error-message">{errorMessage}</div>}
    </div>
  );
};

export const CustomSwitcher = ({
  label,
  checked,
  onChange,
  id,
  disabled = false,
  style = {},
  ...props
}) => (
  <div className="switcher-field-custom-wrapper" style={{ ...style, opacity: disabled ? 0.5 : 1 }}>
    {label && (
      <span className="input-label-custom" htmlFor={id}>
        {label}
      </span>
    )}
    <div className="switch">
      <input
        className="switch-input"
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <label htmlFor={id}></label>
    </div>
  </div>
);

export const CustomSelect = ({
  label,
  value,
  onChange,
  options = [],
  style,
  grow = false,
  className = "",
  ...props
}) => {
  return (
    <div className="select-field-custom-wrapper" style={{ ...style, flex: grow ? "1" : "" }}>
      {label && <span className="input-label-custom">{label}</span>}
      <select
        className={`select-custom ${className}`}
        value={value || ""}
        onChange={onChange}
        disabled={!options.length}
        style={style}
        {...props}
      >
        <option value="" disabled hidden>
          {options.length ? "Izaberi" : "Nema opcija"}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
