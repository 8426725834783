// React
import { useRef } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { addTicket, removeTicket } from "../../../store/ticket-slice";
// Libraries
import { SwiperSlide } from "swiper/react";
// Components
import Personalization from "./print-page-components/Personalization";
import TicketBill from "./print-page-components/TicketBill";
import TotalPrice from "./print-page-components/TotalPrice";
import TicketSwiper from "./print-page-components/Swiper";
import Navigation from "./print-page-components/Navigation";
import EventInfo from "./print-page-components/EventInfo";
import { checkTickets, getTicektsAvailable } from "./functions/checkTicketsFunctions";
import { toastSetup } from "../../../functions/toastSetup";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const PrintPage = ({ concertData, setConcertData, profileData }) => {
  const translate = useSelector((state) => state.language) === "en";
  const allTickets = useSelector((state) => state.ticketStateExperimental.tickets);
  const totalAmount = useSelector((state) => state.ticketStateExperimental.totalAmount);
  const totalTickets = allTickets.length;

  const { t } = useTranslation();

  const ticketsAvailable = getTicektsAvailable({
    profileData,
    concertId: concertData?._id,
    maxTicketsPerUser: concertData?.max_tickets_per_user,
  });

  const dispatch = useDispatch();

  const swiperRef = useRef(null);

  const handleAddTicket = () => {
    if (totalTickets >= ticketsAvailable) {
      toast.error(t("buy.ticket_available_error"));
      return;
    }
    // ADD TICKET
    dispatch(addTicket());
    setTimeout(() => {
      swiperRef.current?.slideTo(swiperRef.current.slides.length - 1);
    }, 50);
  };

  const handleSlideChange = (slideIndex) => {
    swiperRef.current?.slideTo(slideIndex);
  };

  const handleRemoveLastTicket = () => {
    if (totalTickets > 1) {
      swiperRef.current?.slideTo(totalTickets - 2);
      dispatch(removeTicket(allTickets[totalTickets - 1].id));
    }
  };

  const handleRemoveTicket = (ticketId) => {
    swiperRef.current?.slideTo(ticketId - 2);
    dispatch(removeTicket(ticketId));
  };
  const handleCheckTickets = () => {
    console.log();
  };

  return (
    <div>
      <div className="buy-page-window">
        <EventInfo concertData={concertData} />
        <Navigation
          translate={translate}
          totalTickets={totalTickets}
          handleAddTicket={handleAddTicket}
          handleRemoveLastTicket={handleRemoveLastTicket}
          handleCheckTickets={handleCheckTickets}
        />
        <TicketSwiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          swiperRef={swiperRef}
          onAddTicket={handleAddTicket}
          totalTickets={totalTickets}
        >
          {allTickets.map((ticket) => (
            <SwiperSlide key={ticket.id}>
              <Personalization
                ticket={ticket}
                userEmail={profileData?.email}
                userFullName={concertData?.credentials_required ? profileData?.full_name : ""}
                translate={translate}
                credentialsRequired={concertData?.credentials_required}
                onlineSaleZones={concertData?.tickets?.online_sale?.zones}
                placeType={concertData?.place?.type}
              />
            </SwiperSlide>
          ))}
        </TicketSwiper>
        <div className="ticket-bill">
          {allTickets.map((ticket, i) => (
            <TicketBill
              key={i}
              i={i}
              translate={translate}
              ticket={ticket}
              handleSlideChange={handleSlideChange}
              handleRemoveTicket={handleRemoveTicket}
            />
          ))}
        </div>
        <TotalPrice
          ticketAmount={allTickets.length}
          totalAmount={totalAmount}
          translate={translate}
        />
      </div>
    </div>
  );
};
