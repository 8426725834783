import axios from "axios";
import { useState } from "react";

import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";

import { ScanSearch } from "lucide-react";

const TestTemplate = ({ template, concertId }) => {
  const [loading, setLoading] = useState(false);

  const previewTemplate = async () => {
    setLoading(true);
    if (!template) {
      toast.warn("Izaberite template/šablon!");

      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/helper/preview/template`,
        {
          template,
          concertId,
        },
        {
          responseType: "blob", // Expecting a blob response
        }
      );

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Preview-template.pdf"); // Specify the file name for PDF

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Uspješno napravljen preview ulaznice", toastSetup("top-center", 5000));
    } catch (error) {
      if (error.response) {
        const blob = error.response.data;
        const reader = new FileReader();

        reader.onload = () => {
          toast.error("Greška: Prijavite problem na info@event.ba", toastSetup("top-right", 6000));
        };

        reader.readAsText(blob); // Read the blob as text to parse as JSON
      } else {
        // Handle other errors (network, etc.)
        toast.error("Greška u mreži: Pokušajte ponovo kasnije.", toastSetup("top-right", 6000));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <span className="preview-template" onClick={previewTemplate} disabled={loading}>
      <ScanSearch />
      <span>Prikaz ulaznice</span>
    </span>
  );
};
export default TestTemplate;
