import React, { useEffect, useRef, useState } from "react";
import ArrowIcon from "../../../assets/ikonice/arrow_icon.svg";
import { TicketManager } from "./TicketManager";
import { ApproveTicketSale } from "./ApproveTicketSale";
import { hrTimeFormatShort } from "../../../components/helper/timeFormat";
import StatusDisplay from "./StatusDisplay";
import PrintTickets from "./PrintTickets";

export const CheckTicketsCard = ({ eventData, reseller_id }) => {
  const [dropdown, setDropdown] = useState(false);
  const [arrowDisabled, disableArrow] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const dropdownRef = useRef(null);

  const [openPrintTickets, setOpenPrintTickets] = useState(false);

  const date = new Date(eventData.time_of_event).toLocaleDateString("hr-HR", hrTimeFormatShort);

  function toggleDropdown(e) {
    setDropdown(!dropdown);

    // Disable arrow on 0.4 sec so user cannot spam
    disableArrow(true);
    setTimeout(() => {
      disableArrow(false);
    }, 400);
  }

  useEffect(() => {
    // Get the height of the dropdown content
    setDropdownHeight(dropdown ? dropdownRef.current.scrollHeight : 0);
  }, [dropdown]);

  const calculateTotalLoaned = (categories) => {
    let totalLoaned = 0;
    let totalSold = 0;
    let soldMoney = 0;

    for (const category in categories) {
      totalLoaned += categories[category].loaned;
      totalSold += categories[category].sold;
      soldMoney += categories[category].sold * categories[category].price;
    }

    return { loaned: totalLoaned, sold: totalSold, soldMoney: soldMoney };
  };

  const handleClickPrintTickets = () => {
    setOpenPrintTickets(true);
  };

  return (
    <>
      {/* Ticket print UI */}
      {openPrintTickets && (
        <PrintTickets
          setOpenPrintTickets={setOpenPrintTickets}
          eventData={eventData}
          userId={reseller_id}
        />
      )}
      <div
        style={{
          borderBottomLeftRadius: dropdown ? "0" : "7px",
          borderBottomRightRadius: dropdown ? "0" : "7px",
          marginBottom: dropdown ? dropdownHeight + 10 : "10px",
        }}
        className="mytickets-card check-reseller-tickets"
      >
        <img
          style={{ borderBottomLeftRadius: dropdown ? "0" : "7px" }}
          src={`${process.env.REACT_APP_API_URL}/static/event-images/${eventData.poster.portrait}`}
          alt=""
        />
        <div className="mytickets-card-part resseller-info">
          <div>
            <h5>{eventData.performer_name}</h5>
            <p>
              {date} - {eventData.place.place}, {eventData.place.city}
            </p>
          </div>
          <span className="reseller-info-tickets-totals">
            <StatusDisplay
              reseller={eventData.reseller}
              handleClickPrintTickets={handleClickPrintTickets}
            />

            <span className="info">
              <p>
                <small>Prodano:</small> {calculateTotalLoaned(eventData.reseller.categories).sold}
              </p>
              <p>
                <small>Dostupno:</small>{" "}
                {calculateTotalLoaned(eventData.reseller.categories).loaned -
                  calculateTotalLoaned(eventData.reseller.categories).sold}
              </p>
              <p>
                <small>Ukupno:</small>{" "}
                {calculateTotalLoaned(eventData.reseller.categories).soldMoney} <small>BAM</small>
              </p>
              <p>
                <small>Preostalo:</small>{" "}
                {calculateTotalLoaned(eventData.reseller.categories).soldMoney -
                  eventData.reseller.transactions.reduce(
                    (sum, transaction) => sum + (transaction.price || 0),
                    0
                  )}{" "}
                <small>BAM</small>
              </p>
            </span>
          </span>
        </div>

        <div
          onClick={(e) => (!arrowDisabled ? toggleDropdown(e) : undefined)}
          style={{
            borderBottomRightRadius: dropdown ? "0" : "7px",
          }}
          className="dropdown-arrow-wrapper"
        >
          <img
            style={dropdown ? { rotate: "-180deg" } : { rotate: "0deg" }}
            src={ArrowIcon}
            alt="Drop"
            className="dropdown-arrow"
          />
        </div>
        <div
          style={{ maxHeight: dropdown ? dropdownHeight : 0 }}
          className="mytickets-card-dropdown"
          ref={dropdownRef}
        >
          <div className="profile-concert-wrapper">
            <div className="check-ticket-container">
              <div className="check-ticket-manager">
                {/* REWORK THIS */}
                {eventData.reseller?.zones &&
                  Object.entries(eventData.reseller.zones).map(([categoryName, categoryData]) => (
                    <TicketManager
                      key={categoryName}
                      type={categoryName}
                      price={categoryData.price}
                      totalAmount={categoryData.loaned}
                      totalSold={categoryData.sold}
                      concertId={eventData.concertId}
                      resellerId={reseller_id}
                      active={eventData.reseller.active}
                    />
                  ))}
              </div>
            </div>
            {eventData?.reseller?.transactions &&
              eventData?.reseller?.transactions.map((transaction, index) => (
                <ApproveTicketSale
                  key={index}
                  i={index}
                  transaction={transaction}
                  concertId={eventData.concertId}
                  resellerId={reseller_id}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
