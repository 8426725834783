import { toast } from "react-toastify";
import { toastSetup } from "../../../../functions/toastSetup";

const TicketsTabel = ({ zones, setEventData, handleZoneClick }) => {
  const setZones = (zone, property, value) => {
    setEventData((prev) => ({
      ...prev,
      tickets: {
        ...prev.tickets,
        online_sale: {
          ...prev.tickets.online_sale,
          zones: {
            ...prev.tickets.online_sale.zones,
            [zone]: {
              ...prev.tickets.online_sale.zones[zone],
              [property]: value,
            },
          },
        },
      },
    }));
  };
  return (
    <div className="total-per-zone-container">
      <table className="total-per-zone">
        <thead>
          <tr>
            <th>Zona</th>
            <th>Tip</th>
            <th>Ukupno</th>
            <th>Cijena [BAM]</th>
            <th>U prodaji</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(zones).map(([zone, zoneData]) => {
            const { amount, on_sale, price, name, rows } = zoneData;
            const amountVal = amount ?? 0;
            const noEditableAmount = !!rows;
            const cantSell = !on_sale && (!name || !amountVal || !price);
            if (!on_sale) {
              return null;
            }

            return (
              <tr key={zone}>
                <td onClick={() => handleZoneClick(null, [zone, zoneData])}>
                  <span className="open-zone-button">{zone}</span>
                </td>
                <td>
                  <input
                    className="table-input"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setZones(zone, "name", e.target.value);
                    }}
                  />
                </td>

                <td>
                  <input
                    className="table-input"
                    type="number"
                    value={amountVal}
                    onChange={(e) => {
                      const newAmount = Number(e.target.value);
                      setZones(zone, "amount", newAmount);
                      setZones(
                        zone,
                        "max_amount",
                        zoneData.max_amount - (zoneData.amount - newAmount)
                      );
                    }}
                    disabled={noEditableAmount}
                  />
                </td>

                <td>
                  <input
                    className="table-input"
                    type="number"
                    value={price}
                    onChange={(e) => {
                      const newPrice = Number(e.target.value.replace(/^0+/, ""));
                      setZones(zone, "price", newPrice);
                    }}
                  />
                </td>
                <td>
                  <div className="switch">
                    <input
                      className="table-input switch-input"
                      type="checkbox"
                      id={`switch-${zone}`}
                      checked={on_sale}
                      onChange={(e) => {
                        if (cantSell) {
                          toast.warn(
                            "Dodajte ulaznice, cijenu i tip za prodaju",
                            toastSetup("top-center", 5000)
                          );
                          e.preventDefault();
                          e.stopPropagation();
                          return;
                        }
                        setZones(zone, "on_sale", !on_sale);
                      }}
                      disabled={zone.includes("_")}
                    />
                    <label htmlFor={`switch-${zone}`}></label>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TicketsTabel;
