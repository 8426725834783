import { CircleMinus, CirclePlus, ShoppingCart, CreditCard } from "lucide-react";
const Navigation = ({
  translate,
  totalTickets,
  handleAddTicket,
  handleClickBuy,
  handleRemoveLastTicket,
  handleCheckTickets,
}) => {
  const cantRemoveLastTicket = totalTickets === 1;

  return (
    <div className="buy-navigation">
      <div
        className={`amount-button ${cantRemoveLastTicket ? "disabled-button" : ""}`}
        onClick={!cantRemoveLastTicket ? handleRemoveLastTicket : undefined}
      >
        <CircleMinus />
        <span>Oduzmi</span>
      </div>
      <span className="ticket-number">{totalTickets}</span>
      <div className="amount-button" onClick={handleAddTicket}>
        <CirclePlus />
        <span>Dodaj</span>
      </div>
      <div className="amount-button">
        <ShoppingCart />
        <span className="text-nobrake">Korpa</span>
      </div>
      <div className="amount-button" onClick={() => handleCheckTickets()}>
        <CreditCard />
        <span className="text-nobrake">Idi na plaćanje</span>
      </div>
    </div>
  );
};

export default Navigation;
