const TicketTypeSwitcher = ({
  ticketState,
  setTicketState,
  statesArray,
  setTemplate = () => {},
}) => {
  const handleClick = (newState) => {
    setTicketState(newState);
    setTemplate?.(null);
  };

  return (
    <div className="switcher">
      <span className={ticketState ? "" : "active"} onClick={() => handleClick(false)}>
        {statesArray[0]}
      </span>
      <span className={ticketState ? "active" : ""} onClick={() => handleClick(true)}>
        {statesArray[1]}
      </span>
    </div>
  );
};

export default TicketTypeSwitcher;
