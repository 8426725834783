import { useEffect, useState } from "react";
import axios from "axios";
import { CustomSelect } from "../organize-event-page/Inputs";

const SetTemplate = ({ template, setTemplate, isPDF }) => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/helper/templates`
        );

        if (response.data.templates) {
          setTemplates(response.data.templates);
        } else {
          setTemplates([]);
        }
      } catch (error) {
        console.error("Error fetching templates", error);
        setTemplates([]);
      }
    };

    fetchTemplates();
  }, []);

  const templateOptions =
    isPDF && templates["onlineSaleTemplates"]
      ? templates["onlineSaleTemplates"].map((templateName) => ({
          label: templateName.split(".")[0],
          value: templateName.split(".")[0],
        }))
      : templates["freeSaleTemplates"]
      ? templates["freeSaleTemplates"].map((templateName) => ({
          label: templateName.split(".")[0],
          value: templateName.split(".")[0],
        }))
      : [];
  return (
    <CustomSelect
      label="Template"
      value={template}
      options={[{ label: "Osnovni", value: "" }, ...templateOptions]}
      onChange={(e) => {
        if (e.target.value === "") {
          setTemplate(null);
        } else {
          setTemplate(e.target.value);
        }
      }}
    />
  );
};

export default SetTemplate;
