// CONSTANTS
const DEFAULT_MAX_TICKETS_PER_BUY = 5;

export const checkTickets = ({ concertData, allTickets }) => {
  const { credentials_required } = concertData;
  const requiredFields = credentials_required
    ? ["category", "mail", "name", "lname"]
    : ["category"];
  return allTickets.reduce((acc, ticket) => {
    const errors = requiredFields.filter((field) => !ticket[field]);

    if (errors.length > 0) {
      acc.push({ ticketId: ticket.id, errors });
    }

    return acc;
  }, []);
};

export const getTicektsAvailable = ({ profileData, concertId, maxTicketsPerUser }) => {
  const ticketsForThisEvent =
    profileData?.tickets_history
      ?.filter((entry) => entry.concert_id.toString() === concertId.toString())
      .reduce((acc, entry) => acc + entry.tickets.length, 0) ?? 0;
  const maxTicketsForThisEvent = maxTicketsPerUser ?? DEFAULT_MAX_TICKETS_PER_BUY;

  return profileData?.role === "admin"
    ? 200
    : Math.max(0, maxTicketsForThisEvent - ticketsForThisEvent);
};
