import React, { useEffect, useRef, useState } from "react";

import Carousel from "react-elastic-carousel";
import { Personalization } from "./Personalization";
import { TicketBill } from "./TicketBill";
import { removeLastTicket, resetState, softResetState } from "../../store/ticketSlice";
import { useDispatch, useSelector } from "react-redux";
import PaymentForm from "./PaymentForm";
import axios from "axios";
import { toast } from "react-toastify";
import { toastSetup } from "../../functions/toastSetup";
import { hrTimeFormat } from "../../components/helper/timeFormat";
import { setLoginIsOpen } from "../../store/loginSlice";
import { TheaterBuyPage } from "./TheaterBuyPage";
import AdminPayment from "./AdminPaymentForm";
import ScrollButtons from "../../components/scroll-helper/ScrollButtons";
import EventNotOnSale from "./EventNotOnSale";
import LoaderBuyPage from "./LoaderBuyPage";
import TicketRestricted from "./TicketRestricted";
import { setToken, setUserID } from "../../store/userSlice";
import { useParams } from "react-router-dom";

import { CircleMinus, CirclePlus } from "lucide-react";

const DEFAULT_MAX_TICKETS_PER_BUY = 4;

export const BuyPage = () => {
  const [concertData, setConcertData] = useState({});
  const [ticketAmount, setTicketAmount] = useState(1);
  const [agreed, setAgreed] = useState(false);
  const carouselRef = useRef(null);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [orderNumber, setOrderNumber] = useState(Math.floor(Math.random() * 1000000000000000) + 1);
  const [profileData, setProfileData] = useState({});
  const [theaterZones, setTheaterZones] = useState({});
  const [selectedZoneData, setSelectedZoneData] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [presaleBuyer, setPresaleBuyer] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const translate = useSelector((state) => state.language) === "en";
  const dispatch = useDispatch();
  const activeCardRef = useRef(null);
  const allTickets = useSelector((state) => state.ticketState.ticketList);

  const payRef = useRef(null);
  const addRef = useRef(null);

  const userId = useSelector((state) => state.userState.user);

  const [loadingButton, setLoadingButton] = useState(false);

  const logout = () => {
    dispatch(setUserID(""));
    dispatch(setToken(""));
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
  };

  useEffect(() => {
    toast.success(toastSetup("top-center", 3000));

    setOrderNumber(Math.floor(Math.random() * 1000000000000000) + 1);

    return () => {
      setOrderNumber(Math.floor(Math.random() * 1000000000000000) + 1);
    };
  }, []);

  useEffect(() => {
    setLoadingButton(false);
    setAgreed(false);
  }, [allTickets.length]);

  if (!userId) {
    dispatch(setLoginIsOpen(true));
  }
  useEffect(() => {
    dispatch(resetState(profileData.email));
    fetchConcert();

    if (userId !== "") {
      fetchProfileData();
    }
  }, [userId]);

  useEffect(() => {
    if (concertData?.credentials_required) {
      toast.warn(
        translate
          ? "The organizer requires a single owner for each ticket!"
          : "Organizator zahtjeva jedinstvenog vlasnika za svaku ulaznicu!",
        toastSetup("top-center", 5000)
      );
    }
  }, [concertData?.id]);

  const fetchConcert = async () => {
    const concert = await fetchConcertData();
    setConcertData(concert);
    setTheaterZones(concert.tickets.online_sale?.zones);
  };

  const totalAmount = useSelector((state) => state.ticketState.totalAmount);
  const ticketGenData = useSelector((state) => state.ticketState);

  // Manage max tickets to buy
  let ticketsAvailable = concertData?.max_tickets_per_user || DEFAULT_MAX_TICKETS_PER_BUY;
  if (concertData?.max_tickets_per_user && profileData?.tickets_history) {
    const ticketsForThisEventObj = profileData.tickets_history.find(
      (concert) => concert.concert_id === concertData._id
    );

    if (ticketsForThisEventObj) {
      const ticketsForThisEvent = ticketsForThisEventObj.tickets.length;
      const maxTicketsForThisEvent = concertData.max_tickets_per_user;
      ticketsAvailable = maxTicketsForThisEvent - ticketsForThisEvent;
    }
    if (profileData.role === "admin") ticketsAvailable = 200;
  }

  const addTicket = async (check = true) => {
    isChecked && setIsChecked(false);
    if (ticketAmount >= ticketsAvailable) {
      toast.warning(
        translate
          ? `Number of available tickets is: ${ticketsAvailable}.`
          : `Broj dostupnih ulaznica po korisniku je: ${
              concertData?.max_tickets_per_user || DEFAULT_MAX_TICKETS_PER_BUY
            }.`,
        toastSetup("top-center", 3000)
      );
      return;
    }
    if (check) {
      if (userId === "") {
        dispatch(setLoginIsOpen(true));
        return;
      }
      if (!profileData.is_verified) {
        fetchProfileData();
      }
      const ticketsIdWithoutSeat = allTickets
        .filter((ticket) => ticket.price === 0)
        .map((ticket) => ticket.id);
      if (ticketsIdWithoutSeat.length > 0) {
        const message = translate
          ? `You haven't chosen a seat for the ticket: ${ticketsIdWithoutSeat}`
          : `Niste odabrali ${
              concertData?.place?.type === "theater" || concertData?.place?.type === "stadium"
                ? "poziciju"
                : "tip"
            } za ulaznicu: ${ticketsIdWithoutSeat}`;
        toast.error(message, toastSetup("top-center", 3000));
        return;
      }
    }
    await setTicketAmount(ticketAmount + 1);
    if (showPaymentForm) {
      setShowPaymentForm(false);
    }
    carouselRef.current.goTo(ticketAmount + 1);
  };

  const removeTicket = () => {
    if (ticketAmount === 1) return;
    setTicketAmount(ticketAmount - 1);
    dispatch(removeLastTicket({ id: ticketAmount }));
    if (showPaymentForm) {
      setShowPaymentForm(false);
    }
    // Delete card for theater or stadium
    if (
      concertData &&
      (concertData?.place?.type === "theater" || concertData?.place?.type === "stadium")
    )
      setTheaterZones((prevZones) => {
        const ticketID = ticketAmount;
        const newZones = { ...prevZones };

        for (const zoneKey in newZones) {
          const zone = newZones[zoneKey];

          for (const rowKey in zone.rows) {
            const rowToUpdate = zone.rows[rowKey];

            for (const seatKey in rowToUpdate.reserved_seats) {
              if (rowToUpdate.reserved_seats[seatKey].ticketID === ticketID) {
                delete rowToUpdate.reserved_seats[seatKey];
              }
            }
          }
        }

        return newZones;
      });
  };

  const handleSliderCardClick = (index) => {
    setActiveCardIndex(index);
    carouselRef.current.goTo(index);
  };

  const renderSliderCards = () => {
    const sliderCards = [];
    for (let i = 0; i < ticketAmount; i++) {
      sliderCards.push(
        <button
          className={`slider-cards ${activeCardIndex === i ? "active-card" : ""}`}
          ref={activeCardIndex === i ? activeCardRef : null}
          key={i}
          onClick={() => handleSliderCardClick(i)}
        >
          {translate ? "Ticket" : "Ulaznica"} {i + 1}
        </button>
      );
    }
    return sliderCards;
  };

  //TAKE ID and fetch data

  const fetchConcertData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${id}`);

      return response.data[0];
    } catch (error) {
      console.error("Error fetching concert data:", error);
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/users/id/${userId}`
      );

      if (!response.data.is_verified) {
        const email = response.data.email;
        const message = translate
          ? `Please verify your email: "${email}" to proceed with the purchase!`
          : `Verificirajte vaš račun na: "${email}" da biste mogli obaviti kupovinu!`;
        toast.error(message, toastSetup("top-right", 3000));
        return;
      }
      setProfileData(response.data);
    } catch (error) {
      toast.error(
        `Problem pri dohvatanju podataka. Pokušajte se odjaviti i ponovo prijaviti.`,
        toastSetup("top-right", 3000)
      );
      console.error("Error fetching profile data:", error);
    }
  };

  const date = new Date(concertData?.time_of_event).toLocaleString("hr-HR", hrTimeFormat);

  // Update the areEnoughTicketsAvailable function to return the category with not enough tickets
  const areEnoughTicketsAvailable = (concertData, ticketGenData) => {
    if (concertData.place.type === "hall") {
      const missingCategories = new Set();

      for (const ticket of ticketGenData.ticketList) {
        const category = String(ticket?.category);
        const concertTickets = concertData.tickets.online_sale.zones[category]?.amount;
        const ticketCount = ticketGenData.ticketList.filter((t) => t.category === category).length;

        if (concertTickets >= ticketCount) {
          // Enough tickets available for this category
          continue;
        } else {
          // Not enough tickets available for this category
          const ticketName = ticketGenData.ticketList.find(
            (t) => t.category === category
          )?.ticketName;
          missingCategories.add(ticketName || category);
        }
      }

      return Array.from(missingCategories);
    } else if (concertData.place.type === "theater" || concertData.place.type === "stadium") {
      const newZones = concertData.tickets.online_sale.zones;
      const oldZones = theaterZones;

      const unmatchedReservedSeats = [];

      // Iterate through oldZones to find reserved seats
      for (const oldZoneKey in oldZones) {
        const oldZone = oldZones[oldZoneKey];

        if (!oldZone?.rows) {
          if (
            newZones[oldZoneKey].amount < allTickets.filter((t) => t.category === oldZoneKey).length
          ) {
            unmatchedReservedSeats.push`za ${oldZoneKey}`;
          }
        } else {
          for (const rowKey in oldZone.rows) {
            // Check if oldZone.rows[rowKey] is defined and is an object
            if (oldZone.rows[rowKey] && typeof oldZone.rows[rowKey] === "object") {
              const reservedSeatsInRow = Object.values(oldZone.rows[rowKey].reserved_seats || {});

              // Check if reservedSeatsInRow is an array
              if (Array.isArray(reservedSeatsInRow)) {
                // Check if reserved seats exist in newZones
                for (const seat of reservedSeatsInRow) {
                  const { seatNumber } = seat;

                  if (
                    !newZones[oldZoneKey] ||
                    !newZones[oldZoneKey].rows[rowKey] ||
                    !newZones[oldZoneKey].rows[rowKey].seats.includes(seatNumber)
                  ) {
                    // Seat is not available in newZones, add to unmatchedReservedSeats
                    unmatchedReservedSeats.push(
                      `${seat.ticketID}, sjedalo broj ${seat.seatNumber} `
                    );
                  }
                }
              }
            }
          }
        }
      }

      return unmatchedReservedSeats;
    }
  };

  // BUY BUTTON
  // Check everything and show payment form
  const handleButtonClick = async () => {
    setShowPaymentForm(false);

    // Check for tickets that are not in same category and warn user
    const allTickets = Object.values(ticketGenData.ticketList);
    const allTicketsWithZones = Object.values(ticketGenData.ticketList).filter(
      (ticket) => ticket.category !== ""
    );

    const categories = new Set(allTicketsWithZones.map((ticket) => ticket.category));
    if (!agreed)
      if (categories.size > 1) {
        const message = translate
          ? "Warning! You have selected multiple different categories. Are you sure you want to continue with the purchase? "
          : "Upozorenje! Odabrali ste više različitih kategorija. Sigurno želite nastaviti s kupovinom? ";
        toast.warn(message, toastSetup("top-center", 6000));
        setAgreed(true);
        return;
      }
    setLoadingButton(true);
    let ticketsAvailable = concertData?.max_tickets_per_user || DEFAULT_MAX_TICKETS_PER_BUY;

    if (concertData?.max_tickets_per_user && profileData?.tickets_history) {
      const ticketsForThisEvent =
        profileData.tickets_history.find((concert) => concert.concert_id === concertData._id)
          ?.tickets.length || 0;

      ticketsAvailable = concertData.max_tickets_per_user - ticketsForThisEvent;
      if (profileData.role === "admin") ticketsAvailable = 6;
    }
    if (ticketsAvailable < 1) {
      toast.error(
        translate
          ? "You have purchased the maximum number of tickets. Please choose a different event."
          : "Kupili ste maksimalan broj ulaznica. Molimo, odaberite drugi dogadjaj.",
        toastSetup("top-center", 3000)
      );
      setLoadingButton(false);
      return;
    }

    // Ticket without seat selected

    const ticketsIdWithoutSeat = allTickets
      .filter((ticket) => ticket.price === 0)
      .map((ticket) => ticket.id);

    if (ticketsIdWithoutSeat.length > 0) {
      const message = translate
        ? `You haven't chosen a seat for the ticket: ${ticketsIdWithoutSeat}`
        : `Niste odabrali ${
            concertData?.place?.type === "theater" || concertData?.place?.type === "stadium"
              ? "poziciju"
              : "tip"
          } za ulaznicu: ${ticketsIdWithoutSeat}`;
      toast.error(message, toastSetup("top-center", 3000));
      handleSliderCardClick(ticketsIdWithoutSeat[0] - 1);
      setLoadingButton(false);
      return;
    }

    if (userId === "") {
      dispatch(setLoginIsOpen(true));
      setLoadingButton(false);
      return;
    }

    if (!profileData?.is_verified) {
      fetchProfileData();
      setLoadingButton(false);
      return;
    }

    // Check for tickets without email
    const ticketsIdWithoutEmail = allTickets
      .filter((ticket) => ticket.email === "")
      .map((ticket) => ticket.id);
    if (ticketsIdWithoutEmail.length > 0) {
      const message = translate
        ? `You haven't entered email for the ticket: ${ticketsIdWithoutEmail}`
        : `Niste unijeli email za ulaznicu: ${ticketsIdWithoutEmail}`;
      if (ticketsIdWithoutEmail.length === 1) {
        toast.error(message, toastSetup("top-center", 3000));
        handleSliderCardClick(ticketsIdWithoutEmail[0] - 1);
      } else {
        toast.error(message, toastSetup("top-center", 3000));
        handleSliderCardClick(ticketsIdWithoutEmail[0] - 1);
      }
      setLoadingButton(false);
      return;
    }

    // Ticket without name and same name+lastname on multiple tickets

    if (concertData.credentials_required) {
      const ticketsWithoutName = allTickets.filter(
        (ticket) => ticket.name === "" || ticket.lname === ""
      );

      const duplicates = allTickets.reduce((acc, ticket, index) => {
        const sameNameTicketIndex = allTickets.findIndex(
          (otherTicket, otherIndex) =>
            index !== otherIndex &&
            otherTicket.name === ticket.name &&
            otherTicket.lname === ticket.lname
        );

        if (sameNameTicketIndex !== -1) {
          if (!acc[ticket.name + ticket.lname]) {
            acc[ticket.name + ticket.lname] = [];
          }
          acc[ticket.name + ticket.lname].push(ticket.id);
        }
        return acc;
      }, {});

      if (ticketsWithoutName.length > 0) {
        const errorMessage = translate
          ? `You haven't entered name and last name for the ticket: ${ticketsWithoutName[0].id}`
          : ticketsWithoutName.length === 1
          ? `Niste unijeli ime i prezime za ulaznicu: ${ticketsWithoutName[0].id}`
          : `Niste unijeli ime i prezime za ulaznice: ${ticketsWithoutName
              .map((ticket) => ticket.id)
              .join(", ")}`;

        toast.error(errorMessage, toastSetup("top-center", 5000));

        handleSliderCardClick(ticketsWithoutName[0].id - 1);
        const itemToScrollTo = document.querySelector(".slider-bar");

        if (itemToScrollTo) {
          itemToScrollTo.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        setIsChecked(true);
        setLoadingButton(false);
        return;
      }

      if (Object.keys(duplicates).length > 0) {
        const errorMessage = translate
          ? `You can't use the same name and last name for multiple tickets: ${duplicates[
              Object.keys(duplicates)[0]
            ].join(", ")}`
          : Object.keys(duplicates).length === 1
          ? `Ne možete koristiti isto ime i prezime za više ulaznica: ${duplicates[
              Object.keys(duplicates)[0]
            ].join(", ")}`
          : `Ne možete koristiti isto ime i prezime za više ulaznica: ${Object.keys(
              duplicates
            ).join(", ")}`;

        toast.error(errorMessage, toastSetup("top-center", 5000));

        handleSliderCardClick(duplicates[Object.keys(duplicates)[0]][0] - 1);
        const itemToScrollTo = document.querySelector(".slider-bar");

        if (itemToScrollTo) {
          itemToScrollTo.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        setIsChecked(true);
        setLoadingButton(false);
        return;
      }
    }

    // Check if there is enough tickets available on backend
    const concertDataFetched = await fetchConcertData();
    const categoryWithNotEnoughTickets = areEnoughTicketsAvailable(
      concertDataFetched,
      ticketGenData
    );

    if (categoryWithNotEnoughTickets?.length > 0) {
      fetchConcert();
      toast.error(
        translate
          ? `Unfortunately ${
              categoryWithNotEnoughTickets.length === 1 ? "ticket" : "tickets"
            }: ${categoryWithNotEnoughTickets} ${
              categoryWithNotEnoughTickets.length === 1
                ? "has just been bought by another user."
                : "have just been bought by other users."
            }`
          : `Nažalost ${
              categoryWithNotEnoughTickets.length === 1 ? "ulaznica" : "ulaznice"
            }: ${categoryWithNotEnoughTickets} ${
              categoryWithNotEnoughTickets.length === 1
                ? "je upravo kupljena od drugog korisnika."
                : "su upravo kupljene od drugih korisnika."
            }`,
        toastSetup("top-right", 5000)
      );
      // Keep user data in state but reset tickets information
      dispatch(softResetState());
      setLoadingButton(false);
      return;
    }
    const sendPostRequest = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/payment/get_ticket_data`,
          {
            ticketGenData,
            concertId: concertData?._id,
            orderNumber,
            loggedinUser: userId,
          }
        );

        return response.data;
      } catch (error) {
        if (error?.response?.status === 403) {
          logout();
        }
        setOrderNumber(Math.floor(Math.random() * 1000000000000000) + 1);
        toast.warn(
          error?.response?.data?.msg || "An unexpected error occurred",
          toastSetup("top-center", 5000)
        );
        setLoadingButton(false);
        return;
      }
    };

    // Call the async function
    const response = await sendPostRequest();

    if (response?.errorAlreadyReservedSeat.length > 0) {
      handleSliderCardClick(response?.errorAlreadyReservedSeat[0]?.ticket.id - 1);

      const arrayForToast = response.errorAlreadyReservedSeat.map((item) => {
        return item.ticket;
      });
      const toastMessage = translate
        ? `Ticket${arrayForToast.length > 1 ? "s" : ""} ${arrayForToast
            .map(
              (ticket) =>
                `${ticket.id} (${ticket.category}, row ${ticket.row}, seat ${ticket.seat})`
            )
            .join(", ")} ${
            arrayForToast.length > 1 ? "are" : "is"
          } currently reserved, wait for the reservation to expire ( 15 min max ) or choose other tickets.`
        : `Ulaznic${arrayForToast.length > 1 ? "e" : "a"} ${arrayForToast
            .map(
              (ticket) =>
                `${ticket.id} (${ticket.category}, red ${ticket.row}, sjedalo ${ticket.seat})`
            )
            .join(", ")} ${arrayForToast.length > 1 ? "su" : "je"} trenutno rezereviran${
            arrayForToast.length > 1 ? "e" : "a"
          }, pričekajte istek rezervacije ( 15 min max ) ili odaberite druge ulaznice.`;
      toast.error(toastMessage, toastSetup("top-center", 5000 * arrayForToast.length));
      setLoadingButton(false);
      return;
    }
    // If error occurs
    if (!response) return;

    const toastMessage =
      translate === true
        ? "5 minute seat reservation. Make a payment within the given time."
        : "5 min traje rezervacija odabranih mjesta. Izvršite uplatu unutar danog vremena.";
    toast.success(toastMessage, toastSetup("top-center", 5000));

    setShowPaymentForm(true);
    setLoadingButton(false);
  };

  // START TIMER FOR 5 MIN RELOAD
  useEffect(() => {
    let timerId; // Declare timerId variable
    let retryTimerId; // Declare retryTimerId variable
    // Function to reload the page
    const reloadPage = () => {
      if (showPaymentForm) {
        toast.warn(
          "Vrijeme kupovine je isteklo. Pokušajte ponovo.",
          toastSetup("top-center", 3000)
        ); // Display toast message
        setTimeout(() => {
          window.location.reload(); // Reload the page after 3 seconds
        }, 3000); // 3000 milliseconds = 3 seconds
      }
    };

    // Start the countdown timer for 5 minutes
    const startTimer = () => {
      return setTimeout(reloadPage, 6 * 60 * 1000); // 6 mins
    };

    // Check if showPaymentForm is true and click the button if it is
    if (showPaymentForm) {
      setLoading(true);
      // Refactored function to handle the click logic for the button with retry mechanism
      const clickButton = () => {
        const buttonSelector = ".monri-lightbox-button-el";
        const retryDelay = 3000;
        const buttonElement = document.querySelector(buttonSelector);

        if (buttonElement) {
          buttonElement.click();
        } else {
          // Clear the timers and perform cleanup if button element is not found
          clearTimeout(retryTimerId);
          // Retry clicking the button after a short delay
          retryTimerId = setTimeout(clickButton, retryDelay);
        }
      };

      // Call the clickButton function to start the click logic
      timerId = startTimer();
      retryTimerId = clickButton();
    }

    // Cleanup function to clear the timers when the component unmounts or when the button element is not found
    return () => {
      clearTimeout(timerId); // Clear the countdown timer
      clearTimeout(retryTimerId); // Clear the retry timer
    };
  }, [showPaymentForm]);

  // PRESALE LOGIC
  const saleStart = concertData?.sale_start || null;
  useEffect(() => {
    const getPresaleData = async () => {
      try {
        const endpoint = `/api/v1/presale/check/${concertData._id}/${profileData?.email}`;
        const url = new URL(endpoint, process.env.REACT_APP_API_URL).href;
        const response = await axios.get(url);
        setPresaleBuyer(response?.data);
        if (response?.data) {
          toast.success(
            `Imate pristup pretprodaji za ovaj događaj.`,
            toastSetup("top-center", 3000)
          );
        } else {
          toast.error(
            `Nemate pristup pretprodaji za ovaj događaj.`,
            toastSetup("top-center", 3000)
          );
        }
      } catch (error) {
        toast.error(`Došlo je do greške pri provjeri pretprodaje.`, toastSetup("top-center", 3000));
      }
    };

    if (concertData?._id && profileData?.email && new Date(saleStart) > new Date()) {
      getPresaleData();
    }
  }, [concertData._id, profileData?.email, saleStart]);

  if (!concertData?.on_sale) {
    return <EventNotOnSale concertData={concertData} date={date} />;
  }

  if ((new Date(saleStart) > new Date() && !presaleBuyer) || ticketsAvailable === 0) {
    return <TicketRestricted concertData={concertData} date={date} profileData={profileData} />;
  }
  return (
    concertData && (
      <div className="single-page-container">
        <div className="single-page-top">
          <img
            src={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`}
            alt="Landscape poster"
            loading="lazy"
          />

          <div className="cover-overlay"></div>
        </div>
        <div className="buy-container">
          <div className="left">
            <div className="top-buy-page">
              <div className="info">
                <h3>{concertData?.performer_name}</h3>
                <p className="card-main-info">
                  {date} - {concertData?.place?.city}, {concertData?.place?.place}
                </p>
              </div>
              <img
                className="info-buy-page-image"
                src={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`}
                alt="Landscape poster"
              />
            </div>
            {!concertData.tickets?.online_sale.on_sale ? (
              <div className="specification" ref={addRef}>
                <div className="amount">
                  <h4>{translate ? "Amount" : "Količina"}</h4>
                  <div className="amount-left">
                    <CircleMinus onClick={removeTicket} />
                    <span>{ticketAmount}</span>
                    <CirclePlus onClick={addTicket} />
                  </div>
                </div>
                <div className="slider-bar">
                  <div className="sliders">{renderSliderCards()}</div>
                </div>
                <Carousel
                  itemsToShow={1}
                  enableAutoPlay={false}
                  disableArrowsOnEnd={false}
                  ref={carouselRef}
                  onChange={(currentItem) => setActiveCardIndex(currentItem.index)}
                >
                  {[...Array(ticketAmount)].map((_, i) => (
                    <Personalization
                      isChecked={isChecked}
                      id="personalization"
                      setIsChecked={setIsChecked}
                      key={i}
                      i={i}
                      addTicketFunction={addTicket}
                      activeCardIndex={activeCardIndex}
                      ticketAmount={ticketAmount}
                      concertData={concertData}
                      profileData={profileData}
                      setShowPaymentForm={setShowPaymentForm}
                      translate={translate}
                    />
                  ))}
                </Carousel>
                {concertData && concertData?.place?.type !== "hall" && (
                  <TheaterBuyPage
                    concertData={concertData}
                    activeCardIndex={activeCardIndex}
                    theaterZones={theaterZones}
                    tickets={allTickets}
                    addTicket={addTicket}
                    setTheaterZones={setTheaterZones}
                    setShowPaymentForm={setShowPaymentForm}
                    selectedZoneData={selectedZoneData}
                    setSelectedZoneData={setSelectedZoneData}
                    addRef={addRef}
                  />
                )}
              </div>
            ) : (
              <h6>Organizator ne nudi ulaznice za online prodaju</h6>
            )}
          </div>

          <div className="right">
            <img
              src={
                concertData?.poster?.landscape
                  ? `${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`
                  : ""
              }
              alt="concertData.poster.landscape"
              loading="lazy"
            />
            {concertData.tickets?.online_sale &&
            (concertData.tickets.online_sale.hasOwnProperty("type") ||
              concertData.tickets.online_sale.hasOwnProperty("zones")) ? (
              <>
                <div className="payment-bill">
                  {[...Array(ticketAmount)].map((_, i) => (
                    <TicketBill key={i} i={i} translate={translate} />
                  ))}
                </div>

                <div className="saldo">
                  <p>{translate ? "Total price" : "Ukupna cijena"}:</p>
                  <span>
                    {totalAmount
                      ? Math.round(
                          (totalAmount +
                            ticketAmount * 1.06 +
                            (totalAmount + ticketAmount) * 0.056) *
                            100 *
                            (translate ? 0.52 : 1)
                        ) / 100
                      : 0}
                    <small>{translate ? " €" : " BAM"}</small>
                  </span>
                </div>
                <div className="accumulative-spending">
                  <p>{translate ? "Accumulative spending" : "Agenciji troškovi"}:</p>
                  <span>
                    {totalAmount
                      ? Math.round(
                          (ticketAmount * 1.06 + (totalAmount + ticketAmount) * 0.056) *
                            100 *
                            (translate ? 0.52 : 1)
                        ) / 100
                      : 0}
                    <small>{translate ? " €" : " BAM"}</small>
                  </span>
                </div>
                <div className="payment-method">
                  {profileData.role === "admin" &&
                  (profileData.email === "13kreso@gmail.com" ||
                    profileData.email === "maticanto@gmail.com" ||
                    profileData.email === "dzambasivica88@gmail.com")
                    ? showPaymentForm && (
                        <AdminPayment
                          showPaymentForm={showPaymentForm}
                          totalAmount={
                            totalAmount
                              ? Math.round(
                                  (totalAmount +
                                    ticketAmount * 1.06 +
                                    (totalAmount + ticketAmount) * 0.056) *
                                    100 *
                                    (translate ? 0.52 : 1)
                                ) / 100
                              : 0
                          }
                          profileData={profileData}
                          orderNumber={orderNumber}
                          performerName={concertData.performer_name}
                          currency={translate ? "EUR" : "BAM"}
                          language={translate ? "en" : "hr"}
                        />
                      )
                    : showPaymentForm && (
                        <PaymentForm
                          showPaymentForm={showPaymentForm}
                          totalAmount={
                            totalAmount
                              ? Math.round(
                                  (totalAmount +
                                    ticketAmount * 1.06 +
                                    (totalAmount + ticketAmount) * 0.056) *
                                    100 *
                                    (translate ? 0.52 : 1)
                                ) / 100
                              : 0
                          }
                          profileData={profileData}
                          orderNumber={orderNumber}
                          performerName={concertData.performer_name}
                          currency={translate ? "EUR" : "BAM"}
                          language={translate ? "en" : "hr"}
                        />
                      )}
                  <button
                    ref={payRef}
                    className="pay-method"
                    disabled={loadingButton}
                    onClick={handleButtonClick}
                    style={{
                      pointerEvents: loadingButton ? "none" : "auto",
                      opacity: loadingButton ? "0.5" : "1",
                    }}
                  >
                    {translate ? "Proceed to Buy" : "Idi na plaćanje"}
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <ScrollButtons payRef={payRef} addRef={addRef} />
        {loading && <LoaderBuyPage setLoading={setLoading} translate={translate} />}
      </div>
    )
  );
};
