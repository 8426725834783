import HallTicketCard from "./HallTicketCard";
import { useDispatch } from "react-redux";
import { selectTicket } from "../../../../store/ticket-slice";

const COLORS = [
  "#94a3b8",
  "#ca8a04",
  "#84cc16",
  "#059669",
  "#0ea5e9",
  "#6366f1",
  "#7c3aed",
  "#db2777",
  "#ea580c",
];

const HallTickets = ({ onlineSaleZones, selectedZone, userEmail, userFullName, ticketID }) => {
  const dispatch = useDispatch();

  const handleClickZone = async (zone, zoneName, color) => {
    await dispatch(
      selectTicket({
        ticketPrice: Number(onlineSaleZones[zone].price),
        ticketID,
        category: zone,
        ticketName: zoneName,
        color: color,
      })
    );
  };

  const sortedOnlineSaleZones = Object.entries(onlineSaleZones)
    .filter(([_, { amount }]) => !!amount && amount > 0)
    .sort((a, b) => a[1].price - b[1].price);

  return (
    <div className="ticket-cards">
      {sortedOnlineSaleZones.map(([zone, { price, name }], i) => {
        const color = COLORS[i % COLORS.length];
        return (
          <HallTicketCard
            key={zone}
            price={price}
            name={name}
            zone={zone}
            userEmail={userEmail}
            userFullName={userFullName}
            selectedZone={selectedZone}
            shouldPresetTicket={i === 0 && ticketID === 1}
            onClick={() => handleClickZone(zone, name, color)}
            color={color}
          />
        );
      })}
    </div>
  );
};

export default HallTickets;
