import { useState } from "react";
import { Trash2 } from "lucide-react";
import { InputField } from "./Inputs";

export const OrganizeEventCategories = ({ zoneKey, index, zoneData, setEventData, allKeys }) => {
  const [tempKey, setTempKey] = useState(zoneKey);
  const [errors, setErrors] = useState({
    key: false,
    name: false,
    price: false,
    amount: false,
  });

  const handleZoneUpdate = (key, value, field) => {
    setEventData((prev) => ({
      ...prev,
      tickets: {
        ...prev.tickets,
        online_sale: {
          ...prev.tickets.online_sale,
          zones: {
            ...prev.tickets.online_sale.zones,
            [key]: {
              ...prev.tickets.online_sale.zones[key],
              [field]: value,
            },
          },
        },
      },
    }));
  };

  const handleZoneKeyChange = (e) => {
    setTempKey(e.target.value);
    setErrors((prev) => ({ ...prev, key: false }));
  };

  const handleZoneKeyBlur = () => {
    if (allKeys.includes(tempKey) && tempKey !== zoneKey) {
      setErrors((prev) => ({ ...prev, key: true }));
      setTempKey(zoneKey); // Revert to the previous key
      return;
    }

    setEventData((prev) => {
      const { [zoneKey]: oldZoneData, ...restZones } = prev.tickets.online_sale.zones;
      return {
        ...prev,
        tickets: {
          ...prev.tickets,
          online_sale: {
            ...prev.tickets.online_sale,
            zones: {
              ...restZones,
              [tempKey]: oldZoneData,
            },
          },
        },
      };
    });
  };

  const validateField = (field, value) => {
    if (field === "price" || field === "amount") {
      return value > 0;
    }
    return value.trim() !== "";
  };

  const handleFieldBlur = (field, value) => {
    const isValid = validateField(field, value);
    setErrors((prev) => ({ ...prev, [field]: !isValid }));
  };

  return (
    <div className="event-zones-container">
      <InputField
        type="text"
        className={`${errors.key ? "input-error" : ""}`}
        placeholder="Zona"
        value={tempKey}
        grow={true}
        onChange={handleZoneKeyChange}
        onBlur={handleZoneKeyBlur}
        isError={errors.key}
        errorMessage="Ovaj zona već postoji"
      />
      <InputField
        type="text"
        className={` ${errors.name ? "input-error" : ""}`}
        placeholder="Tip"
        grow={true}
        value={zoneData?.name || ""}
        onChange={(e) => handleZoneUpdate(tempKey, e.target.value, "name")}
        onBlur={(e) => handleFieldBlur("name", e.target.value)}
        isError={errors.name}
        errorMessage="Naziv je obavezan"
      />
      <InputField
        type="number"
        min="1"
        grow={true}
        className={` ${errors.amount ? "input-error" : ""}`}
        placeholder="Ukupan broj"
        value={zoneData?.amount || ""}
        onChange={(e) => {
          handleZoneUpdate(tempKey, Number(e.target.value), "amount");
          handleZoneUpdate(tempKey, Number(e.target.value), "max_amount");
        }}
        onBlur={(e) => handleFieldBlur("amount", e.target.value)}
        isError={errors.amount}
        errorMessage="Broj mora biti veći od 0"
      />
      <InputField
        type="number"
        min="0.01"
        step="0.01"
        grow={true}
        className={` ${errors.price ? "input-error" : ""}`}
        placeholder="Cijena"
        value={zoneData?.price || ""}
        onChange={(e) => handleZoneUpdate(tempKey, Number(e.target.value), "price")}
        onBlur={(e) => handleFieldBlur("price", e.target.value)}
        isError={errors.price}
        errorMessage="Cijena mora biti veća od 0"
      />
      <Trash2
        size={20}
        className="trashcan"
        onClick={() =>
          setEventData((prev) => ({
            ...prev,
            tickets: {
              ...prev.tickets,
              online_sale: {
                ...prev.tickets.online_sale,
                zones: Object.fromEntries(
                  Object.entries(prev.tickets.online_sale.zones).filter(([key]) => key !== zoneKey)
                ),
              },
            },
          }))
        }
      />
    </div>
  );
};
