import { useEffect } from "react";
import { useSelector } from "react-redux";
import i18next from "../translations/i18n";

const LanguageUpdater = () => {
  const language = useSelector((state) => state.language);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  return null; // This component doesn’t render anything
};

export default LanguageUpdater;
