import axios from "axios";
import React, { useEffect, useState } from "react";
import { CustomSelect } from "../Inputs";

export const AddOrganizer = ({ eventData, updateEventData }) => {
  const [organizers, setOrganizers] = useState([]);

  useEffect(() => {
    fetchOrganizers();
  }, []);

  const fetchOrganizers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/organizer`);
      setOrganizers(
        response.data.map((organizer) => ({ label: organizer.full_name, value: organizer._id }))
      );
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  return (
    <CustomSelect
      label="Dodijeli organizatora"
      options={[...organizers, { label: "Ivica Džambas", value: "65002f47ec753039e7c3b40a" }]}
      value={eventData?.organizer || ""}
      onChange={(e) => updateEventData("organizer", e.target.value)}
      name="organizer"
    />
  );
};
