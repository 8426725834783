import React from "react";

const TotalCard = ({ total, isValid }) => {
  const scanned = total - isValid;
  const progress = 100 - (scanned / total) * 100;

  return (
    <div className="entrance-total-card">
      <h5 className="total-card-title">
        <strong>Ukupna posjećenost</strong>
        <span>
          {scanned}
          {total ? ` / ${total}` : null}
        </span>
      </h5>
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${progress}%` }} // Move the fill based on progress
          ></div>
        </div>
      </div>
    </div>
  );
};

export default TotalCard;
