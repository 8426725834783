import { MappedOnlineManager } from "./components/MappedOnlineManager";

import { OrganizeEventCategories } from "./OrganizeEventCategories";

import { CirclePlus } from "lucide-react";

const OnlineTicketManager = ({ eventData, setEventData }) => {
  const { place, tickets } = eventData;
  const zones = tickets?.online_sale?.zones || {};

  const handleAddNewZone = () => {
    let newCategoryKey;
    let index = 1;
    do {
      newCategoryKey = `Nova zona ${index}`;
      index++;
    } while (zones.hasOwnProperty(newCategoryKey));

    setEventData((prevState) => ({
      ...prevState,
      tickets: {
        ...prevState.tickets,
        online_sale: {
          ...prevState.tickets.online_sale,
          zones: { ...prevState.tickets.online_sale.zones, [newCategoryKey]: { on_sale: true } },
        },
      },
    }));
  };

  const totalAmount = Object.values(zones).reduce(
    (acc, zone) => (zone?.on_sale ? acc + (Number(zone?.amount) || 0) : acc),
    0
  );

  return (
    <>
      {place?.type === "hall" ? (
        <>
          <h6>
            Online ulaznice <small>[{totalAmount}]</small>
          </h6>
          <div className="label-wrapper">
            <span className="input-label-custom">Zona</span>
            <span className="input-label-custom">Tip</span>
            <span className="input-label-custom">Broj</span>
            <span className="input-label-custom">
              Cijena <small>[BAM]</small>
            </span>
          </div>

          {Object.entries(zones).map(([zoneKey, zoneData], index) => (
            <OrganizeEventCategories
              key={zoneKey}
              index={index}
              zoneKey={zoneKey}
              zoneData={zoneData}
              setEventData={setEventData}
              allKeys={Object.keys(zones)}
            />
          ))}

          <div className="add-zone" onClick={handleAddNewZone}>
            <CirclePlus className="plus-icon" />
            <span className="">Dodaj zonu</span>
          </div>
        </>
      ) : (
        <>
          <h6>
            Online ulaznice <small>[{totalAmount}]</small>
          </h6>
          <MappedOnlineManager eventData={eventData} setEventData={setEventData} />
        </>
      )}
    </>
  );
};

export default OnlineTicketManager;
