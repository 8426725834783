import React, { useState } from "react";
import PropTypes from "prop-types";
import UploadImage from "../../../assets/images/uplad_img_placeholder.png";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import axios from "axios";

export const ImageUploader = ({ aspectRatios, onUploadSuccess }) => {
  const [selectedImagesForUpload, setImagesForUpload] = useState([]);
  const [selectedImages, setSelectedImages] = useState(
    Array(aspectRatios.length).fill(UploadImage)
  );
  const [loading, setLoading] = useState(false);

  // Helper function to parse aspect ratio
  const parseAspectRatio = (ratio) => {
    try {
      const [numerator, denominator] = ratio.split("/").map((num) => parseFloat(num.trim()));
      return numerator / denominator;
    } catch (error) {
      console.error(`Invalid aspect ratio: ${ratio}`);
      return null; // Return null for invalid ratios
    }
  };

  const handleImageClick = (index, aspectRatio) => {
    const parsedAspectRatio = parseAspectRatio(aspectRatio);
    if (!parsedAspectRatio) {
      toast.warn(`Nevažeći omjer: ${aspectRatio}`);
      return;
    }

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/png";

    input.onchange = async (e) => {
      if (loading) {
        toast.warn("Pričekajte dok se slike ne uploadaju.");
        return;
      }

      const file = e.target.files[0];
      if (!file.type.startsWith("image/") || !["image/jpeg", "image/png"].includes(file.type)) {
        toast.warn("Molimo dodajte sliku u JPG ili PNG formatu.");
        return;
      }

      const image = new Image();
      image.onload = () => {
        const isValidAspectRatio =
          Math.abs(image.width / image.height - parsedAspectRatio) < 0.1 ||
          Math.abs(image.width / image.height - 1 / parsedAspectRatio) < 0.1;

        if (isValidAspectRatio) {
          const updatedImages = [...selectedImages];
          updatedImages[index] = URL.createObjectURL(file);
          setSelectedImages(updatedImages);

          const updatedFiles = [...selectedImagesForUpload];
          updatedFiles[index] = file;
          setImagesForUpload(updatedFiles);
        } else {
          toast.warn(`Molimo dodajte sliku s omjerom ${aspectRatio}.`);
        }
      };
      image.src = URL.createObjectURL(file);
    };

    input.click();
  };

  const handleImageUpload = async () => {
    if (selectedImagesForUpload.length !== aspectRatios.length) {
      toast.warn("Molimo dodajte sve slike.", toastSetup("top-right", 3000));
      return;
    }

    if (loading) {
      toast.warn("Pričekajte dok se slike ne uploadaju.");
      return;
    }

    setLoading(true);

    const uniqueNames = selectedImagesForUpload.map((_, index) => {
      const timestamp = Date.now();
      const randomNum = Math.floor(Math.random() * 1000);
      const suffix = index === 0 ? "portrait" : "landscape";

      return `${timestamp}_${randomNum}_${suffix}.jpg`;
    });

    try {
      const formData = new FormData();
      selectedImagesForUpload.forEach((image, index) => {
        formData.append("eventImages", image, uniqueNames[index]);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/concerts/upload_img`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      toast.success(response.data.message, toastSetup("top-center", 3000));
      onUploadSuccess(uniqueNames); // Update parent state with the uploaded image names
      setLoading(false);
      return;
    } catch (error) {
      toast.error("Došlo je do pogreške prilikom slanja slika.", toastSetup("top-center", 3000));
      setLoading(false);
      return;
    }
  };

  return (
    <div className="image-upload-container">
      {aspectRatios.map((aspectRatio, index) => (
        <div key={index}>
          <h3>
            Poster <span>({aspectRatio})</span>
          </h3>
          <div className="image-wrapper">
            <img
              className={selectedImages[index] !== UploadImage ? "uploaded-event-image" : ""}
              src={selectedImages[index]}
              alt="Upload"
              onClick={() => handleImageClick(index, aspectRatio)}
            />
          </div>
        </div>
      ))}
      <button
        type="button"
        className="profile-btn w-7rem"
        onClick={handleImageUpload}
        disabled={loading}
      >
        {loading ? "Dodavanje..." : "Dodaj slike"}
      </button>
    </div>
  );
};

ImageUploader.propTypes = {
  aspectRatios: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
};
