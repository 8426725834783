import { CustomSwitcher } from "../organize-event-page/Inputs";
import axios from "axios";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";

const AddResellerToEvent = ({ setConcertData, userData, concertData }) => {
  const { active, can_print_tickets } = concertData.tickets.free_sale.resellers.filter(
    (reseller) => reseller.reseller_id.toString() === userData._id.toString()
  )[0] || { active: false, can_print_tickets: false };

  const toggleReseller = async (action) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/freeSale/reseller-toggle/${action}`,
        { userData, concertId: concertData._id }
      );
      setConcertData(res.data.concert);
      toast.success(res.data.success, toastSetup("top-right", 3000));
    } catch (error) {
      toast.error(
        `Problem s dodjelom preprodavača, pokušajte kasnije...${error}`,
        toastSetup("top-right", 3000)
      );
    }
  };

  return (
    <div className="reseller-switchers">
      <CustomSwitcher
        checked={active}
        id="active"
        label="Dodaj preprodavača"
        onChange={() => toggleReseller("active")}
      />
      <CustomSwitcher
        disabled={!active}
        checked={can_print_tickets}
        id="can-print"
        label="Mogućnost printa"
        onChange={() => toggleReseller("can-print")}
      />
    </div>
  );
};

export default AddResellerToEvent;
