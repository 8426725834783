import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./userSlice";
import { entranceControllerReducer } from "./entranceControllerSlice";
import { loginReducer } from "./loginSlice";
import { ticketStateExperimental } from "./ticket-slice";
import { ticketReducer } from "./ticketSlice";
import languageSlice from "./language-slice";

export const store = configureStore({
  reducer: {
    userState: userReducer,
    ticketState: ticketReducer,
    ticketStateExperimental: ticketStateExperimental,
    entranceControllerState: entranceControllerReducer,
    loginState: loginReducer,
    language: languageSlice,
  },
});
