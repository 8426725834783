import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import tinyMCEConfig from "../../../components/helper/tinyConfig";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import { setLoginIsOpen } from "../../../store/loginSlice";

// Components
import { GeneralInfo } from "./components/EventGeneralInputForm";
import { AddOrganizer } from "./components/AddOrganizer";
import PlaceInput from "./PlaceInput";
import OnlineTicketManager from "./OnlineTicketManager";
import SponsorInput from "./SponsorInput";
import { ImageUploader } from "./ImageUploader";
import SetTemplate from "../add-tickets/SetTemplate";
import Button from "../../../components/Button";
import Separator from "../../../components/Separator";

export const OrganizeEventPage = () => {
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const dispatch = useDispatch();

  const [eventData, setEventData] = useState({
    credentials_required: false,
    sponsors: [],
    organizer: userId,
    max_tickets_per_user: 4,
    poster: {
      portrait: null,
      landscape: null,
    },
  });

  const updateEventData = (key, value) => {
    setEventData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleEventCreation = async (e) => {
    e.preventDefault();
    try {
      // Validate required fields
      const requiredFields = [
        { label: "naziv događaja", value: "performer_name" },
        { label: "tip događaja", value: "type" },
        { label: "država", value: "place.country" },
        { label: "grad", value: "place.city" },
        { label: "mjesto održavanja", value: "place.place" },
        { label: "portretni poster", value: "poster.portrait" },
        { label: "pejzažni poster", value: "poster.landscape" },
        { label: "vrijeme održavanja", value: "time_of_event" },
        { label: "opis", value: "description" },
        { label: "organizator", value: "organizer" },
      ];

      const missingFields = requiredFields
        .filter(({ value: field }) => {
          const fieldValue = field.split(".").reduce((acc, key) => acc?.[key], eventData);
          return !fieldValue;
        })
        .map(({ label }) => label);

      const zoneErrors = Object.entries(eventData.tickets?.online_sale?.zones || {}).reduce(
        (acc, [zoneKey, zone]) => {
          if (!zone.on_sale) return acc;
          const errors = [];
          if (!zone.name) errors.push(`ime zone`);
          if (!zone.price) errors.push(`cijena ulaznice`);
          if (!zone.amount) errors.push(`broj ulaznica`);
          return acc.concat(
            errors.length
              ? [{ type: "error", message: `Zona ${zoneKey} nedostaje: ${errors.join(", ")}!` }]
              : []
          );
        },
        []
      );
      const messages = [
        { type: "warning", message: `Nedostaju polja: ${missingFields.join(", ")}!` },
        ...zoneErrors,
      ].filter(({ message }) => message);

      if (missingFields.length > 0) {
        messages.forEach((m) => toast(m.message, toastSetup("top-right", 10000)));
        return;
      }

      // Convert the dates to UTC before sending to the backend
      const dataToBeSent = {
        ...eventData,
        time_of_event: new Date(eventData.time_of_event).toUTCString(),
        sale_start: eventData?.sale_start ? new Date(eventData.sale_start).toUTCString() : null,
      };

      // Create the event
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/concerts/create_event`,
        { event: dataToBeSent, token },
        { headers: { "Content-Type": "application/json" } }
      );

      toast.success(response.data.message, toastSetup("top-center", 3000));
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(setLoginIsOpen(true));
      }
      toast.error(
        `Došlo je do pogreške prilikom kreiranja događaja. ${error.response?.data?.message || ""}`,
        toastSetup("top-center", 3000)
      );
    }
  };
  const handleUploadSuccess = (imageNames) => {
    setEventData((prev) => ({
      ...prev,
      poster: {
        portrait: imageNames[0],
        landscape: imageNames[1],
      },
    }));
  };

  const setTemplate = (template) => {
    setEventData((prev) => ({
      ...prev,
      // Format template
      template,
    }));
  };

  return (
    <form className="form container organize-form smaller-profile" onSubmit={handleEventCreation}>
      <ImageUploader aspectRatios={["2 / 3", "16 / 9"]} onUploadSuccess={handleUploadSuccess} />
      <div className="general-info-form">
        <GeneralInfo eventData={eventData} updateEventData={updateEventData} />
        <AddOrganizer eventData={eventData} updateEventData={updateEventData} />
        <SponsorInput eventData={eventData} setEventData={setEventData} />
        <Separator />
        <PlaceInput eventData={eventData} setEventData={setEventData} />
      </div>
      <Separator />
      {eventData?.place?.place && (
        <OnlineTicketManager eventData={eventData} setEventData={setEventData} />
      )}
      <SetTemplate template={eventData?.template} setTemplate={setTemplate} isPDF={true} />
      <Separator />
      <h6>Opis događaja</h6>
      <Editor
        apiKey={tinyMCEConfig.apiKey}
        value={eventData?.description || ""}
        init={tinyMCEConfig}
        onEditorChange={(content) => setEventData((prev) => ({ ...prev, description: content }))}
      />
      <Button type="submit" className="center-btn">
        Dodaj događaj
      </Button>
    </form>
  );
};
