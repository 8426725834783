import React, { useEffect, useState } from "react";
import { Mode } from "../../../draw-place/image-mapper/ImageMapper";
import axios from "axios";
import { toastSetup } from "../../../../functions/toastSetup";
import { toast } from "react-toastify";
import { setLoginIsOpen } from "../../../../store/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import OnlineImageMapper from "./OnlineImageMapper";
import SeatDisplayOnline from "./SeatDisplayOnline";

import { CheckCheck, Save, SearchCheck } from "lucide-react";

export const OnlineTicketManagerMapped = ({
  groundPlanName,
  zones,
  setZones,
  onlineSaleZones,
  setOnlineSaleZones,
  id,
}) => {
  const [modalWindow, setModalWindow] = useState(false);
  const [selectedZone, setSelectedZone] = useState([]);
  const [selectedZoneData, setSelectedZoneData] = useState();
  const [isOnlineSelected, setIsOnlineSelected] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const token = useSelector((state) => state.userState.token);
  const dispatch = useDispatch();

  const groundPlanUrl = `${process.env.REACT_APP_API_URL}/static/ground-plans/${groundPlanName}`;
  const groundPlan = new Image();
  groundPlan.src = groundPlanUrl;
  const [imageLoaded, setImageLoaded] = useState(true);

  useEffect(() => {
    if (imageLoaded) return;
    const img = new Image();
    img.onload = () => setImageLoaded(true);
    img.src = groundPlanUrl;
  }, [groundPlanUrl, imageLoaded]);

  // Zone click
  function handleZoneClick(e, zoneData) {
    setSelectedZoneData(zoneData[1]);
    setSelectedZone(zoneData[0]);

    setModalWindow(true);
    if (document.querySelector(".highlighted"))
      document.querySelector(".highlighted").classList.remove("highlighted");
    e && e.classList.add("highlighted");
  }

  const handleBlurClick = () => {
    setModalWindow(false);
    document
      .querySelectorAll(".highlighted")
      .forEach((element) => element.classList.remove("highlighted"));
  };

  const updateZonePrice = async (zoneName, newPrice, soldAmount, maxAmount) => {
    if (
      !zoneName ||
      newPrice === undefined ||
      soldAmount === undefined ||
      maxAmount === undefined
    ) {
      toast.error("Nedostaju svi parametri", toastSetup("top-center", 3000));
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/concerts/update_online_zone_price/${id}`,
        {
          token,
          zone: zoneName,
          price: newPrice,
          max_amount: maxAmount,
          sold_amount: soldAmount,
        }
      );

      if (response.status === 200) {
        // Update frontend zones state
        const { updatedZones } = response.data;
        setZones(updatedZones);
        toast.success("Cijena je uspješno ažurirana", toastSetup("top-center", 2000));
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(setLoginIsOpen(true));
      }
      toast.error(err?.response?.data?.message, toastSetup("top-center", 3000));
    }
  };

  const updateEventGranulary = async (zone, property, value) => {
    try {
      if (!zone || !property || value === undefined) {
        toast.error("Nisu pronađeni svi potrebni parametri", toastSetup("top-center", 3000));
        return;
      }
      if (property !== "on_sale" && zones[zone]?.on_sale) {
        toast.error(
          "Isključite prodaju za ovu zonu i onda nastaviti s promjenama!",
          toastSetup("top-center", 3000)
        );
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/concerts/update_online_zone/${id}/${property}`,
        {
          token,
          zone,
          value,
        }
      );

      // Ažurirajte lokalno stanje na temelju API odgovora
      if (response?.data?.message) {
        const { updatedZones } = response.data;
        setZones(updatedZones);
        setOnlineSaleZones(updatedZones);
        toast.success(`${response.data.message}`, toastSetup("top-center", 2000));
      } else {
        throw new Error("Server nije vratio očekivani odgovor.");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setLoginIsOpen(true));
      }
      if (error.response?.data?.zones) {
        setZones(error.response.data.updatedZones);
      }
      toast.error(
        error?.response?.data?.message || error.message || "Došlo je do pogreške",
        toastSetup("top-center", 3000)
      );
    }
  };
  const toggleAllRows = () => {
    setIsAllSelected((prev) => !prev);
    setZones((prevZones) => {
      const updatedZones = { ...prevZones };
      if (updatedZones[selectedZone]) {
        const updatedRows = { ...updatedZones[selectedZone].rows };
        Object.entries(selectedZoneData.rows).forEach(([rowKey, rowValue]) => {
          updatedRows[rowKey] = {
            ...updatedRows[rowKey],
            seats: isAllSelected
              ? []
              : Array.from({ length: Number(rowValue.total_seats) }, (_, i) => i + 1),
          };
        });
        const currentAmount = isAllSelected
          ? 0
          : Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0);

        updatedZones[selectedZone] = {
          ...updatedZones[selectedZone],
          rows: updatedRows,
          amount: currentAmount,
          max_amount:
            updatedZones[selectedZone].max_amount -
            (updatedZones[selectedZone].amount - currentAmount),
        };
      }
      return updatedZones;
    });
  };

  const toggleOnlineRows = () => {
    setIsOnlineSelected((prev) => !prev);
    setZones((prevZones) => {
      const updatedZones = { ...prevZones };

      if (updatedZones[selectedZone]) {
        const updatedRows = { ...updatedZones[selectedZone].rows };
        Object.entries(selectedZoneData.rows).forEach(([rowKey, rowValue]) => {
          updatedRows[rowKey] = {
            ...updatedRows[rowKey],
            seats: isOnlineSelected ? [] : selectedZoneData.rows[rowKey].seats,
          };
        });
        const currentAmount = isOnlineSelected
          ? 0
          : Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0);

        updatedZones[selectedZone] = {
          ...updatedZones[selectedZone],
          rows: updatedRows,
          amount: currentAmount,
          max_amount:
            updatedZones[selectedZone].max_amount -
            (updatedZones[selectedZone].amount - currentAmount),
        };
      }

      return updatedZones;
    });
  };

  const checkValidityOfZone = async (zone) => {
    const ticketsToReserve = Object.entries(zones[zone].rows).flatMap(([rowKey, rowValue]) =>
      rowValue.seats.map((seat) => ({
        category: zone,
        row: rowKey,
        seat: String(seat),
      }))
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/freeSale/reserve-free-sale-tickets`,
        {
          token,
          concertId: id,
          ticketsToReserve,
        }
      );
      setOnlineSaleZones(response.data.zones);
      continueWithReserveFreeSaleTicketsResponse(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setLoginIsOpen(true));
      }
      toast.error(error?.response?.data?.message, toastSetup("top-center", 3000));
    }
  };
  const continueWithReserveFreeSaleTicketsResponse = (response) => {
    if (!response.unavailableTickets.length) {
      toast.success("Sve ulaznice validne", toastSetup("top-center", 2000));
    } else {
      setZones((prevZones) => {
        const updatedZones = { ...prevZones };

        const updatedRows = { ...updatedZones[selectedZone].rows };
        response.unavailableTickets.forEach(({ row, seat }) => {
          if (updatedRows[row]?.seats) {
            updatedRows[row].seats = updatedRows[row].seats.filter((s) => s !== Number(seat));
          }
        });

        updatedZones[selectedZone] = {
          ...updatedZones[selectedZone],
          rows: updatedRows,
          amount: Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0),
          max_amount: updatedZones[selectedZone].max_amount - response.unavailableTickets.length,
        };

        return updatedZones;
      });

      if (response.unavailableTickets.length <= 6) {
        const unavailableTicketsByRow = response.unavailableTickets.reduce((acc, ticket) => {
          if (!acc[ticket.row]) {
            acc[ticket.row] = [];
          }
          acc[ticket.row].push(ticket.seat);
          return acc;
        }, {});

        const unavailableTicketsString = Object.entries(unavailableTicketsByRow)
          .map(([row, seats]) => `Red: ${row}, sjedalo: ${seats.join(", ")}`)
          .join(", ");

        toast.warn(
          `Rezervacija nije uspješna za (${response.unavailableTickets.length}) zauzeta sjedala. ${unavailableTicketsString}`,
          toastSetup("top-center", 2000)
        );
      } else {
        toast.warn(
          `Rezervacija nije uspješna za ${response.unavailableTickets.length} sjedala.`,
          toastSetup("top-center", 2000)
        );
      }
    }
  };

  return (
    <>
      {modalWindow && (
        <>
          <div className="add-ticket-modal">
            <div className="modal-wrapper">
              <div className="modal-top-sticky">
                <div className="modal-header">
                  <div className="header">
                    <div className="modal-title">Zona: {selectedZone}</div>
                    <div className="close-modal">
                      <CheckCheck size={18} onMouseDown={() => handleBlurClick()} />
                    </div>
                  </div>

                  <div className="zone-numbers-detail">
                    <span>Ukupno: {zones[selectedZone]?.max_amount || 0}</span>
                    <span>Preostalo: {zones[selectedZone]?.amount || 0}</span>
                    <span>
                      Prodano:{" "}
                      {(zones[selectedZone]?.max_amount || 0) - (zones[selectedZone]?.amount || 0)}
                    </span>
                  </div>
                </div>
                <div className="modal-form">
                  <div className="modal-input">
                    <div className="modal-label">U prodaji</div>
                    <div className="switch">
                      <input
                        className="switch-input"
                        type="checkbox"
                        id={`switch-${zones[selectedZone]}-onsale`}
                        checked={zones[selectedZone].on_sale}
                        onChange={(e) => {
                          if (
                            !zones[selectedZone].on_sale &&
                            (!zones[selectedZone].price ||
                              !zones[selectedZone].name ||
                              !zones[selectedZone].amount)
                          ) {
                            toast.warn(
                              "Dodajte sve parametre za prodaju!",
                              toastSetup("top-center", 3000)
                            );
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.checked = zones[selectedZone].on_sale;
                            return;
                          }
                          updateEventGranulary(selectedZone, "on_sale", e.target.checked);
                        }}
                      />
                      <label htmlFor={`switch-${zones[selectedZone]}-onsale`}></label>
                    </div>
                  </div>
                  <div className="modal-input">
                    <div className="modal-label">Cijena zone</div>
                    <input
                      value={zones[selectedZone]?.price}
                      className="table-input"
                      type="number"
                      onChange={(e) => {
                        setZones((prev) => ({
                          ...prev,
                          [selectedZone]: {
                            ...prev[selectedZone],
                            price: e.target.value,
                          },
                        }));
                      }}
                      onBlur={(e) => {
                        const sold =
                          zones[selectedZone]?.max_amount - zones[selectedZone]?.amount || 0;
                        const amountVal = zones[selectedZone]?.amount || 0;
                        updateZonePrice(
                          selectedZone,
                          parseFloat(e.target.value) || 0,
                          sold,
                          amountVal
                        );
                      }}
                      disabled={zones[selectedZone]?.on_sale}
                    />
                    <small>BAM</small>
                  </div>
                  <div className="modal-input">
                    <div className="modal-label">Tip ulaznice</div>
                    <input
                      value={zones[selectedZone]?.name}
                      className="table-input"
                      type="text"
                      onChange={(e) => {
                        setZones((prev) => ({
                          ...prev,
                          [selectedZone]: {
                            ...prev[selectedZone],
                            name: e.target.value,
                          },
                        }));
                      }}
                      onBlur={(e) => {
                        updateEventGranulary(selectedZone, "name", e.target.value);
                      }}
                      disabled={zones[selectedZone]?.on_sale}
                    />
                  </div>
                  {!selectedZoneData?.rows && (
                    <div className="modal-input">
                      <div className="modal-label">Broj ulaznica za prodaju</div>
                      <input
                        value={zones[selectedZone]?.amount}
                        className="price-input ticket-type table-input"
                        type="number"
                        onChange={(e) => {
                          setZones((prev) => ({
                            ...prev,
                            [selectedZone]: {
                              ...prev[selectedZone],
                              amount: e.target.value,
                              max_amount:
                                prev[selectedZone].max_amount -
                                (prev[selectedZone].amount - e.target.value),
                            },
                          }));
                        }}
                        onBlur={(e) => {
                          updateEventGranulary(
                            selectedZone,
                            "amount",
                            e.target.value - selectedZoneData?.amount
                          );
                        }}
                        disabled={zones[selectedZone]?.on_sale}
                      />
                    </div>
                  )}
                </div>
                {selectedZoneData?.rows && (
                  <div className="maper-button-wrapper">
                    <div className="modal-input">
                      <div className="modal-label">Online ulaznice</div>
                      <div className="switch">
                        <input
                          className="switch-input"
                          type="checkbox"
                          id={`switch-online-rows-for-${selectedZoneData[0]}`}
                          checked={isOnlineSelected}
                          onChange={() => toggleOnlineRows()}
                        />
                        <label
                          className="switch-label"
                          htmlFor={`switch-online-rows-for-${selectedZoneData[0]}`}
                        ></label>
                      </div>
                    </div>
                    <div className="modal-input">
                      <div className="modal-label">Sve ulaznice</div>
                      <div className="switch">
                        <input
                          className="table-input switch-input"
                          type="checkbox"
                          id={`switch-select-all-${zones[selectedZone]}`}
                          checked={isAllSelected}
                          onChange={() => toggleAllRows()}
                        />
                        <label htmlFor={`switch-select-all-${zones[selectedZone]}`}></label>
                      </div>
                    </div>

                    <div
                      className="save-zone-rows-btn"
                      onClick={() => checkValidityOfZone(selectedZone)}
                    >
                      <SearchCheck size={20} />
                      <span>Provjeri dostupnost</span>
                    </div>
                    <div
                      className="save-zone-rows-btn"
                      onClick={() =>
                        updateEventGranulary(selectedZone, "rows", zones[selectedZone]?.rows)
                      }
                    >
                      <Save size={20} />
                      <span>Spremi redove</span>
                    </div>
                  </div>
                )}
              </div>
              {selectedZoneData?.rows && (
                <div className="scroll-wrapper">
                  <SeatDisplayOnline
                    selectedZone={selectedZone}
                    selectedZoneData={selectedZoneData}
                    zones={zones}
                    setZones={setZones}
                    updateEventGranulary={updateEventGranulary}
                    isAllSelected={isAllSelected}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              handleBlurClick();
            }}
            className="blur"
          ></div>
        </>
      )}
      {imageLoaded && (
        <div className="organize-event-plan-wrapper">
          <div id="tooltip" display="none" style={{ position: "absolute", display: "none" }}></div>
          <OnlineImageMapper
            key={id}
            mode={Mode.SELECT}
            cb={(editor) => {
              editor.loadImage(groundPlanUrl); // Load the URL directly
              editor.polygon();
            }}
            options={{
              width: groundPlan.width,
              height: groundPlan.height,
            }}
            handleZoneClick={handleZoneClick}
            onlineSaleZones={onlineSaleZones}
            zones={zones}
          />
        </div>
      )}
      <div className="total-per-zone-container">
        <table className="total-per-zone">
          <thead>
            <tr>
              <th>Zona</th>
              <th>Tip</th>
              <th>Ukupno</th>
              <th>Dostupno</th>
              <th>Prodano</th>
              <th>Cijena [BAM]</th>
              <th>U prodaji</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(zones).map(([zone, zoneData]) => {
              const { amount, max_amount, on_sale, price, name, rows } = zoneData;
              const amountVal = amount ?? 0;
              const maxAmountVal = max_amount ?? 0;
              const sold = maxAmountVal - amountVal;
              const noEditableAmount = !!rows;
              const noEditableAtAll = zone.includes("_") || on_sale;
              const cantSell = !on_sale && (!name || !amountVal || !price);

              return (
                <tr key={zone}>
                  <td
                    onClick={() => {
                      !noEditableAtAll
                        ? handleZoneClick(null, [zone, zoneData])
                        : toast.info("Nije moguće otvoriti zonu koja je trenutno na prodaji!");
                    }}
                  >
                    <span className={`open-zone-button ${noEditableAtAll && "no-edit"}`}>
                      {zone}
                    </span>
                  </td>
                  <td>
                    <input
                      className="table-input"
                      type="text"
                      value={name}
                      onChange={(e) =>
                        setZones((prevZones) => ({
                          ...prevZones,
                          [zone]: { ...prevZones[zone], name: e.target.value },
                        }))
                      }
                      onBlur={(e) =>
                        updateEventGranulary(
                          zone,
                          "name",
                          e.target.value || onlineSaleZones[zone]?.name || ""
                        )
                      }
                      disabled={noEditableAtAll}
                    />
                  </td>
                  <td>{maxAmountVal}</td>
                  <td>
                    <input
                      className="table-input"
                      type="number"
                      value={amountVal}
                      onChange={(e) => {
                        setZones((prev) => ({
                          ...prev,
                          [zone]: {
                            ...prev[zone],
                            amount: e.target.value,
                            max_amount:
                              prev[zone].max_amount - (prev[zone].amount - e.target.value),
                          },
                        }));
                      }}
                      onBlur={(e) =>
                        updateEventGranulary(
                          zone,
                          "amount",
                          e.target.value - onlineSaleZones[zone]?.amount || 0
                        )
                      }
                      disabled={noEditableAmount || noEditableAtAll}
                    />
                  </td>

                  <td>{sold}</td>

                  <td>
                    <input
                      className="table-input"
                      type="number"
                      value={price}
                      onChange={(e) => {
                        const newPrice = e.target.value.replace(/^0+/, "");
                        setZones((prevZones) => ({
                          ...prevZones,
                          [zone]: { ...prevZones[zone], price: newPrice },
                        }));
                      }}
                      onBlur={(e) =>
                        updateZonePrice(zone, parseFloat(e.target.value) || 0, sold, amountVal)
                      }
                      disabled={noEditableAtAll}
                    />
                  </td>
                  <td>
                    <div className="switch">
                      <input
                        className="table-input switch-input"
                        type="checkbox"
                        id={`switch-${zone}`}
                        checked={on_sale}
                        onChange={async (e) => {
                          if (cantSell) {
                            toast.warn(
                              "Dodajte ulaznice, cijenu i tip za prodaju",
                              toastSetup("top-center", 5000)
                            );
                            e.preventDefault();
                            e.stopPropagation();
                            return;
                          }
                          await updateEventGranulary(zone, "on_sale", !on_sale);
                        }}
                        disabled={zone.includes("_")}
                      />
                      <label htmlFor={`switch-${zone}`}></label>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
