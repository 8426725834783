import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addTicketPrice } from "../../store/ticketSlice";
import { TheaterModal } from "./ticket-type/TheaterModal";
import { toast } from "react-toastify";
import { toastSetup } from "../../functions/toastSetup";
import ImageMapperBuyPage from "./ticket-type/ImageMapperBuyPage";

export const TheaterBuyPage = ({
  concertData,
  activeCardIndex,
  tickets,
  theaterZones,
  setTheaterZones,
  setShowPaymentForm,
  selectedZoneData,
  setSelectedZoneData,
  addRef,
}) => {
  const [groundPlanImg, setImg] = useState(null);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  addTicketPrice();

  // Load ground image
  useEffect(() => {
    const loadImage = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/static/ground-plans/${concertData.place.ground_plan}`
        );

        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);

        // Create an image element to calculate its dimensions
        const imgElement = new Image();

        // Set up the onload event handler
        imgElement.onload = () => {
          setImg({
            src: imageUrl,
            width: imgElement.width,
            height: imgElement.height,
          });
        };

        // Set the src AFTER defining the onload handler
        imgElement.src = imageUrl;
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [concertData]);

  // Change selected zone to coresponding ticket index
  useEffect(() => {
    const selected = document.querySelector(".selected");
    if (selected) {
      selected.classList.remove("selected");
    }
    let ticket = tickets.find((ticket) => ticket.id === activeCardIndex + 1);
    if (!ticket.category) {
      ticket = tickets[tickets.length - 2];
    }
    if (ticket) {
      const element = document.getElementById(`${ticket.category}`);
      if (element) {
        handleZoneClick(element, [ticket.category, theaterZones[ticket.category]]);
      }
    }
  }, [activeCardIndex]);

  // Hanlde zone click (select seat)
  function handleZoneClick(e, data) {
    // Set initial data for that zone
    setShowPaymentForm(false);
    setSelectedZoneData(data);
    setModal(true);

    const selected = document.querySelector(".selected");
    if (selected) selected.classList.remove("selected");

    e.classList.add("selected");

    // Prevent clicking on zone if it's not on sale
    if (!data[1].on_sale) {
      toast.warn("Zona nije u prodaji", toastSetup("top-right", 3000));
      return;
    }
    if (!data[1]?.rows) {
      // Tickets without seats, don't allow seat selection, handle ticket reservation
      // 1. remove reserved seats from other zones
      setTheaterZones((prevZones) =>
        Object.fromEntries(
          Object.entries(prevZones).map(([zoneKey, zone]) => [
            zoneKey,
            zone.rows
              ? {
                  ...zone,
                  rows: Object.fromEntries(
                    Object.entries(zone.rows).map(([rowKey, row]) => [
                      rowKey,
                      row.reserved_seats
                        ? {
                            ...row,
                            reserved_seats: Object.fromEntries(
                              Object.entries(row.reserved_seats).filter(
                                ([, seat]) => seat.ticketID !== activeCardIndex + 1
                              )
                            ),
                          }
                        : row,
                    ])
                  ),
                }
              : zone,
          ])
        )
      );
      // Check if there is available seats
      if (data[1]?.amount === 0) {
        toast.error("Nema slobodnih mjesta u odabranoj zoni", toastSetup("top-center", 3000));
        return;
      } else if (
        // Check if there is enaugh tickets in that category
        data[1]?.amount <= tickets.filter((ticket) => ticket.category === data[0]).length
      ) {
        toast.warn(
          "Nema više slobodnih mjesta u odabranoj zoni, odaberite drugu zonu!",
          toastSetup("top-center", 3000)
        );
      } else {
        dispatch(
          addTicketPrice({
            ticketPrice: Number(data[1].price),
            ticketID: activeCardIndex + 1,
            category: data[0],
            name: data[1].name,
          })
        );
      }
    }

    // If there is seat to be selected
    if (data[1]?.rows) {
      if (addRef.current) {
        // Scroll into view only if the screen width is less than 800px
        if (window.innerWidth < 800) {
          addRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }
  }

  const takeSeat = async (seatNumber, ticketID, row) => {
    if (selectedZoneData) {
      setShowPaymentForm(false);
      setTheaterZones((prevZones) => {
        const newZones = { ...prevZones };
        const selectedZoneDataKey = selectedZoneData[0];

        // Iterate through all zones
        for (const zoneKey in newZones) {
          const zone = newZones[zoneKey];

          if (zone.rows) {
            // Assign an empty object to rowToUpdate if it is null or undefined
            const rowToUpdate = zone?.rows[row] || {};

            // Then you can safely access its reserved_seats property
            rowToUpdate.reserved_seats = rowToUpdate?.reserved_seats || {};
            // If it's the selected zone, update reservations
            if (zoneKey === selectedZoneDataKey) {
              // Remove all reservations with the same ticketID
              for (const rowKey in zone.rows) {
                const rowToUpdate = zone.rows[rowKey];

                for (const seatKey in rowToUpdate.reserved_seats) {
                  if (rowToUpdate.reserved_seats[seatKey].ticketID === ticketID) {
                    delete rowToUpdate.reserved_seats[seatKey];
                  }
                }
              }
              // Reserve the new seat with the associated ticketID
              rowToUpdate.reserved_seats[seatNumber] = { seatNumber, ticketID };

              // Update the zone's row with the modified row
              newZones[zoneKey].rows[row] = rowToUpdate;
            } else {
              // For other zones, delete all reservations with the same ticketID
              for (const rowKey in zone.rows) {
                const rowToUpdate = zone.rows[rowKey];

                for (const seatKey in rowToUpdate.reserved_seats) {
                  if (rowToUpdate.reserved_seats[seatKey].ticketID === ticketID) {
                    delete rowToUpdate.reserved_seats[seatKey];
                  }
                }
              }
            }
          }
        }

        // Dispatch the action and update the state as before

        return newZones;
      });
      dispatch(
        addTicketPrice({
          ticketPrice: Number(selectedZoneData[1].price),
          ticketID,
          category: selectedZoneData[0],
          seat: seatNumber,
          row,
          name: selectedZoneData[1].name,
        })
      );
    }
  };

  return (
    <div className="buy-plan-wrapper">
      {modal && theaterZones && (
        <TheaterModal
          selectedZoneData={selectedZoneData}
          theaterZones={theaterZones}
          setShowPaymentForm={setShowPaymentForm}
          takeSeat={takeSeat}
          activeCardIndex={activeCardIndex}
        />
      )}

      {groundPlanImg && theaterZones && (
        <>
          <div id="tooltip" style={{ position: "absolute", display: "none" }}></div>
          <ImageMapperBuyPage
            mode={"selectMode"}
            cb={(editor) => {
              editor.loadImage(groundPlanImg.src); // Load the URL directly
              editor.polygon();
            }}
            options={{
              width: groundPlanImg.width,
              height: groundPlanImg.height,
            }}
            handleZoneClick={handleZoneClick}
            zones={theaterZones}
          />
        </>
      )}
    </div>
  );
};
