import React from "react";
import { Link } from "react-router-dom";
import { hrTimeFormat } from "../helper/timeFormat";
import { useTranslation } from "react-i18next";

export const SliderCard = ({ data }) => {
  const performerName = data.performer_name;
  const place = `${data.place.city}, ${data.place.place}`;
  const src = data.poster.landscape;
  const date = new Date(data.time_of_event).toLocaleDateString("hr-HR", hrTimeFormat);
  const { t } = useTranslation();

  return (
    <Link className="slider-card" to={`/single/${data._id}`} style={{ scrollSnapAlign: "center" }}>
      <div className="this-week-overlay"></div>
      <img src={`${process.env.REACT_APP_API_URL}/static/event-images/${src}`} alt="" />
      <p className="type" style={{ textTransform: "uppercase" }}>
        {t(`type.${data.type[0]}`)}
      </p>
      <p className="performer">{performerName}</p>
      <p className="date">{date}</p>
      <p className="location">{place}</p>
    </Link>
  );
};
