import React from "react";
import { ResellerConcertCard } from "./ResellerConcertCard";

export const PageRow = ({ heading, content, newsletterFunction, btnContent, data }) => {
  return (
    <div className="page-row">
      <div className="page-row-content">
        <h6 className="page-row-heading">{heading}</h6>
        <div className="page-row-paragraphs" dangerouslySetInnerHTML={{ __html: content }}></div>
        {heading === "Newsletter" && (
          <button
            onClick={() => {
              newsletterFunction();
            }}
            className="newsletter-application"
          >
            {btnContent}
          </button>
        )}
      </div>
      {data && data?.concerts.length > 0 && (
        <div className="event-div">
          {data.concerts.map((concertId, i) => (
            <ResellerConcertCard key={i} concertId={concertId} />
          ))}
        </div>
      )}
    </div>
  );
};
