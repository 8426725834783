import { editor } from "@overlapmedia/imagemapper";
import React, { useMemo, useRef, useCallback, useEffect } from "react";

function PrintTicketImageMapper({
  options = {},
  style = {},
  cb,
  handleZoneClick,
  freeSaleZones,
  onlineSaleZones,
  tickets,
}) {
  const elementRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) {
      const editorInstance = editor(elementRef.current, options, style);
      editorRef.current = editorInstance;
      cb && cb(editorInstance);
    }
    editorRef.current.selectMode();
  }, [options, style, cb]);

  const showTooltip = useCallback((e, data) => {
    const tooltip = document.getElementById("tooltip");
    let content = `<p style="text-align: center; margin-bottom: -5px">Zona: ${
      data.zoneName || "N/A"
    }</p>`;

    const onlineProdajaContent = data["Online prodaja"]?.ticket_name
      ? `<div>
      <p>Online prodaja: ${data["Online prodaja"].ticket_name}</p>
      <p>Cijena: <small>${data["Online prodaja"].price || "0,00"} BAM</small></p>
      <small>Ukupan broj slobodnih sjedala: ${data["Online prodaja"].available_seats || 0} / ${
          data["Online prodaja"].total_amount || 0
        }</small>
      </div>
      `
      : "";

    const slobodnaProdajaContent = data["Slobodna prodaja"]?.ticket_name
      ? `
      <div>
      <p>Slobodna prodaja: ${data["Slobodna prodaja"].ticket_name}</p>
      <p>Cijena: <small>${data["Slobodna prodaja"].price || "0,00"} BAM</small></p>
      <small>Ukupan broj slobodnih sjedala: ${data["Slobodna prodaja"].available_seats || 0} / ${
          data["Slobodna prodaja"].total_amount || 0
        }</small>
      </div>
      `
      : "";
    content += onlineProdajaContent + slobodnaProdajaContent;
    tooltip.innerHTML = content;
    tooltip.style.left = e.pageX + "px";
    tooltip.style.top = e.pageY - 40 + "px";
    tooltip.style.display = "flex";
    tooltip.style.flexDirection = "column";
    tooltip.style.gap = "20px";
  }, []);

  const hideTooltip = useCallback(() => {
    const tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
  }, []);

  const showZoneTooltip = useCallback(
    (zoneData, zoneName, e) => {
      let seatsLengths = 0;

      if (zoneData.rows) {
        for (let row in zoneData.rows) {
          seatsLengths += zoneData.rows[row].seats.length;
        }
      } else {
        seatsLengths = Number(zoneData.amount);
      }

      const freeSaleZoneData =
        freeSaleZones && freeSaleZones.hasOwnProperty(zoneName) ? freeSaleZones[zoneName] : {};
      const data = {
        zoneName,
        "Slobodna prodaja": {
          price: freeSaleZoneData.price,
          total_amount: freeSaleZoneData.max_amount,
          available_seats: freeSaleZoneData.amount,
          ticket_name: freeSaleZoneData.name,
        },
        "Online prodaja": {
          price: zoneData.price,
          total_amount: zoneData.max_amount,
          available_seats: seatsLengths,
          ticket_name: zoneData.name,
        },
      };
      showTooltip(e, data);
    },
    [showTooltip, freeSaleZones]
  );

  const renderZones = useMemo(() => {
    if (!onlineSaleZones) return null;
    return Object.entries(onlineSaleZones).map(([zoneName, zoneData], index) => {
      let totalSeats = 0;
      let totalRemainingSeats = 0;

      if (zoneData?.rows) {
        Object.entries(zoneData.rows).map(([key, row]) => {
          totalSeats += Number(row.total_seats);
          totalRemainingSeats += row.seats.length;
        });
      } else {
        totalSeats += Number(zoneData.max_amount);
        totalRemainingSeats += Number(zoneData?.amount || 0);
      }

      const onlineSoldPrecentColor =
        Math.round((Number(zoneData?.amount || 0) / totalSeats) * 255) || 0;

      let containerStyle;

      if (zoneData?.on_sale) {
        containerStyle = `rgb(${onlineSoldPrecentColor + 100}, 20, 20)`;
      }
      if (freeSaleZones && freeSaleZones.hasOwnProperty(zoneName)) {
        containerStyle = "rgb(10, 20, 255)";
      }
      if (freeSaleZones && freeSaleZones.hasOwnProperty(zoneName) && zoneData.on_sale) {
        containerStyle = "rgb(150, 50, 150)";
      }

      if (tickets.some((ticket) => ticket.categoryName === zoneName)) {
        containerStyle = "rgb(0, 220, 0)";
      }

      if (zoneData.location?.shape === "rect") {
        return (
          <g key={`rect_${index}`}>
            <rect
              fill={containerStyle}
              stroke="rgb(21, 21, 21)"
              cursor="pointer"
              strokeWidth="8"
              opacity="0.45"
              strokeDasharray="none"
              strokeLinejoin="round"
              id={zoneName}
              width={zoneData.location.size.width}
              height={zoneData.location.size.height}
              x={zoneData.location.position.x}
              y={zoneData.location.position.y}
              onTouchStartCapture={(e) => {
                handleZoneClick(e.target, [zoneName, zoneData]);
              }}
              onClick={(e) => {
                handleZoneClick(e.target, [zoneName, zoneData]);
              }}
              onMouseMove={(e) => {
                showZoneTooltip(zoneData, zoneName, e);
              }}
              onMouseOut={hideTooltip}
            ></rect>
          </g>
        );
      } else if (zoneData.location?.shape === "polygon") {
        // Similar modification for polygons
        return (
          <g key={`pol_${index}`}>
            <polygon
              points={zoneData.location.points}
              fill={containerStyle}
              stroke="rgb(33, 33, 33)"
              cursor="pointer"
              strokeWidth="8"
              opacity="0.6"
              strokeDasharray="none"
              strokeLinejoin="bevel"
              id={zoneName}
              onTouchStartCapture={(e) => {
                handleZoneClick(e.target, [zoneName, zoneData]);
              }}
              onClick={(e) => {
                handleZoneClick(e.target, [zoneName, zoneData]);
              }}
              onMouseMove={(e) => {
                showZoneTooltip(zoneData, zoneName, e);
              }}
              onMouseOut={hideTooltip}
            ></polygon>
          </g>
        );
      }
      return null;
    });
  }, [onlineSaleZones, handleZoneClick, freeSaleZones, showZoneTooltip, hideTooltip, tickets]);

  return (
    <svg
      className="image-map-svg"
      ref={elementRef}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={options.width}
      height={options.height}
      viewBox={`0, 0, ${options.width}, ${options.height}`}
      preserveAspectRatio="xMinYMin"
    >
      {renderZones}
    </svg>
  );
}

export default PrintTicketImageMapper;
