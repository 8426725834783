const SeatDisplay = ({ selectedZoneData, tickets, setTickets, isAllSelected }) => {
  const orientation = selectedZoneData[1].orientation;

  const realTimeTickets = tickets.find((ticket) => ticket.categoryName === selectedZoneData[0]);

  const { rows = "column-reverse", seats } = orientation || {};

  const toggleSeats = ({ row, seat }) => {
    setTickets((prevTickets) => {
      const updatedTickets = prevTickets.map((zone) => {
        if (zone.categoryName === selectedZoneData[0]) {
          const updatedRows = { ...zone.rows };
          const updatedSeats = updatedRows[row].seats.includes(seat)
            ? updatedRows[row].seats.filter((seatNumber) => seatNumber !== seat)
            : [...updatedRows[row].seats, seat];

          updatedRows[row] = { ...updatedRows[row], seats: updatedSeats };
          return {
            ...zone,
            rows: updatedRows,
            ticketsNum: Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0),
          };
        }
        return zone;
      });
      return updatedTickets;
    });
  };

  const toggleRow = (row) => {
    setTickets((prevTickets) => {
      const updatedTickets = prevTickets.map((zone) => {
        const updatedRows = { ...zone.rows };
        const updatedSeats = updatedRows[row].seats.length
          ? []
          : !isAllSelected
          ? selectedZoneData[1].rows[row].seats
          : Array.from({ length: Number(zone.rows[row].total_seats) }, (_, i) => i + 1);

        updatedRows[row] = { ...updatedRows[row], seats: updatedSeats };
        return {
          ...zone,
          rows: updatedRows,
          ticketsNum: Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0),
        };
      });
      return updatedTickets;
    });
  };

  return (
    <div className="rows-container" style={{ flexDirection: rows }}>
      {Object.entries(realTimeTickets.rows).map(([key, value]) => {
        return (
          <div key={key} className="seats-container" style={{ flexDirection: seats || "row" }}>
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
            {Array.from({ length: Number(value.total_seats) }, (_, i) => {
              const seatNumber = i + 1;
              const notPrinted = selectedZoneData[1].rows[key].seats.includes(seatNumber);
              const isReserved = value.seats.includes(seatNumber);
              let className = "sold";
              if (notPrinted || isAllSelected) className = "free";
              if (isReserved) className = "reserved";

              return (
                <div
                  key={`${i}-${key}-${seatNumber}`}
                  className={className}
                  onClick={() => toggleSeats({ row: key, seat: seatNumber })}
                >
                  {seatNumber}
                </div>
              );
            })}
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SeatDisplay;
