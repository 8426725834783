import { CustomSelect, CustomSwitcher, InputField } from "../Inputs";

export const GeneralInfo = ({ eventData, updateEventData }) => (
  <>
    <InputField
      label="Naziv događaja"
      name="performer_name"
      placeholder="Naziv događaja"
      type="text"
      grow={true}
      value={eventData?.performer_name}
      onChange={(e) => updateEventData("performer_name", e.target.value)}
    />

    <InputField
      label="Vrijeme događaja"
      name="time_of_event"
      type="datetime-local"
      value={eventData?.time_of_event}
      onChange={(e) => updateEventData("time_of_event", e.target.value)}
    />
    <InputField
      label="Ulaznice po korisniku"
      type="number"
      placeholder={4}
      value={eventData?.max_tickets_per_user}
      onChange={(e) => updateEventData("max_tickets_per_user", e.target.value)}
    />
    <CustomSelect
      label="Tip događaja"
      options={[
        { value: "concert", label: "Koncert" },
        { value: "festival", label: "Festival" },
        { value: "theaters", label: "Predstava" },
        { value: "sport", label: "Sport" },
        { value: "other", label: "Ostalo" },
      ]}
      value={eventData?.type || ""}
      onChange={(e) => updateEventData("type", [e.target.value])}
      name="type"
    />
    <CustomSwitcher
      id="personalization"
      label="Personalizacija"
      checked={eventData?.credentials_required === true}
      onChange={(e) => updateEventData("credentials_required", e.target.checked)}
    />

    <CustomSwitcher
      id="presale"
      label={"Pretprodaja"}
      checked={!!eventData?.sale_start}
      onChange={(e) => updateEventData("sale_start", e.target.checked ? new Date() : null)}
    />

    <InputField
      label={"Početak prodaje"}
      name="sale_start_date"
      placeholder="Početak prodaje"
      type="datetime-local"
      value={eventData?.sale_start}
      onChange={(e) => updateEventData("sale_start_date", e.target.value)}
      disabled={!eventData?.sale_start}
    />
  </>
);
