import React, { useMemo, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import { SellingInfo } from "../my-events/SellingInfo";

export const ApproveTicketSale = React.memo(({ i, transaction, resellerId, concertId }) => {
  const [isApproved, setApprovedStatus] = useState(transaction?.is_verified);

  const approvePaymentRequest = async (e) => {
    e.preventDefault();
    if (isApproved) return;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/freeSale/verify-transaction`,
        { transactionIndex: i, concertId, resellerId }
      );
      setApprovedStatus(true);
      toast.success(data.success, toastSetup("top-center", 3000));
    } catch (error) {
      toast.error(error.message || "Error", toastSetup("top-center", 3000));
    }
  };

  const transactionWithIsApproved = useMemo(
    () => ({ ...transaction, is_verified: isApproved }),
    [transaction, isApproved]
  );

  return (
    <SellingInfo key={i} transaction={transactionWithIsApproved}>
      {!isApproved && (
        <button className="approve-transaction-button" onClick={approvePaymentRequest}>
          Potvrdi
        </button>
      )}
    </SellingInfo>
  );
});
