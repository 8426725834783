const SeatDisplayOnline = ({ selectedZoneData, selectedZone, zones, setZones, isAllSelected }) => {
  const orientation = selectedZoneData.orientation;

  const { rows = "column-reverse", seats } = orientation || {};
  const onlineTicketsStateRows = selectedZoneData.rows;

  const toggleSeats = ({ row, seat }) => {
    setZones((prevZones) => {
      const updatedZones = { ...prevZones };

      if (!updatedZones[selectedZone]) return updatedZones;

      const updatedRows = { ...updatedZones[selectedZone].rows };
      const seatIndex = updatedRows[row].seats.indexOf(seat);

      if (seatIndex !== -1) {
        updatedRows[row].seats.splice(seatIndex, 1);
      } else {
        updatedRows[row].seats.push(seat);
        updatedRows[row].seats.sort((a, b) => a - b);
      }

      const currentAmount = Object.values(updatedRows).reduce(
        (acc, row) => acc + row.seats.length,
        0
      );

      updatedZones[selectedZone] = {
        ...updatedZones[selectedZone],
        rows: updatedRows,
        amount: currentAmount,
        max_amount:
          updatedZones[selectedZone].max_amount -
          (updatedZones[selectedZone].amount - currentAmount),
      };

      return updatedZones;
    });
  };
  const toggleRow = (row) => {
    setZones((prevZones) => {
      const updatedZones = { ...prevZones };

      if (updatedZones[selectedZone]) {
        const updatedRows = { ...updatedZones[selectedZone].rows };

        const updatedSeats = updatedRows[row].seats.length
          ? []
          : !isAllSelected
          ? selectedZoneData.rows[row].seats
          : Array.from(
              { length: Number(onlineTicketsStateRows[row].total_seats) },
              (_, i) => i + 1
            );

        updatedRows[row] = { ...updatedRows[row], seats: updatedSeats };

        const currentAmount = Object.values(updatedRows).reduce(
          (acc, row) => acc + row.seats.length,
          0
        );

        updatedZones[selectedZone] = {
          ...updatedZones[selectedZone],
          rows: updatedRows,
          amount: currentAmount,
          max_amount:
            updatedZones[selectedZone].max_amount -
            (updatedZones[selectedZone].amount - currentAmount),
        };
      }

      return updatedZones;
    });
  };

  return (
    <div className="rows-container" style={{ flexDirection: rows }}>
      {Object.entries(zones[selectedZone].rows).map(([key, value]) => {
        return (
          <div key={key} className="seats-container" style={{ flexDirection: seats || "row" }}>
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
            {Array.from({ length: Number(value.total_seats) }, (_, i) => {
              const seat = i + 1;
              const notPrinted = selectedZoneData.rows[key].seats.includes(seat);
              const isSold = value.seats.includes(seat);
              let className = "sold";
              if (notPrinted || isAllSelected) className = "free";
              if (isSold) className = "reserved";

              return (
                <div
                  key={`${i}-${key}-${seat}`}
                  className={className}
                  onClick={() => toggleSeats({ row: key, seat })}
                >
                  {seat}
                </div>
              );
            })}
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SeatDisplayOnline;
