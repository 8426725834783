import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { presetTicket } from "../../../../store/ticket-slice";
import { BadgeDollarSign, MapPin } from "lucide-react";
import Badge from "./Badge";

const HallTicketCard = memo(
  ({
    zone,
    selectedZone,
    price,
    name,
    userEmail,
    onClick,
    shouldPresetTicket,
    color,
    userFullName,
  }) => {
    const dispatch = useDispatch();

    useEffect(() => {
      if (shouldPresetTicket) {
        dispatch(
          presetTicket({
            price,
            category: zone,
            ticketName: name,
            email: userEmail,
            name: userFullName.split(" ")[0],
            lname: userFullName.split(" ")[1],
            color,
          })
        );
      }
    }, [dispatch, shouldPresetTicket, userEmail, zone, price, name]);

    const isSelected = zone === selectedZone;

    const styleCard = {
      boxShadow: `0px 0px 12px 1px ${color}50`,
      outline: `solid 1px ${color}`,
    };

    const stylePoint = {
      color: color,
      boxShadow: `0px 0px 7px 2px ${color}60`,
    };

    return (
      <div
        onClick={onClick}
        className={`${isSelected ? "selected-ticket-card" : ""} ticket-card`}
        style={isSelected ? styleCard : {}}
      >
        <div className="ticket-card-header">
          <Badge color={color}>{name}</Badge>
          <span
            className={`${isSelected ? "selected-ticket-point" : ""} ticket-point`}
            style={isSelected ? stylePoint : {}}
          ></span>
        </div>
        <div className="ticket-card-content">
          <div className="text-with-icon">
            <MapPin />
            <span className="main-text">{zone}</span>
          </div>
          <div className="text-with-icon">
            <BadgeDollarSign />
            <span className="main-text">{price}</span>
            <span className="currency-styles"> BAM</span>
          </div>
        </div>
      </div>
    );
  }
);

export default HallTicketCard;
