import React, { useState } from "react";
import { hrTimeFormatShort } from "../../../components/helper/timeFormatShort";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import axios from "axios";

export const AddPayment = ({ setSellingInfo, concertId, resellerId }) => {
  const [sellingInfo, setTempSellingInfo] = useState({
    reseller: "",
    date: new Date().toLocaleString("hr-HR", hrTimeFormatShort),
    taker: "",
    price: 0,
    is_verified: false,
  });

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Check if all fields are filled
    const missingFields = Object.entries(sellingInfo).filter(([key, value]) => value === "");

    if (missingFields.length > 0) {
      toast.warn("Molimo unesite sva polja", toastSetup("top-right", 3000));
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/freeSale/add-transaction",
        {
          transactionData: sellingInfo,
          resellerId,
          concertId,
        }
      );

      // Update state only if request was successful
      if (response.status === 201) {
        toast.success(response.data.message, toastSetup("top-right", 3000));
        setSellingInfo((prevSellingInfo) => [...prevSellingInfo, sellingInfo]);
      }
    } catch (error) {
      toast.error(
        "Problem s dodavanjem transakcije, pokušajte kasnije...",
        toastSetup("top-right", 3000)
      );
    }
  }

  return (
    <form onSubmit={handleFormSubmit} className="add-payment-wrapper">
      <input
        type="text"
        className="selling-info-input"
        name="sellerName"
        placeholder="Ime prodavača"
        value={sellingInfo.reseller}
        onChange={(e) =>
          setTempSellingInfo((prevSellingInfo) => ({
            ...prevSellingInfo,
            reseller: e.target.value,
          }))
        }
      />
      <input
        disabled
        defaultValue={sellingInfo.date}
        type="text"
        className="selling-info-input"
        name="date"
        placeholder="datum"
      />
      <input
        type="text"
        className="selling-info-input"
        name="taker"
        placeholder="Preuzeo"
        value={sellingInfo.taker}
        onChange={(e) =>
          setTempSellingInfo((prevSellingInfo) => ({
            ...prevSellingInfo,
            taker: e.target.value,
          }))
        }
      />
      <input
        type="number"
        className="selling-info-input"
        name="amount"
        placeholder="Iznos u BAM"
        value={sellingInfo.price || ""}
        onChange={(e) =>
          setTempSellingInfo((prevSellingInfo) => ({
            ...prevSellingInfo,
            price: Number(e.target.value),
          }))
        }
      />
      <button type="submit">Spremi transakciju</button>
    </form>
  );
};
