import React from "react";
import { CustomSwitcher } from "./Inputs";

const SponsorModal = ({ isOpen, toggleModal, eventData, setEventData, existingSponsors }) => {
  const handleCheckboxChange = (sponsorName) => {
    setEventData((prev) => ({
      ...prev,
      sponsors: prev?.sponsors?.includes(sponsorName)
        ? prev?.sponsors?.filter((s) => s !== sponsorName)
        : [...prev?.sponsors, sponsorName],
    }));
  };
  if (!isOpen) {
    return null;
  }

  return (
    <div className="sponsor-modal">
      <div className="modal-content">
        <h6>Odaberi sponzore</h6>
        <div className="sponsors-wrapper">
          {existingSponsors.map((sponsor, i) => (
            <div key={i} className="sponsor-row">
              <img
                src={`${process.env.REACT_APP_API_URL}/static/sponsors/${sponsor}`}
                alt={`Sponsor_image_${sponsor}`}
              />
              <CustomSwitcher
                id={i}
                checked={eventData?.sponsors?.includes(sponsor)}
                onChange={() => handleCheckboxChange(sponsor)}
                style={{ paddingBottm: "0px" }}
              />
            </div>
          ))}
        </div>

        <button className="profile-btn" onClick={toggleModal}>
          Zatvori
        </button>
      </div>
    </div>
  );
};

export default SponsorModal;
