import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import EntranceCard from "./EntranceCard";
import { RefreshCw } from "lucide-react";

const ScansPerEntrance = ({ concertId }) => {
  const token = useSelector((state) => state.userState.token);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getScansPerEntrance();
  }, [concertId, token]);

  const getScansPerEntrance = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/scans-per-entrance/tickets_for_${concertId}`,
        {
          token,
        }
      );
      setData(response.data);
    } catch (error) {
      setError("Error fetching concert data");
      console.error("Error fetching concert data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h6 style={{ margin: "20px 0" }}>Skenovi po ulazu</h6>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {data && (
        <>
          <button
            className="profile-btn"
            style={{ marginBottom: "15px" }}
            disabled={loading}
            onClick={getScansPerEntrance}
          >
            <RefreshCw />
            Osvježi
          </button>
          <div className="entrance-category-container">
            {Object.entries(data.countedEntrances).map(([entrance, { total }]) => (
              <EntranceCard
                entrance={entrance}
                total={total}
                key={entrance}
                maxScans={data.maxScans}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ScansPerEntrance;
